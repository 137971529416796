import { blogUrl } from '@blissbook/ui-config'
import moment from 'moment'
import React from 'react'
import Card from '../Card'
import Typography from '../Typography'

export default ({ post, ...props }) => (
  <Card
    {...props}
    color='sunshine-500'
    href={blogUrl + `/${post.slug}`}
    image={post.image}
    target='_blank'
    title={moment(post.createdAt).format('MMM DD')}
  >
    <Typography variant='p1' className='tw-mb-0'>
      {post.title}
    </Typography>
  </Card>
)
