import { gridBreakpoints } from '@blissbook/ui/branding'
import { Col, Row, ShapeConfetti } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import React from 'react'
import { Section, Typography } from '../../components'

const TextColumn = ({ children }) => (
  <Col
    xs={12}
    lg={6}
    css={css`
      @media (min-width: ${gridBreakpoints.lg}px) {
        max-width: 480px;
      }
    `}
  >
    <Typography variant='p2'>{children}</Typography>
  </Col>
)

export default () => (
  <Section id='lightbulb' className='container py-sm'>
    <ShapeConfetti
      css={css`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
      `}
      className='tw-hidden lg:tw-block'
      shapes={[
        { x: -570, y: 40, type: 'wedge', color: 'purple-500', rotate: 90 },
        {
          x: -420,
          y: 80,
          type: 'quote',
          color: 'purple-500',
          size: 40,
          fill: true,
        },
        {
          x: -460,
          y: 260,
          type: 'square',
          color: 'red-500',
          rotate: 45,
          fill: true,
        },
        { x: -430, y: 400, type: 'square', color: 'blurple-500' },
        {
          x: -570,
          y: 500,
          type: 'wedge',
          color: 'purple-500',
          rotate: 90,
          fill: true,
        },
        {
          x: -500,
          y: 570,
          type: 'quote',
          color: 'sunshine-500',
          size: 40,
          fill: true,
        },

        { x: 565, y: 40, type: 'wedge', color: 'aqua-500', rotate: -90 },
        { x: 470, y: 100, type: 'square', color: 'red-500', fill: true },
        {
          x: 460,
          y: 260,
          type: 'quote',
          color: 'aqua-500',
          size: 40,
          fill: true,
        },
        { x: 440, y: 360, type: 'square', color: 'blurple-500', fill: true },
        { x: 470, y: 450, type: 'wedge', color: 'sunshine-500', rotate: 90 },
        {
          x: 575,
          y: 550,
          type: 'wedge',
          color: 'sunshine-500',
          rotate: -90,
          fill: true,
        },
      ]}
      showOrbitAnimation
    />

    <Typography
      variant='headline'
      className='tw-mx-auto text-center lg:tw-mt-18 mb-sm'
      style={{ maxWidth: 800 }}
    >
      Digital policy management & communication for today’s tech-loving
      workforce.
    </Typography>

    <img
      alt='Lightbulb'
      src='/images/marketing/about/Lightbulb.svg'
      className='tw-block tw-mx-auto tw-mb-10'
    />

    <Typography
      variant='h3'
      className='tw-mx-auto text-center tw-text-blurple-500 tw-mb-10'
    >
      Our Lightbulb Moment
    </Typography>

    <Row className='tw-justify-center'>
      <TextColumn>
        At most companies, the people tasked with company culture and employee
        engagement activities are the same ones who give employees an
        intimidating, legalese handbook that describes what they can or can’t do
        and nothing about why they should care.
      </TextColumn>
      <TextColumn>
        This conflict got us thinking, “Can HR teams mitigate risk in a mature
        and engaging way that enlightens and guides employees? Won’t a company
        be more protected if it shows employees they’re valued as human beings
        instead of tools used to get a job done?”
      </TextColumn>
    </Row>

    <Typography variant='h2' className='text-center tw-mt-6'>
      We say <em>“Yes!”</em> to both.
    </Typography>
  </Section>
)
