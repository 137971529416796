import { gridBreakpoints } from '@blissbook/ui/branding'
import {
  Container,
  Link,
  // @ts-ignore: WIP imports
  ShapeConfetti,
} from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import React from 'react'
import { Section } from '../../components'

const baseAddOns: string[] = [
  'Automated Employment Law Updates',
  'Premium Support',
  'New or Revised Handbook',
  'Custom Copywriting',
  'Extra Teamspaces',
  'Basic API Access',
]

const proAddOns: string[] = [
  'Advanced Employment Law Updates',
  'Premium Support or Custom MSA',
  'New or Revised Handbook',
  'Custom Copywriting',
  'Extra Teamspaces',
  'Custom API Access',
]

const customAddOns: string[] = [
  'Tailored Employment Law Updates',
  'Customized Support Agreement',
  'Bespoke Handbook Creation',
  'Advanced Custom Copywriting',
  'Unlimited Teamspaces',
  'Enterprise API Solutions',
]

interface AddOnsProps {
  selectedPlan: 'base' | 'pro' | 'custom'
}

const AddOns: React.FC<AddOnsProps> = ({ selectedPlan }) => {
  let addOns: string[]
  switch (selectedPlan) {
    case 'pro':
      addOns = proAddOns
      break
    case 'custom':
      addOns = customAddOns
      break
    default:
      addOns = baseAddOns
  }

  return (
    <Section
      id='add-ons'
      className='md:tw-pt-10 lg:tw-pt-20 md:tw-pb-10 lg:tw-pb-20'
    >
      <Container
        className='container tw-flex tw-flex-col tw-items-center'
        css={css(`
          position: relative;
          @media (min-width: ${gridBreakpoints.lg}px) {
            min-height: 400px;
          }
        `)}
      >
        <ShapeConfetti
          css={css(`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
          `)}
          className='tw-hidden lg:tw-block tw-z-10'
          shapes={[
            {
              x: -550,
              y: -40,
              type: 'wedge',
              color: 'aqua-500',
              rotate: 180,
            },
            { x: -390, y: 80, type: 'square', color: 'red-500', fill: true },
            {
              x: -540,
              y: 150,
              type: 'wedge',
              color: 'purple-500',
              fill: true,
            },
            {
              x: -580,
              y: 180,
              type: 'wedge',
              color: 'purple-500',
              fill: true,
            },
            { x: -550, y: 330, type: 'quote', color: 'aqua-500', fill: true },
            {
              x: -570,
              y: 690,
              type: 'quote',
              color: 'purple-500',
              fill: true,
            },
            {
              x: -535,
              y: 480,
              type: 'square',
              color: 'blurple-500',
              fill: false,
            },
            {
              x: -206,
              y: -38,
              type: 'square',
              color: 'blurple-500',
              fill: true,
            },
            {
              x: 240,
              y: 18,
              type: 'wedge',
              color: 'purple-500',
              fill: true,
              rotate: 360,
            },
            {
              x: 400,
              y: -45,
              type: 'wedge',
              color: 'aqua-500',
              fill: true,
              rotate: 270,
            },
            {
              x: 550,
              y: -10,
              type: 'wedge',
              color: 'purple-500',
              fill: false,
            },
            {
              x: 440,
              y: 90,
              type: 'quote',
              color: 'sunshine-500',
              fill: true,
            },
            {
              x: 570,
              y: 180,
              type: 'square',
              color: 'red-500',
              rotate: 45,
              fill: true,
            },
            {
              x: 515,
              y: 440,
              type: 'wedge',
              color: 'sunshine-500',
              fill: false,
            },
            {
              x: 560,
              y: 690,
              type: 'wedge',
              color: 'aqua-500',
              fill: true,
              rotate: 180,
            },
          ]}
        />
        <div className='tw-bg-blurple-700 tw-pb-24 tw-text-white tw-relative'>
          <div className='tw-relative tw-z-10'>
            <div>
              <h2 className='tw-mb-6 tw-text-6xl tw-font-semibold tw-text-center'>
                + Add-Ons.
              </h2>
              <p className='tw-text-center tw-mb-12 tw-text-2xl tw-font-light tw-max-w-2xl tw-mx-auto tw-tracking-[1px]'>
                Extra toppings to take implementing and <br /> operating
                Blissbook to the next level.
              </p>
            </div>
            <div className='tw-text-center tw-my-12 tw-text-lg tw-tracking-[1px]'>
              <Link
                to='/request-a-demo'
                className='tw-text-aqua-300 tw-underline tw-underline-offset-4 '
              >
                Set up a demo
              </Link>{' '}
              and we'll help you figure it out.
            </div>
            <div className='tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6 tw-max-w-4xl tw-mx-auto tw-auto-rows-[160px]'>
              {addOns.map((addOn, index) => (
                <div
                  key={index}
                  className='tw-bg-white tw-text-xl tw-text-gray-800 tw-rounded-lg tw-p-6 tw-text-center tw-font-semibold tw-grid tw-content-center tw-border-[3px] tw-border-blurple-500'
                >
                  {addOn}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default AddOns
