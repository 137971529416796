import { colors } from '@blissbook/ui/branding'
import { BackgroundImage, ShapeConfetti } from '@blissbook/ui/lib'
import { keyframes } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import range from 'lodash/range'
import React from 'react'
import {
  DemoDesktopBrowser,
  DemoHandbook,
  DemoTextLine,
  DemoVideoContainer,
  clickAnimation,
} from './components'

const scrollDuration = 1000

const personEntering = 1000
const personEntered = personEntering + 1000
const boardEntering = 3000
const boardEntered = boardEntering + 1000
const boardItemCount = 3
const boardItemDelay = 1000
const personExiting = 9000
const personExited = personExiting + 1000
const confettiEntering = 10000
const confettiEntered = confettiEntering + 500
const videoDuration = 12000
const toPercentage = (delay) => (delay * 100) / videoDuration

const waveCount = videoDuration / 6000

const VideoWave = (props) => (
  <svg
    fill={colors['aqua-700']}
    preserveAspectRatio='none'
    viewBox='0 0 616.2 248.84'
    role='img'
    aria-label='Wave'
    {...props}
  >
    <path d='M0,42.7C15,30.27,30.58,18.88,48.37,10.75,68.7,1.45,89.79-3.06,111.83,3c14.86,4.1,27.79,12.39,39.88,21.78,16.33,12.68,32.16,26,48.7,38.42,24.81,18.57,50.92,16.76,77.5,3.71C294.1,59,305.8,45.51,319.26,34.2c17.23-14.47,36.24-25.51,58.17-31.27C409.17-5.41,436.19,4.8,460.88,24c16.85,13.08,33.17,26.86,50.27,39.59,24.43,18.18,50.53,16.9,76.23,3.36,10.43-5.49,19.47-16.84,28.82-24.23V248.84H0Z' />
  </svg>
)

const Video = ({ keyframe }) => (
  <DemoVideoContainer
    backgroundColor={colors['aqua-300']}
    css={{
      overflow: 'hidden',
    }}
  >
    {range(0, Math.ceil(waveCount) + 1).map((index) => (
      <VideoWave
        key={index}
        css={{
          position: 'absolute',
          top: 40,
          bottom: 0,
          left: `${100 * ((keyframe.playVideo ? -waveCount : 0) + index)}%`,
          width: '100%',
          transition: `all ${videoDuration}ms linear`,
        }}
      />
    ))}

    <div
      className='tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full'
      css={{
        position: 'absolute',
        top: 0,
        left: 0,

        animation: keyframe.playButtonAnimation,
        opacity: keyframe.playVideo ? 0 : 1,
        pointerEvents: 'none',
        transition: 'all 250ms linear',
      }}
    >
      <div
        className='tw-flex tw-items-center tw-justify-center rounded-circle tw-p-2'
        css={{ background: colors['purple-500'], color: 'white' }}
      >
        <FontAwesomeIcon icon='play' css={{ transform: 'translateX(2px)' }} />
      </div>
    </div>

    <div
      className='tw-flex tw-flex-col tw-items-center tw-justify-center'
      css={{
        position: 'absolute',
        left: '20%',
        width: '60%',
        top: '15%',
        height: '60%',

        background: colors['blurple-700'],
        opacity: 0,

        animation:
          keyframe.playVideo &&
          `${keyframes`
          ${toPercentage(boardEntering)}% { opacity: 0; }
          ${toPercentage(boardEntered)}%, to { opacity: 1; }
        `} ${videoDuration}ms linear forwards`,
      }}
    >
      {range(0, boardItemCount).map((index) => (
        <div
          key={index}
          className='tw-flex tw-items-center'
          css={{
            color: colors['sunshine-500'],
            fontSize: 12,
            marginTop: index && 4,
            opacity: keyframe.playVideo ? 1 : 0,
            transition: 'all 250ms linear',
            transitionDelay: `${boardEntered + (index + 1) * boardItemDelay}ms`,
          }}
        >
          <FontAwesomeIcon icon='check' />
          <DemoTextLine
            variant='small'
            className='tw-ml-2 tw-mb-0'
            color={colors['sunshine-500']}
            width={60}
          />
        </div>
      ))}
    </div>

    <BackgroundImage
      src='/images/marketing/product/video-person.svg'
      size='contain'
      css={{
        position: 'absolute',
        bottom: '-80%',
        left: '2.5%',

        animation:
          keyframe.playVideo &&
          `${keyframes`
          ${toPercentage(personEntering)}%, ${toPercentage(personExited)}% { bottom: -80%; }
          ${toPercentage(personEntered)}%, ${toPercentage(personExiting)}% { bottom: 0; }
        `} ${videoDuration}ms linear`,
      }}
      height='80%'
      width='35%'
    />

    <ShapeConfetti
      css={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,

        opacity: keyframe.playVideo ? 1 : 0,
        transition: `all ${confettiEntered - confettiEntering}ms linear`,
        transitionDelay: `${confettiEntering}ms`,
      }}
      shapeDefaults={{ fill: true, size: 10 }}
      shapes={[
        { x: -91, y: 15, type: 'wedge', color: 'aqua-500', rotate: 180 },
        { x: -95, y: 80, type: 'wedge', color: 'purple-500' },
        { x: -70, y: 53, type: 'square', color: 'blurple-500' },
        { x: -62, y: 87, type: 'wedge', color: 'sunshine-500' },
        { x: -40, y: 20, type: 'square', color: 'red-500' },
        { x: 17, y: 20, type: 'wedge', color: 'aqua-500', rotate: 180 },
        { x: 59, y: 35, type: 'square', color: 'blurple-500' },
        { x: 75, y: 17, type: 'wedge', color: 'purple-500', rotate: 180 },
        { x: 80, y: 76, type: 'wedge', color: 'purple-500' },
        { x: 94, y: 39, type: 'wedge', color: 'sunshine-500' },
        { x: 97, y: 93, type: 'square', color: 'red-500', rotate: 45 },
      ]}
      showOrbitAnimation
    />

    <div
      css={{
        position: 'absolute',
        bottom: 10,
        height: 5,
        left: 10,
        right: 10,

        background: 'white',
      }}
    >
      <div
        css={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          width: keyframe.playVideo ? '100%' : 0,

          background: colors['red-500'],
          transition: `all ${videoDuration}ms linear`,
        }}
      />
    </div>
  </DemoVideoContainer>
)

const Component = ({ isAnimationComplete, keyframe }) => (
  <DemoDesktopBrowser disabled={isAnimationComplete}>
    <DemoHandbook
      css={{
        marginTop: -keyframe.scrollTop,
        transition: `all ${scrollDuration}ms ease`,
      }}
      sections={[
        { type: 'chapter' },
        { type: 'letter', video: <Video keyframe={keyframe} /> },
        { type: 'chapter' },
        { type: 'text' },
      ]}
    />
  </DemoDesktopBrowser>
)

export default {
  id: 'video',
  title: 'Video',
  description:
    'Make your handbook even more interactive by embedding videos from YouTube, Vimeo, or Wistia. Great for welcome videos!',
  Component,

  keyframes: [
    {
      duration: 1500,
      scrollTop: 0,
    },
    {
      duration: scrollDuration + 500,
      scrollTop: 300,
    },
    {
      duration: clickAnimation.duration,
      playButtonAnimation: clickAnimation.style,
    },
    {
      duration: videoDuration,
      playButtonAnimation: undefined,
      playVideo: true,
    },
  ],
}
