import AcceptWork from './AcceptWork'
import Conclusion from './Conclusion'
import DepthWork from './DepthWork'
import HowToMain from './HowToMain'
import PepWork from './PepWork'
import PrepWork from './PrepWork'
import StepWork from './StepWork'
import YepWork from './YepWork'
import { urls as howToUrls } from './howToConfig'

export const routes = [
  {
    path: howToUrls.root,
    exact: true,
    component: HowToMain,
  },
  {
    path: howToUrls.prepWork,
    exact: true,
    component: PrepWork,
  },
  {
    path: howToUrls.stepWork,
    exact: true,
    component: StepWork,
  },
  {
    path: howToUrls.depthWork,
    exact: true,
    component: DepthWork,
  },
  {
    path: howToUrls.yepWork,
    exact: true,
    component: YepWork,
  },
  {
    path: howToUrls.pepWork,
    exact: true,
    component: PepWork,
  },
  {
    path: howToUrls.acceptWork,
    exact: true,
    component: AcceptWork,
  },
  {
    path: howToUrls.conclusion,
    exact: true,
    component: Conclusion,
  },
]
