import { Paragraph } from '@blissbook/lib/email/renderer'
import config from '@blissbook/ui-config'
import { getColor, gridBreakpoints } from '@blissbook/ui/branding'
import { Button, Link } from '@blissbook/ui/lib'
import {
  Col,
  Container,
  // @ts-ignore
  DesktopBrowser,
  Row,
} from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { HubSpotForm, Layout, ResourceSection, Typography } from '../components'
import { MaxWidthContainer, Section } from '../components'
import TestimonialsSection from './Home/TestimonialsSection'
import { CallToActionSection } from './sections'

const color = 'aqua-500'

const { assetsUrl = '' } = config

const imgFolder =
  assetsUrl + '/images/marketing/resources/hr-policy-content-services/'

const assets = {
  lawChangeAlerts: imgFolder + 'law-change-alerts-illustration.png',
}

export default () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <Layout
      title='US Employment Law Updates from Blissbook'
      description='Stay in-the-know about changing US federal, state, city, and county labor laws so you can keep your HR policy content up-to-date.'
    >
      <ResourceSection
        hideBackgroundShape
        images={['/images/marketing/resources/law-change-alert.png']}
      >
        <img
          src='/images/marketing/Bugle.svg'
          className='tw-mb-10'
          alt='a bugle icon in blue and yellow'
        />

        <Typography variant='h4'>Employment Law Updates</Typography>

        <Typography variant='h1'>
          Summaries of new and changed US labor laws.
        </Typography>

        <Typography variant='p2'>
          Stay in-the-know about changing federal, state, city, and county laws
          so you can keep your HR policy content up-to-date. Blissbook’s
          relevant and actionable alerts focus on changes that are likely to
          drive a review of or changes to your HR policy content.
        </Typography>
        <HubSpotForm
          className='tw-mb-4'
          formId='6f043e2e-eb2a-480a-81da-ab83273dffb5'
        />
      </ResourceSection>
      <TestimonialsSection
        description='Join other readers from companies like:'
        showTestimonials={false}
        padding='tw-pt-[5px]'
      />
      <Section
        id='contact'
        className='text-center py-lg'
        css={{ backgroundColor: '#f6f6fc' }}
      >
        <img
          src='/images/marketing/home/surprise.svg'
          className='tw-mb-10'
          aria-label='a surprise box'
        />
        <Typography
          variant='h1'
          className='tw-mx-auto'
          style={{ maxWidth: 500 }}
        >
          Over 75 updates in the past year.
        </Typography>
        <Typography
          variant='p1'
          className='tw-mx-auto'
          style={{ maxWidth: 700 }}
        >
          US labor laws at every level are constantly evolving. To protect your
          business from compliance gaps, you first need to understand these
          changes. Can you read? Good. We’ll handle the rest!
        </Typography>
        <Button className='btn btn-primary tw-mx-auto' onClick={scrollToTop}>
          Sign Up for Free
        </Button>
      </Section>
      <Section className='tw-h-[80px] tw-w-full' />
      <Section className='tw-bg-opacity-100 tw-bg-gradient-to-t tw-from-[#f6f6f6] tw-to-[#f1f1f1]/15'>
        <Container className='tw-pt-[104px] tw-pb-40'>
          <MaxWidthContainer className='tw-mx-auto' xs={960}>
            <Row className='tw-flex-row-reverse tw-items-center'>
              <Col lg={6}>
                <DesktopBrowser
                  className='box-shadow tw-mx-auto tw-mb-8 lg:tw-mb-0'
                  css={css(`
                  max-width: 100%;
                  @media (min-width: ${gridBreakpoints.lg}px) {
                    width: 1000px;
                    max-width: 1000px;
                  }
                `)}
                >
                  <img
                    src={assets.lawChangeAlerts}
                    style={{ width: '100%' }}
                    alt='an example of a alert modal of law changes'
                  />
                </DesktopBrowser>
              </Col>
              <Col lg={6}>
                <Typography variant='h4' css={{ color: getColor(color) }}>
                  Take a Break
                </Typography>
                <Typography variant='h2'>
                  Law Change Alerts
                  <FontAwesomeIcon icon={['far', 'bell']} className='tw-ml-4' />
                </Typography>
                <Typography variant='p2' css={{ color: getColor('gray-800') }}>
                  We aim to reduce your risk from outdated HR policies by
                  tracking and informing you of changing employment laws across
                  city, county, state, and US federal jurisdictions. We even
                  tell you what to do!
                </Typography>
                <Typography variant='p2' css={{ color: getColor('gray-800') }}>
                  Our employment law updates are an anti-firehose of
                  information. We focus only on the changes to laws that are
                  likely to drive a review of or changes to your policy content.
                </Typography>
                <Typography
                  variant='p2'
                  css={{ color: getColor('gray-800') }}
                  className='tw-mb-10'
                >
                  We specifically do not include other HR-related but
                  non-policy-focused law changes (e.g. minimum wage laws), and
                  focus on enacted laws only so you aren’t overwhelmed by a
                  torrent of information.
                </Typography>

                <Button
                  className='btn btn-primary tw-mb-10'
                  onClick={scrollToTop}
                >
                  Sign Up for Free{' '}
                </Button>
                <Paragraph className='tw-font-sans tw-text-lg tw-font-semibold tw-leading-[1.67]'>
                  <Link to='/employee-handbook-hr-policy-content'>
                    Learn more about our Content Services
                  </Link>
                </Paragraph>
              </Col>
              <Col lg={6} className='tw-mb-6 offset-lg-2' />
            </Row>
          </MaxWidthContainer>
        </Container>
      </Section>
      <CallToActionSection
        title='Looking for more than just alerts?'
        description={
          <>
            Hundreds of companies trust Blissbook’s{' '}
            <Link to='/employee-handbook-software'>
              policy management software
            </Link>{' '}
            and{' '}
            <Link to='/employee-handbook-hr-policy-content'>
              content services
            </Link>{' '}
            to keep HR content up-to-date. Tell us about your project and we’ll
            give you a customized demo.
          </>
        }
      >
        <Link className='btn btn-primary tw-mr-6' to='/request-a-demo'>
          Request a Demo
        </Link>
      </CallToActionSection>
    </Layout>
  )
}
