import { ForwardLink, Link } from '@blissbook/ui/lib'
import React, { Fragment } from 'react'

import { Layout, Typography } from '../components'

import CallToActionSection from '../pages/sections/CallToActionSection'
import { TOCList } from './TOC'
import {
  ChapterHeader,
  ExternalLink,
  HowToSection,
  QuoteBordered,
} from './components'

import { urls } from './howToConfig'

import './styles/how-to-ebook.scss'

export default () => (
  <Layout
    className='how-to-ebook'
    title='Finishing your Policies and Procedures Project: No More Work'
    description={`The conclusion of Blissbook's How to Write Policies and Procedures eBook.`}
  >
    <ChapterHeader
      title='No More Work'
      subtitle='(Until Next Time!)'
      prev={{ url: urls.acceptWork, text: '6. The Accept Work' }}
    />

    <HowToSection
      className='container tw-mt-14'
      main={
        <Fragment>
          <Typography variant='p2'>
            Building brand new policies or updating outdated ones may not be the
            most exciting part of your job, but it’s extremely valuable. This
            part of your role touches every person within your company. When you
            think about the employee experience, HR is crucial to providing
            competitive benefits, flexible working conditions, and an organized
            workplace. By putting thought into what must be communicated to
            protect the company and how that message is experienced, you have an
            opportunity to build a handbook full of policies and procedures that
            are meaningful and engaging to employees.
          </Typography>
          <Typography variant='p2'>
            When discussing potentially challenging policy updates with our
            thought leaders, Cynthia Gore spoke about an experience at her
            previous company. Upcoming legislation would impact employee
            classification (hourly versus salaried), a substantial change for
            many employees. After an extensive review, the company made the
            decision to raise the wage levels of those employees who would be
            affected and, even though the legislation enactment was delayed,
            they stuck with their decision to raise wages.
          </Typography>
          <Typography variant='p2'>
            Though the process was difficult, the pride in Cynthia’s voice when
            she spoke on it was evident, and we think her story is a great one
            for all companies.
          </Typography>
        </Fragment>
      }
      side={<TOCList activeIndex={7} />}
    />

    <HowToSection
      className='container'
      main={
        <QuoteBordered
          quote='It was a great exercise. It was tough because it impacted the business quite a bit, and we could have made the decision to change our minds, but we didn’t. Because it was the right thing to do.'
          attribution={{
            name: 'Cynthia Gore',
            title: (
              <span>
                Head of HR Operations and Talent Acquisition at{' '}
                <ExternalLink href='https://www.mbusa.com'>
                  Mercedes-Benz USA
                </ExternalLink>
              </span>
            ),
          }}
        />
      }
    />

    <HowToSection
      className='container tw-mb-10'
      main={
        <Fragment>
          <Typography variant='p2'>
            Employees make your company go ‘round, so it’s important they know
            they’re valued. By making it easy to understand expectations, you
            put your employees – and your company – in a position to succeed.
          </Typography>
        </Fragment>
      }
    />

    <CallToActionSection
      title='Ready to create your new employee handbook?'
      description='We’re here for you! Get in touch so we can learn about you and your project. We can give a demo and set up a free trial as needed.'
    >
      <Link className='btn btn-primary tw-mr-6' to='/request-a-demo'>
        Request a Demo
      </Link>
      <ForwardLink className='tw-block tw-my-6' to='/pricing'>
        Check Out Pricing and Try It Free
      </ForwardLink>
    </CallToActionSection>
  </Layout>
)
