import config from '@blissbook/ui-config'
import { Button, FadeOutInTransition } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { animationCompleteCss } from './animations'

const fadeInDuration = 500
const fadeOutDuration = 500

const positionCss = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
}

// Reduce keyframes to full states
const mapKeyframes = (keyframes) =>
  keyframes.reduce((result, keyframe, index) => {
    result.push({
      ...result[index - 1],
      ...keyframe,
      index,
    })

    return result
  }, [])

// Animated experience
const AnimatedExperience = ({ experience, in: isIn, ...props }) => {
  // Calculate the keyframes
  const keyframes = useMemo(
    () => mapKeyframes(experience.keyframes),
    [experience],
  )

  // Current index
  const [index, setIndex] = useState(0)
  const keyframeIndex = Math.min(index, keyframes.length - 1)
  const keyframe = keyframes[keyframeIndex]
  const isComplete = index === keyframes.length

  // Reset / Replay state
  const [resetAt, setResetAt] = useState()
  const [forceOut, setForceOut] = useState(false)
  const [canAnimate, setCanAnimate] = useState(false)
  isIn = isIn && !forceOut

  const resetAnimation = () => {
    setResetAt(new Date())
    setIndex(0)
  }

  const onReplayAnimation = () => {
    setForceOut(true)
    setTimeout(() => setForceOut(false), fadeOutDuration)
  }

  // Sync the animation with the fade in/out
  useEffect(() => {
    if (isIn) {
      resetAnimation()
      setTimeout(() => setCanAnimate(true), fadeInDuration)
    } else {
      setCanAnimate(false)
    }
  }, [isIn])

  // Move the current index
  useEffect(() => {
    // Stop the animation
    if (index >= keyframes.length || !canAnimate) return

    // Set timer to go to the next keyframe
    const timerId = setTimeout(() => setIndex(index + 1), keyframe.duration)
    return () => clearTimeout(timerId)
  }, [index, canAnimate])

  return (
    <FadeOutInTransition
      key={resetAt}
      fadeInDelay={fadeOutDuration}
      fadeInDuration={fadeInDuration}
      fadeOutDuration={fadeOutDuration}
      in={isIn}
    >
      <div
        className='tw-flex tw-items-start tw-justify-center'
        css={{
          ...positionCss,
          pointerEvents: config.env.development ? 'all' : 'none',
        }}
      >
        <experience.Component
          {...props}
          isAnimationComplete={isComplete}
          keyframe={keyframe}
        />

        <If condition={keyframes.length}>
          <div
            className='tw-flex tw-items-center tw-justify-center'
            css={{
              ...positionCss,
              ...animationCompleteCss,
              opacity: isComplete ? 1 : 0,
              pointerEvents: 'none',
              zIndex: 1,
            }}
          >
            <Button
              className='tw-px-6 tw-py-2'
              color='primary'
              css={{ pointerEvents: isComplete ? 'all' : 'none' }}
              disabled={forceOut}
              onClick={onReplayAnimation}
            >
              <FontAwesomeIcon className='tw-mr-2' icon='redo-alt' spin />
              Replay Demo
            </Button>
          </div>
        </If>
      </div>
    </FadeOutInTransition>
  )
}

const WrappedAnimatedExperience = (props) => {
  // Can the "in" flag
  const [wasIn, setWasIn] = useState(props.in)
  if (props.in && !wasIn) setWasIn(true)

  return (
    <If condition={props.in || wasIn}>
      <AnimatedExperience {...props} />
    </If>
  )
}

export default React.memo(WrappedAnimatedExperience)
