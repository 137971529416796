import { Field } from '@blissbook/ui/lib'
import { setAuthValue } from '@blissbook/ui/marketing/actions'
import { sendEmailAuth } from '@blissbook/ui/marketing/queries'
import { handleError } from '@blissbook/ui/util/errors'
import { useStore } from '@blissbook/ui/util/store'
import { Formik } from 'formik'
import React, { Fragment } from 'react'
import * as Yup from 'yup'
import { AuthButton, Typography } from '../../components'

const validationSchema = Yup.object().shape({
  email: Yup.string().required().email(),
})

export const EmailForm = ({ setUserId }) => {
  const values = useStore('auth')

  const onSubmit = async (values, { setStatus }) => {
    setStatus({ isSubmitting: true })

    try {
      const { email } = values
      const { signInUrl, userId } = await sendEmailAuth(email)
      if (signInUrl) {
        window.location.href = signInUrl
      } else if (userId) {
        setUserId(userId)
      }
    } catch (error) {
      handleError(error)
      setStatus()
    }
  }

  return (
    <Formik
      initialValues={values}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ handleSubmit, status: { isSubmitting } = {} }) => (
        <form id='sign-in-email' noValidate onSubmit={handleSubmit}>
          <fieldset disabled={isSubmitting}>
            <Field
              name='email'
              label='Your Email Address'
              onChangeValue={(value) => setAuthValue('email', value)}
              type='email'
              autoFocus
            />

            <div className='tw-flex tw-items-center'>
              <AuthButton type='submit'>Sign In</AuthButton>
            </div>
          </fieldset>
        </form>
      )}
    </Formik>
  )
}

export const obfuscateEmail = (email) => {
  let [local, domain] = email.split('@')
  local = local[0] + Array(local.length).join('*')
  return `${local}@${domain}`
}

export const EmailAuthCodeHeader = ({ authId }) => (
  <Fragment>
    <Typography className='auth' variant='p2'>
      We sent a verification code to {obfuscateEmail(authId)}.
    </Typography>

    <Typography className='auth' variant='p2'>
      Enter the code or click the special link/button in the email to log in.
    </Typography>
  </Fragment>
)
