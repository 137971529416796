import { Link } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import React from 'react'
import { useLocation } from 'react-router-dom'
import FooterLayout from './Footer'
import NavbarLayout from './Navbar'

export const Footer = (props: any) => <FooterLayout {...props} Link={Link} />

export const Navbar = (props: any) => {
  const location = useLocation()
  const { user } = useStore('session')
  return <NavbarLayout {...props} Link={Link} location={location} user={user} />
}
