import { colors, getColor } from '@blissbook/ui/branding'
import { BackgroundImage, Link } from '@blissbook/ui/lib'
import { Shape } from '@blissbook/ui/lib/shape'
import { css } from '@emotion/react'
import classnames from 'classnames'
import React from 'react'
import tinycolor from 'tinycolor2'
import Typography from './Typography'

const imageHeight = 150
const transitionDuration = '200ms'

export default ({
  children,
  className,
  color,
  image,
  shape,
  title,
  titleCss,
  ...props
}) => {
  color = getColor(color)
  const isLink = Boolean(props.to || props.href)
  const Component = isLink ? Link : 'div'

  return (
    <Component
      {...props}
      css={css`
        position: relative;
        border: none;
        border-bottom: 15px solid ${color};
        font-weight: inherit;
        overflow: hidden;
        .card-body {
          padding: 1rem 1.5rem;
        }
        .shape {
          transition: all ${transitionDuration} linear;
        }
        &:hover {
          text-decoration: none;
        }
        a {
          font-weight: inherit;
        }
        ${
          isLink &&
          `
          &:hover {
            .shape {
              width: 50px;
              height: 50px;
            }
          }
        `
        }
        ${
          image &&
          `
          padding-bottom: ${imageHeight}px;

          .card-image-container {
            position: absolute;
            height: ${imageHeight}px;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
            &:after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background: ${tinycolor(colors['aqua-500'])
                .setAlpha(0.3)
                .toRgbString()};
            }
          }
          .card-image {
            transition: all ${transitionDuration} linear;
          }
          &:hover {
            .card-image {
              transform: scale(1.2);
            }
          }  
        `
        }
      `}
      className={classnames('card box-shadow', className)}
    >
      <If condition={shape === undefined}>
        <Shape
          css={{ position: 'absolute', top: -1, right: -1 }}
          type='wedge'
          color={color}
          fill
          rotate={90}
          size={40}
        />
        <Else />
        {shape}
      </If>

      <div className='card-body tw-text-gray-800'>
        <Typography
          variant='h4'
          css={{ ...titleCss, color }}
          className='tw-mt-4'
        >
          {title}
        </Typography>
        {children}
      </div>

      <If condition={image}>
        <div className='card-image-container'>
          <BackgroundImage
            className='card-image'
            height={imageHeight}
            src={image}
          />
        </div>
      </If>
    </Component>
  )
}
