import { ssoProviders } from '@blissbook/lib/auth'
import config from '@blissbook/ui-config'
import { Button, ButtonLink, FullScreenPage, Head } from '@blissbook/ui/lib'
import { useStore } from '@blissbook/ui/util/store'
import { cx } from '@emotion/css'
import isFunction from 'lodash/isFunction'
import React, { useState } from 'react'
import { AuthBackLink, AuthLayout, Typography } from '../../components'
import { AuthCodeForm } from './AuthCodeForm'
import { SignInErrorPage, SignedOutPage } from './ErrorPage'
import { HelpForm } from './HelpForm'
import { EmailAuthCodeHeader, EmailForm } from './email'
import { PhoneAuthCodeHeader, PhoneForm } from './phone'
export { SignedOutPage }

const emailAuthMethod = {
  AuthCodeHeader: EmailAuthCodeHeader,
  Form: EmailForm,
  authType: 'email',
  helpMessage: `I'm having trouble signing in and I already checked my spam/junk folder for the email you sent. Can you help?`,
  link: 'Sign in via email address',
  waitMinutes: 15,
}

const phoneAuthMethod = {
  AuthCodeHeader: PhoneAuthCodeHeader,
  authType: 'phone',
  Form: PhoneForm,
  link: 'Sign in via phone number',
}

// Get the auth methods
const getAuthMethods = (authSettings) => {
  // If no auth settings, just return email
  if (!authSettings) return [emailAuthMethod]

  // Otherwise, return based on authSettings
  const { emailAuth, phoneAuth } = authSettings
  return [
    emailAuth && emailAuthMethod,
    phoneAuth && {
      ...phoneAuthMethod,
      noEmailHelpContent: phoneAuth.helpContent,
    },
  ].filter(Boolean)
}

// Get the ssos
const getSingleSignOns = (authSettings) => {
  const ssos = authSettings ? authSettings.singleSignOns : []
  return ssos.map((sso) => ({
    ...sso,
    provider: ssoProviders.findById(sso.providerId),
  }))
}

// Determine the initial authType
const getDefaultAuthMethod = ({ auth, authMethods, singleSignOns }) => {
  // Force email?
  const emailAuth = authMethods.find((m) => m.authType === 'email')
  if (emailAuth && auth.email.length) return emailAuth
  // SSO?
  if (singleSignOns.length > 0) return
  // Use first available
  return authMethods[0]
}

const renderSsoText = (sso, key, organization) => {
  // If there is an override, use that
  if (sso[key]) return sso[key]
  // Use the default text
  const ssoText = sso.provider[key]
  return isFunction(ssoText) ? ssoText(organization) : ssoText
}

const buttonStyle = { maxWidth: 300, padding: '.5rem 1.5rem', width: '100%' }

const SsoButton = ({ className, organization, sso, ...props }) => (
  <a
    {...props}
    className={`btn btn-sso -${sso.providerId} ${className}`}
    style={buttonStyle}
    href={`/auth/${sso.providerId}`}
  >
    {renderSsoText(sso, 'buttonText', organization)}
  </a>
)

const SsoLink = ({ organization, sso, ...props }) => (
  <div {...props}>
    <a href={`/auth/${sso.providerId}`}>
      {renderSsoText(sso, 'linkText', organization)}
    </a>
  </div>
)

export const SignInPage = (props) => {
  const { error } = useStore()
  const Component = error ? SignInErrorPage : SignInAuthPage
  return <Component {...props} />
}

const SignInAuthPage = () => {
  const { auth, authSettings, organization } = useStore()

  const imageUrl = organization
    ? undefined
    : '/images/monogram-shadow-outline-offblack.svg'
  const [authMethods] = useState(() => getAuthMethods(authSettings))
  const [singleSignOns] = useState(() => getSingleSignOns(authSettings))
  const [authMethod, setAuthMethod] = useState(() =>
    getDefaultAuthMethod({ auth, authMethods, singleSignOns }),
  )
  const [showHelp, setShowHelp] = useState(false)
  const [userId, setUserId] = useState()
  const otherAuthMethods = authMethods.filter((m) => m !== authMethod)
  const SsoComponent = authMethod ? SsoLink : SsoButton

  return (
    <FullScreenPage hideBackground={organization}>
      <Head
        title={
          organization
            ? `Sign In to ${organization.name}`
            : 'Sign In to Blissbook'
        }
        description='Sign in to your online employee handbook. HR Professional? Create and edit a handbook. Employee? Review current company policies or sign your handbook.'
      />
      <AuthLayout imageUrl={imageUrl}>
        <Choose>
          <When condition={showHelp}>
            <HelpForm
              initialBody={authMethod?.helpMessage}
              noEmailHelpContent={authMethod?.noEmailHelpContent}
              onClose={() => setShowHelp(false)}
            />
          </When>
          <Otherwise>
            <Choose>
              <When condition={!userId}>
                <Typography className='auth' variant='h1'>
                  {organization ? 'Sign in.' : 'Sign in to Blissbook.'}
                </Typography>

                <Typography className='auth' variant='p2'>
                  {organization
                    ? `Sign in to ${organization.name}.`
                    : 'Come on in, the water’s fine!'}
                </Typography>

                <If condition={authMethod}>
                  <authMethod.Form {...{ setUserId }} />
                </If>

                {singleSignOns.map((sso) => (
                  <SsoComponent
                    key={sso.providerId}
                    className='tw-block tw-mt-6'
                    organization={organization}
                    sso={sso}
                  />
                ))}

                {otherAuthMethods.map((method) => (
                  <Button
                    className={cx(
                      'tw-block tw-mt-6',
                      authMethod ? 'btn-link' : 'btn btn-outline-dark',
                    )}
                    key={method.authType}
                    onClick={() => setAuthMethod(method)}
                    style={authMethod ? undefined : buttonStyle}
                  >
                    {method.link}
                  </Button>
                ))}
              </When>
              <Otherwise>
                <Typography className='auth' variant='h1'>
                  Enter the Verification Code.
                </Typography>

                <authMethod.AuthCodeHeader authId={auth[authMethod.authType]} />

                <AuthCodeForm {...{ setUserId, userId }} />

                <Typography variant='muted'>
                  Didn’t get a code?{' '}
                  <Choose>
                    <When condition={!authMethod.waitMinutes}>
                      <ButtonLink onClick={() => setUserId()}>
                        Try again
                      </ButtonLink>
                      .
                    </When>
                    <Otherwise>
                      You may need to wait up to {authMethod.waitMinutes}{' '}
                      minutes. Or,{' '}
                      <ButtonLink onClick={() => setUserId()}>
                        try again
                      </ButtonLink>
                      .
                    </Otherwise>
                  </Choose>
                </Typography>

                <ButtonLink
                  className='tw-block tw-mt-6'
                  onClick={() => setUserId()}
                >
                  Go back to the sign in page.
                </ButtonLink>
              </Otherwise>
            </Choose>

            <If condition={config.intercom}>
              <If condition={authMethod || authMethods.length === 0}>
                <ButtonLink
                  className='tw-block tw-mt-6'
                  onClick={() => setShowHelp(true)}
                >
                  Trouble Signing In?
                </ButtonLink>
              </If>
            </If>
          </Otherwise>
        </Choose>

        <If condition={!organization}>
          <AuthBackLink className='tw-block tw-mt-6' />
        </If>
      </AuthLayout>
    </FullScreenPage>
  )
}
