import React from 'react'

export default ({ children, id, ...props }) => (
  <section {...props} css={{ position: 'relative', zIndex: 0 }}>
    <If condition={id}>
      {/* biome-ignore lint/a11y/useAnchorContent: marketing site cares less */}
      <a name={id} />
    </If>
    {children}
  </section>
)
