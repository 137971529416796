import {
  FormSubmit,
  // @ts-ignore: WIP imports
  FullScreenPage,
  Head,
  // @ts-ignore: WIP imports
  Infobox,
  Link,
} from '@blissbook/ui/lib'
import { handleError } from '@blissbook/ui/util/errors'
import { useStore } from '@blissbook/ui/util/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState } from 'react'
import { AuthLayout, Typography } from '../../components'

export const IntegrationPage: React.FC<{
  label: string
  onInstall: () => void
}> = ({ label, onInstall }) => {
  const { integration, organization } = useStore()
  const [isSubmitting, setSubmitting] = useState(false)
  const actionText = integration ? 'Re-Install' : 'Install'
  const submitText = [
    isSubmitting ? `${actionText}ing` : actionText,
    organization && label,
  ]
    .filter(Boolean)
    .join(' ')
  return (
    <FullScreenPage>
      <Head title='Install Blissbook' />

      <AuthLayout formWidth={640} imageUrl='/images/stamp-angled-offblack.svg'>
        <Choose>
          <When condition={!organization}>
            <Typography className='auth' variant='h1'>
              Install Blissbook to {label}
            </Typography>
            <Typography variant='p1'>
              To create a new Blissbook account, click the button below.
            </Typography>
            <Typography variant='p1'>
              To link {label} to an existing Blissbook account, please{' '}
              <Link target='_blank' to='/sign-in'>
                sign in to Blissbook
              </Link>{' '}
              and then refresh this page.
            </Typography>
          </When>
          <When condition={integration}>
            <Typography className='auth' variant='h1'>
              Re-Install {label}
            </Typography>
            <Typography variant='p1'>
              This will re-connect {label} to the "{organization.name}"
              organization in Blissbook.
            </Typography>
          </When>
          <Otherwise>
            <Typography className='auth' variant='h1'>
              Install {label}
            </Typography>
            <Typography variant='p1'>
              This integration will connect {label} to the "{organization.name}"
              organization in Blissbook.
            </Typography>

            <Typography className='tw-mb-0' variant='p1'>
              <strong>User Sync</strong>
            </Typography>
            <Typography variant='p1'>
              Your organization members in Blissbook will be replaced with your
              employee list from {label}. All active org members in Blissbook
              who are not in {label} will be archived in Blissbook.
            </Typography>

            <Typography className='tw-mb-0' variant='p1'>
              <strong>{label} Single Sign-On</strong>
            </Typography>
            <Typography variant='p1'>
              {label} SSO enables users to sign in to Blissbook with their{' '}
              {label} credentials. If you do not want this, open{' '}
              <Link
                href='/organization/settings'
                rel='noopener noreferrer'
                target='_blank'
              >
                your account settings page in Blissbook
              </Link>
              , refresh that page once this installation is complete, click the
              Edit button, click the Edit button in the {label} box, then click
              the Uninstall button.
            </Typography>
          </Otherwise>
        </Choose>

        <FormSubmit
          css={{
            '.btn': {
              paddingLeft: '2rem',
              paddingRight: '2rem',
            },
            '.btn-cancel': {
              marginLeft: '2rem',
            },
          }}
          isSubmitting={isSubmitting}
          onCancel={() => {
            window.history.go(-1)
          }}
          onSubmit={async () => {
            setSubmitting(true)
            try {
              await onInstall()
            } catch (error) {
              handleError(error, 'Rippling Install Error')
              setSubmitting(false)
            }
          }}
        >
          {submitText}
          <If condition={isSubmitting}>
            <FontAwesomeIcon className='tw-ml-2' icon='spinner' spin />
          </If>
        </FormSubmit>

        <If condition={organization && !integration}>
          <Infobox className='tw-mt-9'>
            Note: If you have automated invitations turned on, emails will be
            sent to any newly added org members who gain access to a document.
            Check your{' '}
            <a
              href='/email/invitations-and-reminders'
              rel='noopener noreferrer'
              target='_blank'
            >
              invitation automation setting here
            </a>
            .
          </Infobox>
        </If>
      </AuthLayout>
    </FullScreenPage>
  )
}
