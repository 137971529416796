import React from 'react'
import { Layout } from '../../components'
import { ReadySection } from '../sections'
import CreateSection from './CreateSection'
import EmployeeExperienceSection from './EmployeeExperienceSection'
import LaunchSection from './LaunchSection'
import SideNav from './SideNav'
import TrackSection from './TrackSection'
import UpdateSection from './UpdateSection'

export default () => (
  <Layout
    title='Blissbook Product Overview: Online Employee Handbook Software'
    description={
      'Create, distribute, and maintain an online employee handbook; collect and track electronic signatures; manage policies; and more.'
    }
  >
    <CreateSection />
    <LaunchSection />
    <UpdateSection />
    <TrackSection />
    <EmployeeExperienceSection />
    <ReadySection />

    <SideNav />
  </Layout>
)
