import { colors } from '@blissbook/ui/branding'
import { Container, Tooltip } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Section, Typography } from '../../components'

const FEATURES = [
  {
    name: 'Legally-Binding eSignatures',
    tooltip:
      'Collect legally-binding eSignatures, track who has and hasn’t signed, set due dates, define exceptions, customize your acknowledgement form, send reminders, and more.',
  },
  {
    name: 'Automated Reminders',
    tooltip: `Send employees who've yet signed their acknowledgement form one or many reminder emails on a custom, automated cadence.`,
  },
  {
    name: 'Custom Branding',
    tooltip:
      'Customize your handbook’s colors, fonts, layouts, images, and more. Because your handbook is a website, you don’t need to work with a graphic design team to keep your layout from breaking when you change your content. It just works!',
  },
  {
    name: 'Signature Reporting & Export',
    tooltip:
      'View or print to PDF granular reports for any employee to be ready for any kind of an audit or hearing. Reports include information on the exact content an employee viewed or signed off on.',
  },
  {
    name: 'Automated Invitations',
    tooltip: `Blissbook can automatically send invitations to view or sign your handbook(s) to new employees when they're added to your organization.`,
  },
  {
    name: 'Mobile & Print Friendly',
    tooltip:
      'Blissbook’s responsive template ensures your handbook looks & works great on any device: laptops, desktops, phones, tablets, and printed paper.',
  },
  {
    name: 'Personalized Content',
    tooltip:
      'Assign employees to groups and then limit access to content based on those groups. Employees get a personalized version of your one handbook and you can manage all your content in one place.',
  },
  {
    name: 'Notification Emails',
    tooltip:
      'Inform employees about your handbook(s) with Blissbook’s built-in email notification system. Invitations, reminders, notifications of changes; it’s all available and tracked for documentation purposes.',
  },
  {
    name: 'Collaborative Editing',
    tooltip:
      'Work alongside your whole team in real time. Add an unlimited number of Admins or Editors and easily share a preview of your handbook with project stakeholders.',
  },
  {
    name: 'Manager Participation',
    tooltip:
      'Involve managers in signature collection by CCing them on auto-reminders. Managers also get their own dashboard so they’ll know their staff’s signature status.',
  },
  {
    name: 'Single Sign-On',
    tooltip:
      'Integrate Blissbook with your Single Sign-On (SSO) provider to make your handbook content easily accessible to your private audience. Supported SSO providers: Okta, Google, Microsoft AD FS, Azure/Office 365, and more.',
  },
  {
    name: 'Embedded Videos',
    tooltip:
      'Embed videos throughout your handbook to communicate your culture or policies. Blissbook supports YouTube, Vimeo, and Wistia.',
  },
  {
    name: 'Change Annotations',
    tooltip: `Never create or send a “summary of changes” document again! Annotations keep employees up-to-date with changes to your handbook. When an employee views or signs your handbook, they're guided through all the changes since the last time they viewed or signed. Don't worry, you have complete control over what they see!`,
  },
  {
    name: 'Employee List Sync',
    tooltip:
      'Integrate your HRIS directly or via secure file transfer to update your employee list automatically. New employees can be automatically invited and former employees automatically lose access.',
  },
  {
    name: 'Custom Domain',
    tooltip: `Customize your handbook's URL to match your brand and make it easy for employees to remember with a Custom Domain (e.g. https://handbook.yourdomain.com).`,
  },
  {
    name: 'Version History',
    tooltip:
      'All revisions to your handbook and to every section/policy are automatically tracked. It’s easy to see exactly what changed, who published the change, and when, plus what the exact content was that an employee signed off on.',
  },
  {
    name: 'Multiple Handbooks',
    tooltip:
      'Personalized content within 1 handbook isn’t for you? No problem. Create as many documents or handbooks as you’d like and share them with different groups of people.',
  },
  {
    name: 'Awesome Support',
    tooltip:
      'We’ll help you create and launch your handbook, help employees who have trouble accessing your handbook, and more.',
  },
  {
    name: 'Built-In Project Management',
    tooltip:
      'Never launched a digital handbook? No worries. Built-in project management walks your team through every task that needs to get done to launch successfully.',
  },
  {
    name: 'Role-Based Access Control',
    tooltip:
      'Limit access to only the parts of Blissbook your colleagues need to see to do their job successfully. No more worrying about someone editing a policy by accident. Included roles: Tech, Reporting, Editor, Billing.',
  },
]

export default () => (
  <Section
    id='features'
    className='py-sm'
    css={{ background: colors['blurple-700'], color: 'white' }}
  >
    <Container css={{ position: 'relative' }}>
      <Typography variant='h2' className='tw-mx-auto text-center tw-mb-10'>
        Features included in{' '}
        <em css={{ color: colors['sunshine-500'] }}>all</em> plans:
      </Typography>

      <div className='tw-gap-x-16 tw-grid md:tw-grid-cols-2-auto lg:tw-grid-cols-3-auto tw-mx-auto tw-justify-center'>
        {FEATURES.map((feature, index) => (
          <div key={index} className='tw-flex tw-items-center tw-mb-6'>
            <FontAwesomeIcon
              icon='check'
              color={colors['sunshine-500']}
              className='tw-mr-2'
              css={{ fontSize: 20 }}
            />
            <Tooltip
              content={feature.tooltip}
              css={{
                fontSize: 16,
                width: 340,
              }}
              maxWidth='none'
              placement='bottom-start'
            >
              <Typography
                variant='p2'
                children={feature.name}
                className='tw-mb-0'
                css={{
                  cursor: 'pointer',
                  borderBottom: `1px dotted ${colors['sunshine-300']}`,
                }}
              />
            </Tooltip>
          </div>
        ))}
      </div>
    </Container>
  </Section>
)
