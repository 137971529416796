import { billingConfig } from '@blissbook/common/billing/config'
import { colors } from '@blissbook/ui/branding'
import {
  BackgroundShapeGrid,
  Container,
  Link,
  ToggleButton,
  gridShapeSize,
} from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import classnames from 'classnames'
import qs from 'query-string'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Section, Typography } from '../../components'
const {
  daysPerYear,
  minCredits,
  monthlyCreditPrice,
  monthsPerYear,
  yearlyCreditPrice,
} = billingConfig
const manyEmployees = 250

const PricingLayout = (props) => (
  <div
    {...props}
    className='tw-flex tw-flex-col tw-items-center tw-bg-white box-shadow tw-py-10 tw-px-10 tw-mx-auto tw-mb-10'
    css={{ borderRadius: 5, maxWidth: '100%', width: 600 }}
  />
)

const PricingTitle = (props) => <h3 {...props} />

const PricingPrice = ({ price }) => {
  const dollars = Math.floor(price)
  const cents = Math.round((price - dollars) * 100)
  return (
    <div
      className='tw-flex tw-items-start tw-my-8'
      css={{ fontWeight: 600, height: 64, lineHeight: 1 }}
    >
      <span css={{ fontSize: 45, transform: 'translateY(-7px)' }}>$</span>
      <span css={{ fontSize: 95, transform: 'translateY(-22px)' }}>
        {dollars}
      </span>
      <If condition={cents > 0}>
        <span css={{ fontSize: 30, transform: 'translateY(-6px)' }}>
          {cents}
        </span>
      </If>
    </div>
  )
}

const PricingLabel = ({ isActive, ...props }) => (
  <label
    {...props}
    className='tw-mb-0'
    css={{
      color: colors['blurple-500'],
      cursor: 'pointer',
      fontSize: 14,
      fontWeight: 400,
    }}
    style={{ opacity: isActive ? 1 : 0.5 }}
  />
)

const plans = [
  {
    label: `1-${minCredits - 1} Employees`,
    Component: () => {
      const [isYearly, setYearly] = useState(true)
      const creditPrice = isYearly ? yearlyCreditPrice : monthlyCreditPrice
      const yearlyPrice = Math.round(creditPrice * minCredits * daysPerYear)
      const monthlyPrice = yearlyPrice / monthsPerYear

      return (
        <PricingLayout>
          <PricingTitle>1-{minCredits - 1} Employees</PricingTitle>
          <PricingPrice price={monthlyPrice} />
          <Typography variant='p2' className='tw-mb-0 text-center'>
            per Month
          </Typography>

          <div
            className='tw-flex tw-items-center tw-justify-center tw-my-14'
            css={{ height: 24 }}
          >
            <PricingLabel
              children='Paid Monthly'
              isActive={!isYearly}
              onClick={() => setYearly(false)}
            />
            <ToggleButton
              checked={isYearly}
              className='tw-mx-2'
              onChange={(checked) => setYearly(checked)}
            />
            <PricingLabel
              children='Paid Yearly'
              isActive={isYearly}
              onClick={() => setYearly(true)}
            />
          </div>
          <Link to='/request-a-demo' className='btn btn-primary tw-ml-2'>
            Get started
          </Link>
        </PricingLayout>
      )
    },
  },
  {
    label: `${minCredits}-${manyEmployees - 1} Employees`,
    Component: () => {
      const yearlyPrice = yearlyCreditPrice * daysPerYear
      return (
        <PricingLayout>
          <PricingTitle>
            {minCredits}-{manyEmployees - 1} Employees
          </PricingTitle>
          <PricingPrice price={yearlyPrice} />
          <Typography variant='p2' className='tw-mb-1 text-center'>
            per Employee Seat Per Year
          </Typography>
          <Typography
            variant='p2'
            className='text-center'
            css={{ fontSize: 14, marginBottom: -1 }}
          >
            assuming no special requirements
          </Typography>

          <Link to='#employee-seat' className='tw-mt-8 tw-mb-14'>
            What's this mean?
          </Link>
          <div className='tw-flex tw-items-center tw-justify-center'>
            <Link to='/request-a-demo' className='btn btn-primary tw-ml-2'>
              Get started
            </Link>
          </div>
        </PricingLayout>
      )
    },
  },
  {
    label: `${manyEmployees}+ Employees`,
    Component: () => (
      <PricingLayout>
        <PricingTitle>{manyEmployees}+ Employees</PricingTitle>
        <div className='tw-my-2' css={{ fontSize: 55, fontWeight: 600 }}>
          Custom Plan
        </div>
        <Typography variant='p2' className='tw-mb-0 text-center'>
          Price per Employee Seat per Year depends on your unique requirements.
          Let’s talk!
        </Typography>

        <Link to='#employee-seat' className='tw-my-14'>
          What's an Employee Seat?
        </Link>
        <Link to='/request-a-demo' className='btn btn-primary tw-ml-2'>
          Get started
        </Link>
      </PricingLayout>
    ),
  },
]
const defaultPlanIndex = plans.length - 1

// Get the plan from the query
const getPlanFromQuery = (query) => {
  const index = query.plan
    ? Number.parseInt(query.plan, 10) - 1
    : defaultPlanIndex
  return plans[index] || plans[defaultPlanIndex]
}

const PricingSection = ({ history, location }) => {
  const query = qs.parse(location.search)
  const currentPlan = getPlanFromQuery(query)

  const setPlanIndex = (index) => {
    const search = qs.stringify({ ...query, plan: index + 1 })
    history.replace({ ...location, search })
  }

  return (
    <Section id='pricing'>
      <div
        css={css`
          position: absolute;
          top: ${gridShapeSize * 4}px;
          bottom: 0;
          left: 0;
          right: 0;
          background: ${colors['blurple-700']};
          z-index: -1;
        `}
      />

      <Container css={{ position: 'relative' }}>
        <BackgroundShapeGrid
          alignX='left'
          alignY='top'
          shapeDefaults={{ fill: true }}
          shapes={[
            { x: -1, y: 3, type: 'wedge', color: 'sunshine-700', rotate: 90 },
            { x: -1, y: 4, type: 'square', color: 'purple-500' },
            { x: 0, y: 2, type: 'square', color: 'sunshine-500' },
            { x: 1, y: 2, type: 'wedge', color: 'sunshine-300', rotate: 180 },
            { x: 1, y: 3, type: 'square', color: 'red-50' },
            { x: 1, y: 4, type: 'square', color: 'red-500' },
          ]}
        />

        <BackgroundShapeGrid
          alignX='right'
          alignY='top'
          shapeDefaults={{ fill: true }}
          shapes={[
            { x: -1, y: 2, type: 'wedge', color: 'purple-500', rotate: -90 },
            { x: -1, y: 4, type: 'square', color: 'blurple-500' },
            { x: 0, y: 3, type: 'wedge', color: 'aqua-500', rotate: 90 },
            { x: 1, y: 3, type: 'square', color: 'aqua-300' },
          ]}
        />

        <Typography
          variant='h1'
          className='tw-mx-auto text-center mt-lg tw-mb-4'
        >
          Ready to go digital?
        </Typography>

        <Typography variant='p1' className='tw-mx-auto text-center tw-mb-6'>
          Blissbook fits companies of all sizes.
        </Typography>

        <div
          className='tw-flex tw-justify-center btn-group tw-mx-auto tw-mb-10'
          css={{ maxWidth: '100%', width: 700 }}
        >
          {plans.map((plan, index) => {
            const isActive = plan === currentPlan
            return (
              <button
                key={index}
                children={plan.label}
                className={classnames('btn tw-h-auto tw-py-2', {
                  'btn-primary selected': isActive,
                })}
                css={{ fontSize: 18, fontWeight: 'bold' }}
                onClick={() => setPlanIndex(index)}
                type='button'
              />
            )
          })}
        </div>

        <currentPlan.Component />

        <Typography
          variant='p2'
          className='tw-mx-auto text-center tw-text-white tw-mb-0'
        >
          Special requirements? Can’t find the feature you need? Please{' '}
          <Link to='/request-a-demo' className='-underline'>
            get in touch with us
          </Link>
          .
        </Typography>
      </Container>
    </Section>
  )
}

export default withRouter(PricingSection)
