import { Field } from '@blissbook/ui/lib'
import { setAuthValue } from '@blissbook/ui/marketing/actions'
import { sendPhoneAuth } from '@blissbook/ui/marketing/queries'
import { handleError } from '@blissbook/ui/util/errors'
import { useStore } from '@blissbook/ui/util/store'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { AuthButton, Typography } from '../../components'

const validationSchema = Yup.object().shape({
  phone: Yup.string().required(),
})

export const PhoneForm = ({ setUserId }) => {
  const values = useStore('auth')
  const organization = useStore('organization')

  const onSubmit = async (values, { setStatus }) => {
    setStatus({ isSubmitting: true })

    try {
      const { phone } = values
      const { userId } = await sendPhoneAuth(phone)
      if (userId) {
        setUserId(userId)
      }
    } catch (error) {
      handleError(error)
      setStatus()
    }
  }

  return (
    <Formik
      initialValues={values}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ handleSubmit, status: { isSubmitting } = {} }) => (
        <form id='sign-in-phone' noValidate onSubmit={handleSubmit}>
          <fieldset disabled={isSubmitting}>
            <Field
              name='phone'
              label='Your Phone Number'
              onChangeValue={(value) => setAuthValue('phone', value)}
              autoFocus
            />

            <div className='form-group'>
              <AuthButton type='submit'>Next</AuthButton>
            </div>
          </fieldset>

          <Typography variant='muted'>
            By entering your phone number, you give {organization.name}{' '}
            permission to send you a text message. You are responsible for any
            and all charges that result from this action and you will not be
            reimbursed. If you’re uncomfortable with any of this, please contact
            an Administrator for assistance.
          </Typography>
        </form>
      )}
    </Formik>
  )
}

export const obfuscatePhone = (phone) => {
  const digitCount = phone.replace(/\D/g, '').length
  let hideDigitCount = digitCount - 3

  let result = ''
  for (let index = 0; index < phone.length; index++) {
    let char = phone[index]
    if (char.match(/\d/) && hideDigitCount > 0) {
      hideDigitCount -= 1
      char = 'x'
    }
    result += char
  }
  return result
}

export const PhoneAuthCodeHeader = ({ authId }) => (
  <Typography className='auth' variant='p2'>
    We sent a 6-digit code to {obfuscatePhone(authId)}.
  </Typography>
)
