module.exports = [
  require('./personalized').default,
  require('./read-more').default,
  require('./annotations').default,
  require('./search').default,
  require('./links').default,
  require('./sso').default,
  require('./accessibility').default,
  require('./video').default,
  require('./trusted').default,
  require('./toc').default,
]
