import { sanitizeHtml } from '@blissbook/lib/sanitize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import React from 'react'
import Card from '../Card'
import Typography from '../Typography'

const renderTweetHtml = (tweet) => {
  const { entities } = tweet

  // Determine changes
  const changes = sortBy(
    [
      // Hashtags
      ...entities.hashtags.map((hashtag) => ({
        indices: hashtag.indices,
        html: `<a href='https://twitter.com/hashtag/${hashtag.text}' rel='noopener noreferrer' target='_blank'>#${hashtag.text}<a/>`,
      })),
      // URLs
      ...entities.urls.map((url) => ({
        indices: url.indices,
        html: `<a href='${url.expanded_url}'rel='noopener noreferrer'  target='_blank'>${url.url}</a>`,
      })),
      // User Mentions
      ...entities.user_mentions.map((mention) => ({
        indices: mention.indices,
        html: `<a href='https://twitter.com/${mention.name}' rel='noopener noreferrer' target='_blank'>@${mention.name}<a/>`,
      })),
    ],
    (change) => -change.indices[0],
  )

  // Apply changes
  return changes.reduce((html, change) => {
    const [start, end] = change.indices
    return html.slice(0, start) + change.html + html.slice(end)
  }, tweet.text)
}

export default ({ tweet, ...props }) => {
  const html = renderTweetHtml(tweet)
  return (
    <Card
      {...props}
      color='aqua-500'
      title={
        <FontAwesomeIcon
          icon={['fab', 'twitter']}
          css={{ fontSize: 24, margin: '-3px 0', opacity: 0.5 }}
        />
      }
    >
      <Typography
        variant='p2'
        css={{ fontSize: 18 }}
        // biome-ignore lint/security/noDangerouslySetInnerHtml: filtered manually
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}
      />

      <div className='tw-text-gray-500 text-right' css={{ fontSize: '80%' }}>
        {moment(tweet.createdAt).fromNow()}
      </div>
    </Card>
  )
}
