import { colors, fontFamily, gridBreakpoints } from '@blissbook/ui/branding'
import {
  BackgroundImage,
  Container,
  DesktopBrowser,
  ForwardLink,
  Link,
  Row,
  Shape,
  ShapeConfetti,
  ShapeGrid,
} from '@blissbook/ui/lib'
import {
  useParallax,
  useParallaxScreenRatio,
} from '@blissbook/ui/util/parallax'
import { css } from '@emotion/react'
import React, { useState } from 'react'
import tinycolor from 'tinycolor2'
import {
  Layout,
  MaxWidthContainer,
  QuoteColumn,
  Section,
  Typography,
} from '../components'
import { CallToActionSection } from './sections'

const imageFolder = '/images/marketing/customers/'

const TESTIMONIALS = {
  slalom: {
    color: '#0C62FB',
    company: 'Slalom',
    companyUrl: 'https://slalom.com',
    handbook: imageFolder + 'slalom/slalom.jpg',
    isMobile: true,
    logo: imageFolder + 'slalom/slalom-logo.svg',
    name: 'Mel Murphy',
    photo: imageFolder + 'slalom/mel.jpg',
    quote:
      'Blissbook went above and beyond our already high expectations, responded with kindness to no less than a million questions, phone calls (some just for emotional support), emails, late nights, customization and design requests, and all with an extremely tight deadline. From beginning to execution to post-launch, support has been incredible. The result of this hard work is a beautiful global handbook we are so proud of and that is very Slalom.',
    title: 'Policy and Compliance Program Manager, Global People Team',
  },
  swbc: {
    color: '#28334A',
    company: 'SWBC',
    companyUrl: 'https://www.swbc.com',
    handbook: imageFolder + 'swbc/swbc-handbook.jpg',
    isMobile: true,
    logo: imageFolder + 'swbc/swbc-logo.svg',
    name: 'Mystel Duke',
    photo: imageFolder + 'swbc/mystel.jpg',
    quote: `Blissbook replaced our custom in-house policy management platform. They built custom features in a timely manner and we love how easy it is to update content, communicate changes with employees, and report on acknowledgements. We couldn't be happier!`,
    title: 'Senior Vice President, Human Resources',
  },
}

const QuoteAttribution = ({ name, job, ...props }) => (
  <footer
    className='blockquote-attribution'
    style={{
      marginTop: '40px',
      fontSize: '80%',
      fontStyle: 'normal',
      fontFamily: fontFamily,
    }}
    {...props}
  >
    <cite
      style={{
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontStyle: 'normal',
        display: 'block',
        fontSize: '95%',
        letterSpacing: '2px',
      }}
    >
      {job}
    </cite>
  </footer>
)

const HandbookImage = ({ scrollY, style, ...props }) => {
  const [node, setNode] = useState()
  const height = useParallax(node, (node) => node.offsetHeight)
  const parentHeight = useParallax(node, (node) => node.parentNode.offsetHeight)

  // Bound the top
  if (height && parentHeight) {
    const maxScrollY = height - parentHeight
    scrollY = Math.min(scrollY, maxScrollY)
  }

  return (
    <img
      {...props}
      alt={props.alt || 'Handbook'}
      css={{
        transition: 'top 1ms linear',
      }}
      ref={setNode}
      style={{ ...style, top: -scrollY }}
    />
  )
}

const Profile = ({ testimonial, ...props }) => (
  <MaxWidthContainer {...props} className='tw-flex' xs='75%' lg='none'>
    <div className='tw-mr-4'>
      <BackgroundImage
        src={testimonial.photo}
        height={50}
        width={50}
        className='rounded-circle'
      />
    </div>
    <div>
      <Typography variant='h4' className='tw-mb-0 tw-text-gray-800'>
        {testimonial.name}
      </Typography>
      <Typography variant='p2' className='tw-mb-0'>
        <span className='tw-inline lg:tw-hidden'>{testimonial.title}</span>
        <span className='tw-hidden lg:tw-inline'>
          {testimonial.title} at{' '}
          <Link
            href={testimonial.companyUrl}
            css={{ color: 'inherit', '&:hover': { color: 'inherit' } }}
          >
            {testimonial.company}
          </Link>
        </span>
      </Typography>
    </div>
  </MaxWidthContainer>
)

const Testimonial = ({ testimonial, ...props }) => {
  const [browserNode, setBrowserNode] = useState()
  const browserRatio = useParallaxScreenRatio(browserNode, {
    attachment: 'top',
    range: [0.5, -1],
  })

  return (
    <div style={{ position: 'relative' }} {...props}>
      <ShapeGrid
        style={{
          position: 'absolute',
          left: 0,
          bottom: -180,
        }}
        alignX='left'
        alignY='bottom'
        shapes={[
          {
            x: 1,
            y: 1,
            type: 'wedge',
            color: tinycolor(testimonial.color).setAlpha(0.2).toRgbString(),
            fill: true,
            rotate: -90,
          },
          {
            x: 0,
            y: 0,
            type: 'square',
            color: tinycolor(testimonial.color).setAlpha(0.8).toRgbString(),
            fill: true,
          },
        ]}
      />
      <div className='tw-flex tw-align-stretch'>
        <div
          css={{ flex: 1 }}
          className='tw-hidden lg:tw-flex tw-justify-end tw-mr-18'
        >
          <DesktopBrowser
            className='box-shadow'
            css={{ width: '75%' }}
            height={700}
            ref={setBrowserNode}
          >
            <HandbookImage
              css={css(`
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
              `)}
              scrollY={!browserRatio ? 0 : browserRatio * 1250}
              src={testimonial.handbook}
              width='100%'
            />
          </DesktopBrowser>
        </div>
        <div css={{ flex: 1, position: 'relative' }}>
          <div
            className='mt-sm'
            css={css(`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            `)}
          >
            <Shape
              type='quotes'
              color='sunshine-500'
              size={50}
              className='tw-mb-8'
            />

            <Typography variant='quote' className='tw-mb-10'>
              {testimonial.quote}
            </Typography>

            <Profile testimonial={testimonial} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default () => (
  <Layout
    title='We love our customers, and they love us.'
    description='Blissbook Customers are HR Professionals who want to up their game and create, distribute and maintain an online digital employee handbook.'
  >
    <Section className='py-sm'>
      <Container
        className='container tw-flex tw-flex-col tw-items-center'
        css={css(`
          position: relative;
          @media (min-width: ${gridBreakpoints.lg}px) {
            height: 230px;
          }
        `)}
      >
        <ShapeConfetti
          css={css(`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
          `)}
          className='tw-hidden lg:tw-block'
          shapes={[
            {
              x: -500,
              y: -40,
              type: 'wedge',
              color: 'aqua-500',
              rotate: 180,
            },
            { x: -560, y: 120, type: 'square', color: 'red-500', fill: true },
            {
              x: -320,
              y: 60,
              type: 'wedge',
              color: 'purple-500',
              fill: true,
            },
            { x: -390, y: 190, type: 'quote', color: 'aqua-500', fill: true },
            {
              x: -500,
              y: 270,
              type: 'wedge',
              color: 'sunshine-500',
              fill: true,
            },

            {
              x: 500,
              y: -10,
              type: 'wedge',
              color: 'purple-500',
              fill: false,
            },
            {
              x: 350,
              y: 60,
              type: 'square',
              color: 'red-500',
              rotate: 45,
              fill: true,
            },
            {
              x: 550,
              y: 80,
              type: 'wedge',
              color: 'sunshine-500',
              fill: true,
              rotate: 180,
            },
            {
              x: 400,
              y: 200,
              type: 'square',
              color: 'aqua-500',
              fill: false,
            },
            {
              x: 490,
              y: 290,
              type: 'quote',
              color: 'purple-500',
              fill: true,
            },
          ]}
          showCannonAnimation
          showOrbitAnimation
        />
        <Typography variant='h1' className='text-center tw-pt-14'>
          <span
            className='h3'
            style={{
              display: 'block',
              color: colors['blurple-500'],
            }}
          >
            We love our
          </span>
          <em style={{ fontSize: 80 }}>Customers</em>
        </Typography>
      </Container>
    </Section>

    <Section>
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Typography variant='h1' className='text-center'>
            And they love us!
          </Typography>
          <Row className='tw-pt-14'>
            <QuoteColumn className='col-md-7' includeIcon={true}>
              <Typography variant='quote' className='tw-mb-0'>
                “Blissbook has <strong>saved many hours</strong> &amp;
                demonstrated the effectiveness &amp; professionalism of our
                governance to external assessors &amp; audits.{' '}
                <strong>The ROI for this product is very high.</strong>”
                <QuoteAttribution job='SVP, Chief Information Security Officer' />
              </Typography>
            </QuoteColumn>
            <QuoteColumn className='col-md-5'>
              <Typography variant='quote' className='tw-mb-0'>
                “So <strong>impressed with Blissbook</strong> and am so grateful
                I found it. Many books to come for our org.”
                <QuoteAttribution job='HR Manager' />
              </Typography>
            </QuoteColumn>
            <QuoteColumn className='col-md-5'>
              <Typography variant='quote' className='tw-mb-0'>
                “You just made my life way easier.”
                <QuoteAttribution job='HR Business Partner' />
              </Typography>
            </QuoteColumn>
            <QuoteColumn className='col-md-7'>
              <Typography variant='quote' className='tw-mb-0'>
                “So glad we came across Blissbook –{' '}
                <strong>our employees love it.</strong>”
                <QuoteAttribution job='Executive Assistant' />
              </Typography>
            </QuoteColumn>
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section className='tw-hidden lg:tw-block'>
      <Container className='py-sm'>
        <Testimonial testimonial={TESTIMONIALS.slalom} />
      </Container>
    </Section>

    <Section>
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row className='tw-pt-14'>
            <QuoteColumn className='col-md-7' includeIcon={true}>
              <Typography variant='quote' className='tw-mb-0'>
                “Blissbook has by far{' '}
                <strong>exceeded all of our expectations</strong> and it is a
                pleasure to be able to work with you. Keep up the good work! :)”
                <QuoteAttribution job='HR Coordinator' />
              </Typography>
            </QuoteColumn>
            <QuoteColumn className='col-md-5'>
              <Typography variant='quote' className='tw-mb-0'>
                “You are so awesome to work with! I really appreciate your{' '}
                <strong>great customer service!</strong>”
                <QuoteAttribution job='HR Generalist' />
              </Typography>
            </QuoteColumn>
            <QuoteColumn className='col-md-5'>
              <Typography variant='quote' className='tw-mb-0'>
                “Love your product--it's brilliant.”
                <QuoteAttribution job='Corporate Counsel' />
              </Typography>
            </QuoteColumn>
            <QuoteColumn className='col-md-7'>
              <Typography variant='quote' className='tw-mb-0'>
                “What a cool feature. I love how you guys are{' '}
                <strong>always improving.</strong>”
                <QuoteAttribution job='Communications Specialist' />
              </Typography>
            </QuoteColumn>
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section className='tw-hidden lg:tw-block'>
      <Container className='py-sm'>
        <Testimonial testimonial={TESTIMONIALS.swbc} />
      </Container>
    </Section>

    <Section>
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row className='tw-pt-14'>
            <QuoteColumn className='col-md-7' includeIcon={true}>
              <Typography variant='quote' className='tw-mb-0'>
                “Thank you, from the bottom of my heart. We had a successful
                launch and our <strong>CEO is very happy</strong> 😊.”
                <QuoteAttribution job='Policy &amp; Compliance Program Manager' />
              </Typography>
            </QuoteColumn>
            <QuoteColumn className='col-md-5'>
              <Typography variant='quote' className='tw-mb-0'>
                “We are really thankful for Blissbook and happy with the
                platform.”
                <QuoteAttribution job='VP, Operations' />
              </Typography>
            </QuoteColumn>
            <QuoteColumn className='col-md-4'>
              <Typography variant='quote' className='tw-mb-0'>
                “We love our Blissbook HR Policy Manual 😉”
                <QuoteAttribution job='Managing Director' />
              </Typography>
            </QuoteColumn>
            <QuoteColumn className='col-md-8'>
              <Typography variant='quote' className='tw-mb-0'>
                “We received RAVING reviews from our employees, including our
                CEO, on the Culture Book. This is such an amazing product!”
                <QuoteAttribution job='Director People Analytics, Systems &amp; Operations' />
              </Typography>
            </QuoteColumn>
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <CallToActionSection
      title='Ready for your quote to be listed here?'
      description='We’re here for you! Get in touch so we can learn about you and your project. We can give a demo and set up a free trial as needed.'
      contentClassName=''
      includeLogos
    >
      <div className='md:tw-flex tw-items-center tw-mb-8'>
        <Link className='btn btn-primary tw-mr-6' to='/pricing'>
          Try it free
        </Link>
        <ForwardLink className='tw-block tw-my-6' to='/request-a-demo'>
          Request a demo
        </ForwardLink>
      </div>
    </CallToActionSection>
  </Layout>
)
