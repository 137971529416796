import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import {
  Col,
  Container,
  DesktopBrowser,
  ForwardLink,
  Link,
  MobileBrowser,
  Row,
} from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import React from 'react'
import tinycolor from 'tinycolor2'
import {
  HeadingDivider,
  Layout,
  MaxWidthContainer,
  Section,
  Typography,
} from '../components'
import { CallToActionSection, SolutionSection } from './sections'

const backgroundColor = tinycolor(colors['blurple-200'])
  .setAlpha(0.5)
  .toRgbString()
const color = 'blurple-500'

const FEATURES = [
  {
    image: '/images/marketing/solutions/SunBlurple.svg',
    title: 'Your company culture will shine in Blissbook.',
    description:
      'Company culture (your “why”) is defined by your values, purpose, mission, vision, or one of a dozen other words our customers use. No matter what you call it, there’s a design template to make your culture stand out.',
  },
  {
    image: '/images/marketing/solutions/MediaBlurple.svg',
    title: 'Make your handbook pop with images and videos.',
    description:
      'Add images throughout your handbook to make it your own and reflect your company’s culture and brand. Videos can be easily embedded from YouTube, Vimeo, and Wistia.',
  },
  {
    image: '/images/marketing/solutions/Brain.svg',
    title: 'Influence behavior with the power of design.',
    description: (
      <span>
        Blissbook uses a{' '}
        <a href='https://blissbook.com/blog/the-psychology-behind-blissbook/ '>
          variety of techniques rooted in behavioral psychology
        </a>{' '}
        to reduce your risk of litigation, improve compliance, and encourage
        employee engagement.
      </span>
    ),
  },
  {
    image: '/images/marketing/solutions/WWW.svg',
    title: 'Custom domains secured with HTTPS.',
    description:
      'Give your account a custom domain, like policies.yourcompany.com, so your content is trusted, branded, and easy to find. Custom domains are secured via HTTPS, which keeps IT happy!',
  },
  {
    image: '/images/marketing/solutions/Mailbox.svg',
    title: 'Custom sending email account.',
    description:
      'Employees trust email sent through Blissbook because everything looks and feels like it’s coming from you. Set up a custom sending email account and design emails to reflect your brand.',
  },
  {
    image: '/images/marketing/solutions/KeyCloud.svg',
    title: 'Skip the sign-in page with SSO.',
    description:
      'With Single Sign-On, you can have us send users right to your SSO sign-in page. Employees get an experience they’re used to and are confident they’re securely signing in to an official resource.',
  },
]

export default () => (
  <Layout
    title='Branded, Interactive, Digital Employee Handbooks – Blissbook'
    description={
      'Customize Blissbook’s mobile-friendly employee handbook template to fit your brand then launch your new interactive, digital handbook.'
    }
  >
    <SolutionSection
      color={color}
      image='CloudsBlurple.svg'
      imageDesktopCss={css(`
        top: 70px;
        right: -250px;
        height: 400px;

        @media (min-width: ${gridBreakpoints.xl}px) {
          right: -220px;
        }
      `)}
      imageMobileCss={css(`
        transform: scaleX(-1);
      `)}
      greeting='BEAUTIFUL &amp; UNIQUE'
      title='100% custom &amp; brandable.'
      description='Your employees’ experience with Blissbook can be 100% customized and branded for your organization. Your handbook is beautiful and accessible for employees on any device. They don’t even need to know what Blissbook is.'
    />

    <Section
      css={{
        background: `linear-gradient(180deg, white 0%, ${backgroundColor} 100%);`,
      }}
    >
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row className='tw-flex-row-reverse'>
            <Col xs={12} lg={6} className='mb-sm'>
              <div className='tw-flex tw-flex-col tw-items-center'>
                <DesktopBrowser className='box-shadow' css={{ width: 400 }}>
                  <img
                    alt='a Blissbook handbook on a desktop computer screen'
                    src='/images/marketing/home/Create-desktop.svg'
                    width='100%'
                  />
                </DesktopBrowser>
                <MobileBrowser
                  className='box-shadow'
                  css={{
                    marginTop: -100,
                    marginRight: -320,
                    width: 150,
                    zIndex: 1,
                  }}
                >
                  <img
                    alt='a Blissbook handbook on a mobile phone screen'
                    src='/images/marketing/home/Create-mobile.svg'
                    width='100%'
                    css={{
                      background: `linear-gradient(white 50%,${colors['blurple-200']} 50%);`,
                    }}
                  />
                </MobileBrowser>
              </div>
            </Col>
            <Col xs={12} lg={6} className='mb-sm'>
              <HeadingDivider variant='h2' divider={{ color }}>
                Your handbook is fully brandable.
              </HeadingDivider>
              <Typography variant='p2'>
                Your content in Blissbook is beautifully designed,
                mobile-friendly, and fully customizable. In addition to several
                built-in design tools (custom fonts, colors, etc.), Blissbook
                allows custom CSS to make your handbook & policies pixel
                perfect. We can even do the design for you; just send us your
                brand guidelines.
              </Typography>
              <Typography variant='p2'>
                Because your content is a website (not a PDF), you don’t need a
                graphic design team to keep your layout from breaking when you
                change content. It just works!
              </Typography>
            </Col>
          </Row>

          <Row>
            {FEATURES.map((feature, index) => (
              <Col key={index} xs={12} md={6} className='tw-mb-10'>
                <MaxWidthContainer md={400}>
                  <If condition={feature.icon}>{feature.icon}</If>
                  <If condition={feature.image}>
                    <img
                      alt='feature icon'
                      src={feature.image}
                      style={{ fill: colors['blurple-500'] }}
                      className='tw-mb-10'
                      css={{ height: 90 }}
                    />
                  </If>
                  <Typography variant='h2'>{feature.title}</Typography>
                  <Typography variant='p2'>{feature.description}</Typography>
                </MaxWidthContainer>
              </Col>
            ))}
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <CallToActionSection
      title='Ready for user-friendly policy management?'
      description='We’re here for you! Get in touch so we can learn about you and your project. We can give a demo and set up a free trial as needed.'
      contentClassName=''
      includeLogos
    >
      <div className='md:tw-flex tw-items-center tw-mb-8'>
        <Link className='btn btn-primary tw-mr-6' to='/pricing'>
          Try it free
        </Link>
        <ForwardLink className='tw-block tw-my-6' to='/request-a-demo'>
          Request a demo
        </ForwardLink>
      </div>
    </CallToActionSection>
  </Layout>
)
