import { colors } from '@blissbook/ui/branding'
import { BackgroundImage, Col, Link, Row, Shape } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { MaxWidthContainer, Section, Typography } from '../../components'

const ListItem = ({ children, color, title }) => (
  <div className='tw-flex tw-mb-6'>
    <div className='tw-mr-8' css={{ marginTop: 12 }}>
      <Shape type='wedge' size={20} color={color} rotate={180} fill />
    </div>
    <MaxWidthContainer lg={580}>
      <Typography variant='h2' className='tw-mb-2'>
        {title}
      </Typography>
      {children}
    </MaxWidthContainer>
  </div>
)

const SocialLink = ({ icon, ...props }) => (
  <Link
    {...props}
    css={{ color: colors['aqua-300'], fontSize: 20 }}
    className='tw-mr-2'
  >
    <FontAwesomeIcon icon={['fab', icon]} />
  </Link>
)

const profileBorderWidth = 2
const profileSize = 90

const Profile = ({
  fullName,
  image,
  linkedInUrl,
  title,
  twitterUrl,
  websiteUrl,
}) => (
  <div className='tw-flex tw-items-center'>
    <div
      className='rounded-circle tw-mr-4'
      css={{
        border: `${profileBorderWidth}px solid ${colors['red-500']}`,
        height: profileSize + profileBorderWidth * 2,
        width: profileSize + profileBorderWidth * 2,
      }}
    >
      <BackgroundImage
        src={image}
        height={profileSize}
        width={profileSize}
        className='rounded-circle'
        css={{ filter: 'grayscale(100%)' }}
      />
    </div>
    <div>
      <Typography variant='p2' className='tw-mb-0'>
        <strong>{fullName}</strong>
      </Typography>
      <Typography variant='p2' className='tw-mb-0 tw-text-base'>
        {title}
      </Typography>
      <div className='tw-flex tw-items-center'>
        <If condition={twitterUrl}>
          <SocialLink href={twitterUrl} icon='twitter' />
        </If>
        <If condition={linkedInUrl}>
          <SocialLink href={linkedInUrl} icon='linkedin-in' />
        </If>
        <If condition={websiteUrl}>
          <SocialLink href={websiteUrl} icon='internet-explorer' />
        </If>
      </div>
    </div>
  </div>
)

const PROFILES = [
  {
    fullName: `Tom O'Dea`,
    image: '/images/marketing/about/tom.jpg',
    linkedInUrl: 'http://linkedin.com/in/tomodea',
    title: 'Co-Founder',
    twitterUrl: 'https://twitter.com/tom_odea',
  },
  {
    fullName: 'Sam Duvall',
    image: '/images/marketing/about/sam-d.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/samduvall',
    title: 'Co-Founder',
    twitterUrl: 'https://twitter.com/samwduvall',
  },
]

const TEAM = [
  {
    fullName: 'Drew Dotson',
    image: '/images/marketing/about/drew.jpg',
    linkedInUrl: 'https://www.linkedin.com/in/drewdotson/',
    title: 'Copywriting',
    twitterUrl: 'https://twitter.com/drewdotson',
  },
  {
    fullName: 'Amanda McNay',
    image: '/images/marketing/about/amanda.jpg',
    websiteUrl: 'https://ajmdesignstudio.com',
    title: 'Design',
  },
  {
    fullName: 'Sarah Whiddon',
    image: '/images/marketing/about/sarah.jpg',
    title: 'Project Mgmt',
  },
  {
    fullName: 'Angeli Pacatang',
    image: '/images/marketing/about/angeli.jpg',
    title: 'Executive Assistant',
  },
  {
    fullName: 'P-A St-Jean',
    image: '/images/marketing/about/p-a.jpg',
    title: 'Engineering',
  },
  {
    fullName: 'Jeff Beck',
    image: '/images/marketing/about/jeff.jpg',
    title: 'Customer Enablement',
  },
  {
    fullName: 'Diane Marshall',
    image: '/images/marketing/about/diane.jpeg',
    title: 'Customer Success',
  },
  {
    fullName: 'Umair Khan',
    image: '/images/marketing/about/umair.jpg',
    title: 'Full Stack Developer',
  },
  {
    fullName: 'Shawnna Johnson',
    image: '/images/marketing/about/shawnna.jpg',
    title: 'HR Consulting',
  },
]

export default () => (
  <Section id='did-you-know' className='container pt-sm'>
    <MaxWidthContainer className='tw-mx-auto' xs={960}>
      <Row>
        <Col xs={12} lg={4}>
          <Typography variant='h1'>Did you know?</Typography>
        </Col>
        <Col xs={12} lg={8}>
          <ListItem color='sunshine-500' title='You’re our only boss.'>
            <Typography variant='p2'>
              Our company was founded in 2010. We’re privately owned,
              profitable, and do not answer to anyone other than our customers
              and ourselves. We aim to stay profitable as we build a company
              with products customers love and a culture that provides employees
              with the opportunity for fulfillment.
            </Typography>

            <Row className='tw-flex tw-items-center justify-content-start tw-mt-6'>
              {PROFILES.map((profile, index) => (
                <Col key={index} xs={12} sm='auto'>
                  <Profile {...profile} />
                </Col>
              ))}
            </Row>
          </ListItem>

          <ListItem color='aqua-500' title='We launched at an HR conference.'>
            <Typography variant='p2'>
              Blissbook was launched in beta at the HR Tech conference in
              October 2013 and, after 2 years of R&D, re-launched in 2015.
            </Typography>
          </ListItem>

          <ListItem
            color='blurple-500'
            title='Blissbook rose from the ashes of a PDF.'
          >
            <Typography variant='p2'>
              Our first contract was to design a PDF handbook to be printed as a
              physical book for 800+ employees. By the time our client’s
              stakeholders finished reviewing and approving the book, we had
              built v1 of Blissbook with a digital version of the handbook ready
              to launch. The client never printed a single book.
            </Typography>
          </ListItem>

          <ListItem color='red-500' title='Our team is awesome!'>
            <div className='tw-grid md:tw-grid-cols-2 tw-gap-6'>
              {TEAM.map((profile, index) => (
                <Profile {...profile} key={index} />
              ))}
            </div>
          </ListItem>
        </Col>
      </Row>
    </MaxWidthContainer>
  </Section>
)
