import { Link } from '@blissbook/ui/lib'
import React from 'react'
import { HeadingDivider, LegalHeader, LegalLayout } from '../components'

export default () => (
  <LegalLayout
    title='Blissbook Data Processing Addendum'
    description={`Blissbook is policy management for everyone. Even if you're in the EU or EEA, we keep your personal information protected.`}
  >
    <LegalHeader
      heading='Read Through Our'
      lastUpdated='May 24, 2024'
      title='Data Processing Addendum'
    />

    <p>
      This Data Processing Addendum (“DPA”), amends and supplements any existing
      and currently valid Master Services Agreement or Terms of Use (the
      “Agreement”) either previously or concurrently made between you (together
      with subsidiary(ies) and affiliated entities, collectively, “Customer”)
      and Rocket Whale, Products, LLC, a Delaware limited liability company
      (together with its affiliated entities, collectively “Blissbook”).
    </p>

    <p>
      All capitalized terms not defined in this DPA shall have the meanings set
      forth in the Agreement.
    </p>

    <p>All references to the “Agreement” include all Annexes thereto.</p>

    <p>
      This DPA is effective the date Blissbook begins to process Personal Data
      on behalf of Customer (the “Effective Date”).
    </p>

    <p>The parties agree as follows:</p>

    <HeadingDivider variant='h2'>1. Definitions</HeadingDivider>
    <p>
      <strong>"Control"</strong> means an ownership, voting or similar interest
      representing fifty percent (50%) or more of the total interests then
      outstanding of the entity in question. The term "Controlled" shall be
      construed accordingly.
    </p>

    <p>
      <strong>"Customer Data"</strong> means any Personal Data that Blissbook
      processes on behalf of Customer as a Data Processor in the course of
      providing Services, as more particularly described in this DPA.
    </p>

    <p>
      <strong>"Data Protection Laws"</strong> means all data protection and
      privacy laws applicable to the processing of Personal Data under the
      Agreement, including, where applicable, EU Data Protection Law.
    </p>

    <p>
      <strong>"Data Controller"</strong> means an entity that determines the
      purposes and means of the processing of Personal Data.
    </p>

    <p>
      <strong>"Data Processor"</strong> means an entity that processes Personal
      Data on behalf of a Data Controller.
    </p>

    <p>
      <strong>"EU Data Protection Law"</strong> means Regulation 2016/679 of the
      European Parliament and of the Council on the protection of natural
      persons with regard to the processing of Personal Data and on the free
      movement of such data (General Data Protection Regulation) ("GDPR"); and
      (ii) Directive 2002/58/EC concerning the processing of Personal Data and
      the protection of privacy in the electronic communications sector and
      applicable national implementations of it (as may be amended, superseded
      or replaced).
    </p>

    <p>
      <strong>"EEA"</strong> means, for the purposes of this DPA, the European
      Economic Area, United Kingdom and Switzerland.
    </p>

    <p>
      <strong>"Group"</strong> means any and all Affiliates that are part of an
      entity's corporate group.
    </p>

    <p>
      <strong>"Personal Data"</strong> means any non-public information relating
      to an identified or identifiable natural person.
    </p>

    <p>
      <strong>"Processing"</strong> has the meaning given to it in the GDPR and
      "process", "processes" and "processed" shall be interpreted accordingly.
    </p>

    <p>
      <strong>"Security Incident"</strong> means any unauthorized or unlawful
      breach of security that leads to the accidental or unlawful destruction,
      loss, alteration, unauthorized disclosure of or access to Customer Data.
    </p>

    <p>
      <strong>"Sensitive Data"</strong> means (a) social security number, tax
      file number, passport number, driver's license number, or similar
      identifier (or any portion thereof); (b) credit or debit card number
      (other than the truncated (last four digits) of a credit or debit card);
      (c) financial, credit, genetic, biometric or health information; (d)
      racial, ethnic, political or religious affiliation, information about
      sexual life or sexual orientation, or criminal record; (e) account
      passwords; or (f) other information that falls within the definition of
      "special categories of data" or "special personal information" under
      applicable Data Protection Laws.
    </p>

    <p>
      <strong>"Services"</strong> means any product or service provided by
      Blissbook to Customer pursuant to the Agreement.
    </p>

    <p>
      <strong>"Sub-processor"</strong> means any Data Processor engaged by
      Blissbook to assist in fulfilling its obligations with respect to
      providing the Services pursuant to the Agreement or this DPA.
    </p>

    <HeadingDivider variant='h2'>
      2. Relationship with the Agreement
    </HeadingDivider>
    <p>
      2.1 The parties agree that DPA shall replace any existing DPA the parties
      may have previously entered into in connection with the Services.
    </p>

    <p>
      2.2 Except for the changes made by this DPA, the Agreement remains
      unchanged and in full force and effect. If there is any conflict between
      this DPA and the Agreement, this DPA shall prevail to the extent of that
      conflict.
    </p>

    <p>
      2.3 Any claims brought under or in connection with this DPA shall be
      subject to the terms and conditions, including but not limited to, the
      exclusions and limitations set forth in the Agreement.
    </p>

    <p>
      2.4 Any claims against Blissbook under this DPA shall be brought solely
      against the entity that is a party to the Agreement. In no event shall any
      party limit its liability with respect to any individual's data protection
      rights under this DPA or otherwise. Customer further agrees that any
      regulatory penalties incurred by Blissbook in relation to the Customer
      Data that arise as a result of, or in connection with, Customer’s failure
      to comply with its obligations under this DPA or any applicable Data
      Protection Laws shall count toward and reduce Blissbook’s liability under
      the Agreement as if it were liability to the Customer under the Agreement.
    </p>

    <p>
      2.5 No one other than a party to this DPA, its successors and permitted
      assignees shall have any right to enforce any of its terms.
    </p>

    <p>
      2.6 This DPA shall be governed by and construed in accordance with
      governing law and jurisdiction provisions in the Agreement, unless
      required otherwise by applicable Data Protection Laws.
    </p>

    <HeadingDivider variant='h2'>
      3. Scope and Applicability of this DPA
    </HeadingDivider>
    <p>
      This DPA applies where and only to the extent that Blissbook processes
      Customer Data that originates from the EEA and/or that is otherwise
      subject to EU Data Protection Law on behalf of Customer as Data Processor
      in the course of providing Services pursuant to the Agreement.
    </p>

    <HeadingDivider variant='h2'>
      4. Roles and Scope of Processing
    </HeadingDivider>
    <p>
      4.1 Role of the Parties. As between Blissbook and Customer, Customer is
      the Data Controller of Customer Data, and Blissbook shall process Customer
      Data only as a Data Processor acting on behalf of Customer.
    </p>

    <p>
      4.2. Customer Processing of Customer Data. Customer agrees that (i) it
      shall comply with its obligations as a Data Controller under Data
      Protection Laws in respect of its processing of Customer Data and any
      processing instructions it issues to Blissbook; and (ii) it has provided
      notice and obtained (or shall obtain) all consents and rights necessary
      under Data Protection Laws for Blissbook to process Customer Data and
      provide the Services pursuant to the Agreement and this DPA.
    </p>

    <p>
      4.3 Blissbook Processing of Customer Data. Blissbook shall process
      Customer Data only for the purposes described in this DPA and only in
      accordance with Customer’s documented lawful instructions. The parties
      agree that this DPA and the Agreement set out the Customer’s complete and
      final instructions to Blissbook in relation to the processing of Customer
      Data and processing outside the scope of these instructions (if any) shall
      require prior written agreement between Customer and Blissbook.
    </p>

    <p>4.4 Details of Data Processing</p>
    <ol className='alpha'>
      <li>
        Subject matter: The subject matter of the data processing under this DPA
        is the Customer Data.
      </li>
      <li>
        Duration: As between Blissbook and Customer, the duration of the data
        processing under this DPA is until the termination of the Agreement in
        accordance with its terms.
      </li>
      <li>
        Purpose: The purpose of the data processing under this DPA is the
        provision of the Services to the Customer and the performance of
        Blissbook's obligations under the Agreement (including this DPA) or as
        otherwise agreed by the parties.
      </li>
      <li>
        Nature of the processing: Blissbook provides a policy content management
        system and other related services, as described in the Agreement.
      </li>
      <li>
        Categories of data subjects: Any individual accessing and/or using the
        Services through the Customer's account ("Users"); and any individual:
        (i) whose email address is included in the Customer's Employee List or
        otherwise in a Customer’s account as a Contact; or (ii) whose
        information is stored on or collected via the Services.
      </li>
      <li>Types of Customer Data:</li>
    </ol>

    <p>
      For Customers, Users, and Contacts: identification and contact data (name,
      date of birth contact details including email address and/or telephone
      number); financial information (credit card details, account details,
      payment information); employment details (employer, employee ID, hire
      date, geographic location, area of responsibility, other metadata as
      defined by Customer); personal interests or preferences (including
      purchase history, marketing preferences and publicly available social
      media profile information); IT information (IP addresses, usage data,
      cookies data, online navigation data, location data, browser data).
    </p>

    <p>
      4.5 Notwithstanding anything to the contrary in the Agreement (including
      this DPA), Customer acknowledges that Blissbook shall have a right to use
      and disclose data relating to the operation, support and/or use of the
      Services for its legitimate business purposes, such as billing, account
      management, technical support, product development and sales and
      marketing. To the extent any such data is considered Personal Data under
      Data Protection Laws, Blissbook is the Data Controller of such data and
      accordingly shall process such data in accordance with the{' '}
      <Link to='/privacy-policy' title='Blissbook Privacy Policy'>
        Blissbook Privacy Policy
      </Link>{' '}
      and Data Protection Laws.
    </p>

    <p>
      4.6 Tracking Technologies. Customer acknowledges that in connection with
      the performance of the Services, Blissbook employs the use of cookies,
      unique identifiers, web beacons and similar tracking technologies
      ("Tracking Technologies"). Customer shall maintain appropriate notice,
      consent, opt-in and opt-out mechanisms as are required by Data Protection
      Laws to enable Blissbook to deploy Tracking Technologies lawfully on, and
      collect data from, the devices of Subscribers (defined below) in
      accordance with and as described in the{' '}
      <Link to='/privacy-policy' title='Blissbook Privacy Policy'>
        Blissbook Privacy Policy
      </Link>
      .
    </p>

    <HeadingDivider variant='h2'>5. Sub-processing</HeadingDivider>
    <p>
      5.1 Authorized Sub-processors. Customer agrees that Blissbook may engage
      Sub-processors to process Customer Data on Customer's behalf. The
      Sub-processors currently engaged by Blissbook and authorized by Customer
      are listed in Appendix 1.
    </p>

    <p>
      5.2 Sub-processor Obligations. Blissbook shall: (i) enter into a written
      agreement with the Sub-processor imposing data protection terms that
      require the Sub-processor to protect the Customer Data to the standard
      required by Data Protection Laws; and (ii) remain responsible for its
      compliance with the obligations of this DPA and for any acts or omissions
      of the Sub-processor that cause Blissbook to breach any of its obligations
      under this DPA.
    </p>

    <HeadingDivider variant='h2'>6. Security</HeadingDivider>
    <p>
      6.1 Security Measures. Blissbook will maintain reasonable administrative,
      physical, and technical safeguards for protection of the security,
      confidentiality and integrity of personal data transferred to Blissbook as
      described in the Agreement and in the{' '}
      <Link to='/privacy-policy' title='Blissbook Privacy Policy'>
        Blissbook Privacy Policy
      </Link>
      .
    </p>

    <p>
      6.2 Updates to Security Measures. Customer is responsible for reviewing
      the information made available by Blissbook relating to data security and
      making an independent determination as to whether the Services meet
      Customer’s requirements and legal obligations under Data Protection Laws.
      Customer acknowledges that the Security Measures are subject to technical
      progress and development and that Blissbook may update or modify the
      Security Measures from time to time provided that such updates and
      modifications do not result in the degradation of the overall security of
      the Services purchased by the Customer.
    </p>

    <p>
      6.3 Customer Responsibilities. Notwithstanding the above, Customer agrees
      that except as provided by this DPA, Customer is responsible for its
      secure use of the Services, including securing its account authentication
      credentials, protecting the security of Customer Data when in transit to
      and from the Services and taking any appropriate steps to securely encrypt
      or backup any Customer Data uploaded to the Services.
    </p>

    <HeadingDivider variant='h2'>7. Security Reports and Audits</HeadingDivider>
    <p>
      7.1 At the request of the Customer and at the Customer’s cost, Blissbook
      will submit its data processing facilities for audit of the processing
      activities covered by the DPA which shall be carried out by the Customer
      or an inspection body composed of independent members and in possession of
      the required professional qualifications bound by a duty of
      confidentiality, selected by the Customer.
    </p>

    <p>
      7.2 Blissbook shall also provide written responses (on a confidential
      basis) to all reasonable requests for information made by Customer,
      including responses to information security and audit questionnaires that
      are necessary to confirm Blissbook's compliance with this DPA, provided
      that Customer shall not exercise this right more than once per year.
    </p>

    <HeadingDivider variant='h2'>8. International Transfers</HeadingDivider>
    <p>
      8.1 Data center locations. Blissbook may transfer and process Customer
      Data anywhere in the world where Blissbook or its Sub-processors maintain
      data processing operations (including, but not limited to, the United
      States of America). Blissbook shall at all times provide an adequate level
      of protection for the Customer Data processed, in accordance with the
      requirements of Data Protection Laws.
    </p>

    <p>
      8.2 European Data transfers. If Blissbook received Customer Data protected
      by EU Data Protection Laws (“EU Personal Data”) in a country outside of
      the EEA that is not recognized as providing an adequate level of
      protection for personal data (as described in an applicable EU Data
      Protection Law), Blissbook agrees to process EU Personal Data in
      compliance with the EU-US Data Privacy Framework, as more fully set forth
      in our{' '}
      <Link to='/privacy-policy' title='Blissbook Privacy Policy'>
        Privacy Policy
      </Link>
      .
    </p>

    <HeadingDivider variant='h2'>9. Additional Security</HeadingDivider>
    <p>
      9.1 Confidentiality of processing. Blissbook shall ensure that any person
      who is authorized by Blissbook to process Customer Data (including its
      staff, agents and subcontractors) shall be under an appropriate obligation
      of confidentiality (whether a contractual or statutory duty).
    </p>

    <p>
      9.2 Security Incident Response. Upon becoming aware of a Security
      Incident, Blissbook shall notify Customer without undue delay and shall
      provide timely information relating to the Security Incident as it becomes
      known or as is reasonably requested by Customer.
    </p>

    <HeadingDivider variant='h2'>10. Changes to Sub-processors</HeadingDivider>
    <p>
      10.1 Blissbook shall (i) provide an up-to-date list of the Sub-processors
      it has appointed upon written request from Customer; and (ii) notify any
      Customers who have requested such list (for which email shall suffice) if
      it adds or removes Sub-processors at least 10 days prior to any such
      changes.
    </p>

    <p>
      10.2 Customer may object in writing to Blissbook’s appointment of a new
      Sub-processor within five (5) calendar days of such notice, provided that
      such objection is based on reasonable grounds relating to data protection.
      In such event, the parties shall discuss such concerns in good faith with
      a view to achieving a commercially reasonable resolution. If this is not
      possible, Customer may suspend or terminate the Agreement.
    </p>

    <HeadingDivider variant='h2'>11. Return or Deletion of Data</HeadingDivider>
    <p>
      11.1 Upon termination or expiration of the Agreement, or cancelation of
      the Services, Blissbook shall (at Customer's election) delete or return to
      Customer all Customer Data (including copies) in its possession or
      control, save that this requirement shall not apply to the extent
      Blissbook is required by applicable law to retain some or all of the
      Customer Data, or to Customer Data it has archived on back-up systems,
      which Customer Data Blissbook shall securely isolate and protect from any
      further processing, except to the extent required by applicable law. If
      you are a Contact or Visitor and want to exercise any data privacy rights
      granted to you by certain privacy laws, e.g. GDPR, please contact us via
      the form on{' '}
      <Link to='/gdpr' title='Exercise data privacy rights under GDPR'>
        this page
      </Link>
      .
    </p>

    <HeadingDivider variant='h2'>12. Cooperation</HeadingDivider>
    <p>
      12.1 The Services provide Customer with a number of controls that Customer
      may use to retrieve, correct, delete or restrict Customer Data, which
      Customer may use to assist it in connection with its obligations under the
      GDPR, including its obligations relating to responding to requests from
      data subjects or applicable data protection authorities. To the extent
      that Customer is unable to independently access the relevant Customer Data
      within the Services, Blissbook shall (at Customer's expense) provide
      reasonable cooperation to assist Customer to respond to any requests from
      individuals or applicable data protection authorities relating to the
      processing of Personal Data under the Agreement. In the event that any
      such request is made directly to Blissbook, Blissbook shall not respond to
      such communication directly without Customer's prior authorization, unless
      legally compelled to do so. If Blissbook is required to respond to such a
      request, Blissbook shall promptly notify Customer and provide it with a
      copy of the request unless legally prohibited from doing so.
    </p>

    <p>
      12.2 If a law enforcement agency sends Blissbook a demand for Customer
      Data (for example, through a subpoena or court order), Blissbook shall
      attempt to redirect the law enforcement agency to request that data
      directly from Customer. As part of this effort, Blissbook may provide
      Customer’s basic contact information to the law enforcement agency. If
      compelled to disclose Customer Data to a law enforcement agency, then
      Blissbook shall give Customer reasonable notice of the demand to allow
      Customer to seek a protective order or other appropriate remedy unless
      Blissbook is legally prohibited from doing so.
    </p>

    <p>
      12.3 To the extent Blissbook is required under EU Data Protection Law,
      Blissbook shall (at Customer's expense) provide reasonably requested
      information regarding the Services to enable the Customer to carry out
      data protection impact assessments or prior consultations with data
      protection authorities as required by law.
    </p>

    <h1>Appendix 1 - Sub-processors</h1>
    <p>
      Blissbook uses its Affiliates and a range of third party Sub-processors to
      assist it in providing the Services (as described in the Agreement). These
      Sub-processors set out below provide cloud hosting and storage services;
      content delivery and review services; assist in providing customer
      support; as well as incident tracking, response, diagnosis and resolution
      services.
    </p>
    <ul>
      <li>
        Amazon Web Services; Hosting &amp; storage; VA, USA &amp; WA, USA{' '}
      </li>
      <li>Postmark; Transactional email; IL, USA</li>
      <li>Intercom; CRM &amp; helpdesk; CA, USA</li>
      <li>HubSpot; CRM; MA, USA</li>
      <li>Pusher; Real-time events; London, UK</li>
      <li>Google; Email &amp; productivity; CA, USA</li>
      <li>Stripe; Payment storage &amp; processor; CA, USA</li>
      <li>Dropbox; Cloud storage; CA, USA</li>
      <li>
        AgileBits (1Password); Secure information/file transfer; Toronto, Canada
      </li>
      <li>Sentry; Error & Performance Monitoring; CA, USA</li>
      <li>merge.dev; HRIS sync (optional); CA, USA</li>
    </ul>
  </LegalLayout>
)
