import { colors } from '@blissbook/ui/branding'
import { Link } from '@blissbook/ui/lib'
import React from 'react'

import { Typography } from '../../components'

import { images } from '../howToConfig'

export default ({ ...props }) => (
  <div
    className='media tw-px-12 tw-py-4 tw-my-10'
    style={{
      borderRadius: '5px',
      backgroundColor: colors['blurple-500'],
      color: colors.white,
    }}
    {...props}
  >
    <img
      alt="Blissbook's logo"
      src={images.logo.src}
      className='tw-mr-10 tw-mt-2 md:tw-block tw-hidden'
      style={{
        width: '75px',
        height: 'auto',
      }}
    />
    <div className='media-body'>
      <Typography
        variant='p1'
        style={{ fontWeight: '700', marginBottom: '8px' }}
      >
        Be a Handbook Superhero.
      </Typography>
      <Typography
        variant='p2'
        style={{ fontSize: '18px', marginBottom: '10px' }}
      >
        Blissbook is everything you need to create, distribute, and maintain an
        online employee handbook.
      </Typography>
      <Link href='/request-a-demo' className='btn btn-info tw-py-2 tw-px-6'>
        Request a Demo
      </Link>
    </div>
  </div>
)
