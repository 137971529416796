import { colors, getColor, gridBreakpoints } from '@blissbook/ui/branding'
import {
  ContactLink,
  Container,
  Link,
  MailtoSupportLink,
} from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'
import tinycolor from 'tinycolor2'
import {
  DotsVertical,
  Layout,
  MaxWidthContainer,
  Section,
  Typography,
} from '../components'
import { ReadySection, SolutionSection } from './sections'

const backgroundColor = tinycolor(colors['blurple-200'])
  .setAlpha(0.5)
  .toRgbString()
const color = 'blurple-500'

const FEATURES = [
  {
    id: 'browsers-and-devices',
    title: 'Browsers & Devices',
    description: (
      <>
        Blissbook is a modern web application that requires a modern web browser
        to run properly, for example Chrome, Firefox, Safari, and Edge.
        Published handbooks look great on any device, including smartphones and
        tablets.
      </>
    ),
  },
  {
    id: 'data-security',
    title: 'Data Security & Privacy',
    description: (
      <>
        Blissbook maintains an information security program designed to
        safeguard against the misuse, unauthorized access to or destruction,
        loss, or alteration of our customers' confidential information.
        <br />
        <br />
        <img
          src='/images/marketing/security/badges.png'
          className='tw-mb-4'
          alt='SOC 2, GDPR, CCPA badges'
          width='360'
        />
        <br />
        Blissbook has completed a SOC 2 Type I audit and is currently undergoing
        a SOC 2 Type II audit, we expect to receive our certification in Q1
        2025. We adhere to data privacy principles like GDPR and CCPA, and we
        comply with the EU-U.S. Data Privacy Framework (“EU-U.S. DPF”), the UK
        Extension to the EU-U.S. DPF, and the Swiss-U.S. Data Privacy Framework
        (“Swiss-U.S. DPF”) as set forth by the U.S. Department of Commerce. Read
        more in our <Link href='/privacy-policy'>privacy policy</Link>.
        <br />
        <br />
        Modern security features, including (AES) 256-bit encryption, are in
        place to help ensure that all users have a secure, encrypted, and
        private connection to blissbook.com.
        <br />
        <br />
        Read more on{' '}
        <Link href='https://help.blissbook.com/en/articles/4740225-security-and-privacy-of-customer-data'>
          how we keep customer data secure and private
        </Link>
        .
      </>
    ),
  },
  {
    id: 'authentication',
    title: 'User Authentication',
    description: (
      <>
        Blissbook does not store or support passwords for users. For private
        access to Blissbook absent Single Sign-On, users authenticate themselves
        through their private email account or phone number.
      </>
    ),
  },
  {
    id: 'hosting',
    title: 'Hosting',
    description: (
      <>
        Blissbook is hosted by AWS in secure locations with stringent 24x7
        onsite security, using hardware and network configurations that follow
        industry best practices for cloud-hosted software. All customer data is
        backed up daily onto an off-site server.
      </>
    ),
  },
  {
    id: 'support',
    title: 'Support',
    description: (
      <>
        Email (<MailtoSupportLink />) and phone support are available on
        weekdays (not including federal holidays) from 9-5 ET, with typical
        response rates of well under an hour.
      </>
    ),
  },
  {
    id: 'installation',
    title: 'Installation Requirements',
    description: <>There is nothing for users to install or download.</>,
  },
  {
    id: 'uptime',
    title: 'Uptime & Guaranteed Service',
    description: (
      <>
        Blissbook is accessible 24 hours a day, 7 days a week with guaranteed
        99.9% uptime. The standard maintenance window is Sat. 12a - Sun. 12p ET
        and best efforts are made for any other maintenance to occur between 12a
        and 6a ET. Typical monthly downtime is measured in single-digit minutes
        or less. You can access a public report on uptime and downtime at{' '}
        <Link href='https://status.blissbook.com'>
          https://status.blissbook.com
        </Link>
        .
      </>
    ),
  },
  {
    id: 'customer-data',
    title: 'Customer Data',
    description: (
      <>
        You are never restricted from accessing your data or content (even if
        you cancel your subscription), including but not limited to electronic
        signature reports. Blissbook offers an HTML or PDF export of content and
        a CSV/PDF export of signed acknowledgement forms.
      </>
    ),
  },
  {
    id: 'custom-domains',
    title: 'Custom Domains',
    description: (
      <>
        Set up a secure custom domain name such as
        https://policies.yourcompany.com. Read more in our{' '}
        <Link href='https://help.blissbook.com/en/articles/1667334-using-a-custom-domain-on-a-handbook'>
          help center
        </Link>
        .
      </>
    ),
  },
  {
    id: 'custom-email-account',
    title: 'Custom Sender Email Account',
    description: (
      <>
        Improve the deliverability and trustworthiness of emails sent through
        Blissbook with a custom sender email account. Read more in our{' '}
        <Link href='https://help.blissbook.com/en/articles/1970898-blissbook-s-email-system'>
          help center
        </Link>
        .
      </>
    ),
  },
  {
    id: 'sso',
    title: 'Single Sign-On (SSO)',
    description: (
      <>
        Blissbook currently supports any SSO provider, including Okta, Azure
        (fka Office 365), G Suite (Google), OneLogin, and anything that can use
        SAML 2.0. Our support team is here to help with any setup required.
        <br />
        <br />
        Ready to set up SSO for your organization? Here's the{' '}
        <Link href='https://help.blissbook.com/en/articles/1389582-setting-up-single-sign-on'>
          help doc
        </Link>
        .
      </>
    ),
  },
  {
    id: 'hris',
    title: 'HRIS Integrations',
    description: (
      <>
        Blissbook can automatically sync your employees from 40+ different HRIS
        or AD platforms, including Workday, UKG, BambooHR, Rippling, Namely,
        Dayforce, Paylocity, and many more. You can also automate a sync via
        SFTP. For more details, check out the{' '}
        <Link href='https://help.blissbook.com/en/articles/9356926-integrate-your-hris-payroll-or-ad-platform-with-blissbook'>
          help article
        </Link>
        .
      </>
    ),
  },
  {
    id: 'custom-development',
    title: 'Custom Development',
    description: (
      <>
        If Blissbook's feature-set doesn't currently meet your needs, we'd be
        happy to explore customizing our platform for you. Please{' '}
        <ContactLink message='I am interested in Blissbook but I think we need something custom.'>
          contact us
        </ContactLink>{' '}
        to help us understand your project.
      </>
    ),
  },
]

export default () => (
  <Layout
    title={
      'Blissbook’s IT Infrastructure, Information Security and IT Policies'
    }
    description='Your data is safe &amp; secure with Blissbook. Learn about how we protect your HR policy content, employee data, electronic signature data, and more.'
  >
    <SolutionSection
      color={color}
      image='Mountain.svg'
      imageDesktopCss={css(`
        top: 160px;
        right: -230px;
        height: 340px;

        @media (min-width: ${gridBreakpoints.xl}px) {
          right: -120px;
        }
      `)}
      imageMobileCss={css(`
        background-position: 50% 0%;
        transform: scaleY(-1);
      `)}
      greeting='Hey Fellow Nerds!'
      title='Trust our rock solid IT infrastructure.'
      description='Your data is safe &amp; secure with Blissbook.'
    />

    <Section
      css={{
        background: `linear-gradient(180deg, white 0%, ${backgroundColor} 100%);`,
      }}
    >
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <div className='tw-flex'>
            <div className='tw-hidden lg:tw-block' css={{ flex: 1 }}>
              <Typography variant='p1' css={{ fontSize: 20 }}>
                {FEATURES.map((feature, index) => (
                  <Link
                    key={index}
                    children={feature.title}
                    className='tw-block tw-mb-2'
                    css={css`
                      color: ${colors.black};
                      &:hover {
                        color: ${getColor(color)};
                        text-decoration: none;
                      }
                    `}
                    to={`#${feature.id}`}
                  />
                ))}
              </Typography>

              <Link
                href='https://help.blissbook.com/en/articles/4740225-security-and-privacy-of-customer-data'
                className='btn btn-sm btn-block btn-primary tw-my-10'
              >
                <FontAwesomeIcon icon='file-alt' /> Security &amp; Privacy Help
                Article
              </Link>

              <Typography
                variant='quote'
                className='tw-text-gray-500'
                css={{ fontSize: 20 }}
              >
                “That was the easiest SSO integration I’ve done!! I have to
                applaud (and thank) Blissbook for such a smooth setup
                experience.” - IT Partner
              </Typography>

              <Typography
                variant='quote'
                className='tw-text-gray-500'
                css={{ fontSize: 20 }}
              >
                “I want to let you know, I’m very impressed with the
                implementation already – I absolutely love how easy and
                self-service you’ve made the SSO, SFTP, email, and domain
                configuration. This is all stuff normally locked behind a
                support rep with a lot of companies – the fact that I was able
                to configure SSO and SFTP within hours is wonderful – huge time
                saver!” - Operations Manager
              </Typography>
            </div>
            <DotsVertical
              className='tw-hidden lg:tw-block tw-ml-6 tw-mr-14'
              color='purple-300'
            />
            <div css={{ flex: 2 }}>
              {FEATURES.map((feature, index) => {
                const isLast = index === FEATURES.length - 1
                return (
                  <div key={index} css={{ position: 'relative' }}>
                    <Typography id={feature.id} variant='h2'>
                      {feature.title}
                    </Typography>

                    <Typography
                      variant='p2'
                      className={isLast ? 'tw-mb-0' : 'tw-mb-10'}
                    >
                      {feature.description}
                    </Typography>
                  </div>
                )
              })}
            </div>
          </div>
        </MaxWidthContainer>
      </Container>
    </Section>

    <ReadySection />
  </Layout>
)
