import { Link, MailtoSupportLink } from '@blissbook/ui/lib'
import React from 'react'
import {
  HeadingDivider,
  LegalHeader,
  LegalIndent,
  LegalLayout,
} from '../components'

export default () => (
  <LegalLayout
    title='Blissbook Privacy Policy'
    description={
      'Managing your policies online doesn’t mean you have to give up your or your employees’ privacy. Here’s how Blissbook uses your personal information.'
    }
  >
    <LegalHeader
      heading='Read Through Our'
      lastUpdated='May 24, 2024'
      title='Privacy Policy'
    />

    <h1>The Basics</h1>

    <HeadingDivider variant='h2'>Introduction</HeadingDivider>
    <p>
      Blissbook is an online policy management platform (the "Services")
      operated by Rocket Whale Products, LLC, a limited liability company
      headquartered in the State of Georgia ("we," "us," "our," and
      "Blissbook").
    </p>

    <p>
      The Services enable our Customers to, among other things, create a digital
      employee handbook, share it with employees and others (see “Contact”
      below), and collect documentation regarding the Contacts’ relationship
      with the handbook(s). Find out more about our Services{' '}
      <Link to='/employee-handbook-software'>here</Link>.
    </p>

    <p>
      At Blissbook, we are committed to protecting your privacy. Because your
      information may be shared with others in order to accomplish the purposes
      of the Services, our pledge is to safeguard your Personal Information
      (defined below) that we collect on the Website, which is associated with
      you and your name, in accordance with this privacy policy.
    </p>

    <p>
      By accessing the Website and/or using any of the Services, you agree to
      the terms contained in this privacy policy and any documents incorporated
      by reference. Please read this Privacy Policy carefully as it affects your
      rights and liabilities under law. If you disagree with the way we collect
      and process Personal Information (as defined below) collected on the
      Website, you may not use the Website or the Services.
    </p>

    <HeadingDivider variant='h2'>Key Terms</HeadingDivider>
    <p>In this privacy policy, these terms have the following meanings:</p>

    <p>
      <strong>"Contact"</strong> is a person a Customer may add to their
      organization through our Services. In other words, a Contact is anyone in
      a Customer’s organization (including the Customer themselves), anyone whom
      a Customer shares something with through our Services, or about whom a
      Customer has given us information. For example, if you are a Customer, an
      employee who has viewed and/or signed your handbook would be considered a
      Contact.
    </p>

    <p>
      <strong>"Customer"</strong> means any person or entity that is registered
      with us to administer the Services on behalf of their organization.
    </p>

    <p>
      <strong>"Employee List"</strong> is a list of Contacts a Member may upload
      or manage on our platform and all associated information related to those
      Contacts (for example, email addresses).
    </p>

    <p>
      <strong>"Personal Information"</strong> means any information that
      identifies or can be used to identify a Customer, a Contact, or a Visitor,
      directly or indirectly. Examples of Personal Information include, but are
      not limited to, name, email address, online identifiers, date of birth,
      phone number, location, or other demographic information.
    </p>

    <p>
      <strong>"Website(s)"</strong> means https://blissbook.com and any related
      sites or any web pages, interactive features, applications, widgets,
      blogs, social networks, social network "tabs," or other online, mobile, or
      wireless offerings that post a link to this privacy policy.
    </p>

    <p>
      <strong>"Visitor"</strong> means any person who visits any of our
      Websites.
    </p>

    <p>
      <strong>"you"</strong> and <strong>"your"</strong> means, depending on the
      context, either a Customer, a Contact, or a Visitor.
    </p>

    <p>
      <strong>"we,"</strong> <strong>"our"</strong> or <strong>"us"</strong>{' '}
      means Blissbook and its subsidiaries, affiliates, directors, officers,
      stockholders, employees, agents and contractors.
    </p>

    <HeadingDivider variant='h2'>Changes</HeadingDivider>
    <p>
      We may change this privacy policy at any time and from time to time. The
      most recent version of the privacy policy is reflected by the version date
      located at the top of this privacy policy. All updates and amendments are
      effective immediately upon notice, which we may give by any means,
      including, but not limited to, by posting a revised version of this
      privacy policy or other notice on the Websites.
    </p>

    <HeadingDivider variant='h2'>Scope of this Policy</HeadingDivider>
    <p>
      This privacy policy applies to the Website and the Services. The Website
      and the Services may contain links to other websites over which we have no
      control. Blissbook is not responsible for the privacy policies or
      practices of other websites which you access from the Website or through
      the Services. We encourage you to read the privacy policies or statements
      of the other websites you visit.
    </p>

    <h1>Privacy for Customers</h1>

    <p>
      This section applies to the Personal Information we collect and process
      from a Customer or potential Customer through the provision of the
      Services. If you are not a Customer, the Visitors or Contacts section of
      this policy may be more applicable to you and your data. In this section,{' '}
      <strong>"you"</strong> and <strong>"your"</strong> refer to Customers and
      potential Customers.
    </p>

    <HeadingDivider variant='h2'>A. Information We Collect</HeadingDivider>
    <p>
      The Personal Information that we may collect broadly falls into the
      following categories:
    </p>

    <p>
      <strong>Information you provide to us:</strong> When using our Services,
      you may provide Personal Information about you and your Contacts. Personal
      Information is often, but not exclusively, provided to us when you sign up
      for and use the Services, consult with our customer support team, send us
      an email, add employees to your organization within the Service, integrate
      the Services with another website or service (for example, set up a Single
      Sign-On integration), or communicate with us in any other way.
    </p>

    <p>
      By giving us this information, you agree to this information being
      collected, used and disclosed as described in our Terms &amp; Conditions
      and in this privacy policy.
    </p>

    <LegalIndent>
      <p>This information may include:</p>

      <p>
        <strong>Registration information:</strong> You need a Blissbook account
        to use the Services as a Customer. When you register for an account, you
        will be asked to provide certain basic information, such as your name,
        email address, and your organization's name.
      </p>

      <p>
        <strong>Billing and communications information:</strong> If you purchase
        our Services, you may also need to provide us with payment and billing
        information, such as your credit card details and billing address.
        Blissbook will also maintain a record of your billing transactions and
        purchases, and any communications and responses.
      </p>

      <p>
        <strong>Information we process on your behalf:</strong> To use certain
        features in your account (such as sharing a handbook with employees),
        you need to upload an Employee List that provides us information about
        your Contacts, such as their names and email addresses. We use and
        process this information to provide the Services in accordance with your
        instructions.
      </p>
    </LegalIndent>

    <p>
      <strong>Information we collect automatically</strong>: When you use the
      Services, we may automatically collect certain information about your
      device and usage of the Services. We use cookies and other tracking
      technologies to collect some of this information. Our use of cookies and
      other tracking technologies is discussed more in the Cookie section below.
    </p>

    <LegalIndent>
      <p>This information may include:</p>

      <p>
        <strong>Device information:</strong> We collect information about the
        device and applications you use to access the Services, such as your IP
        address, your operating system, your browser ID, and other information
        about your system and connection.
      </p>

      <p>
        <strong>Log data:</strong> Our web servers keep log files that record
        data each time a device accesses those servers and those log files
        contain data about the nature of each access, including originating IP
        addresses. We may also access metadata and other information associated
        with files that you upload into our Services.
      </p>

      <p>
        <strong>Product usage data:</strong> We collect usage data about you
        whenever you interact with our Services, which may include the dates and
        times you access the Services and your browsing activities (such as what
        portions of the Services are used or recordings of your browsing
        sessions). We also collect information regarding the performance of the
        Services, including metrics related to the deliverability of emails and
        other communications you send through the Services. This information
        allows us to improve the content and operation of the Services,
        facilitate research and analysis of the Services, and diagnose and fix
        errors in the Services.
      </p>
    </LegalIndent>

    <p>
      <strong>Information we collect from other sources:</strong> From time to
      time, we may obtain information about you or your Contacts from
      third-party sources, such as public databases, social media platforms,
      third-party data providers, or our joint marketing partners.
    </p>

    <LegalIndent>
      <p>
        Examples of the information we receive from other sources include public
        profiles, page view information, and search results and links. We use
        this information, alone or in combination with other information
        (including Personal Information) we collect, to enhance our ability to
        provide relevant marketing and content to you and to develop and provide
        you with more relevant products, features, and services.
      </p>
    </LegalIndent>

    <HeadingDivider variant='h2'>B. Use of Personal Information</HeadingDivider>
    <p>
      We may use the Personal Information we collect through the Services or
      other sources for a range of reasons, including:
    </p>
    <ul>
      <li>
        <strong>
          To bill and collect money owed to us by you.
          <br />
        </strong>
        This includes sending you emails, invoices, receipts, notices of
        delinquency, and alerting you if we need a different credit card number.
        We use third parties for secure credit card transaction processing, and
        those third parties collect billing information to process your orders
        and credit card payments. To learn more about the steps we take to
        safeguard that data, see the "Our Security" section of this privacy
        policy.
        <br />
        <br />
      </li>
      <li>
        <strong>
          To send you system alert messages.
          <br />
        </strong>
        For example, to inform you about temporary or permanent changes to our
        Services, such as planned outages, or account, security, or compliance
        notifications, such as new features, version updates, releases, abuse
        warnings, and changes to this privacy policy.
        <br />
        <br />
      </li>
      <li>
        <strong>
          To communicate with you about your account and provide customer
          support.
          <br />
        </strong>
        For example, to notify you of an issue within your account, or to keep
        you updated about an issue or new functionality that you previously
        expressed interest in.
        <br />
        <br />
      </li>
      <li>
        <strong>
          To enforce compliance with our Terms &amp; Conditions and applicable
          law, and to protect the rights and safety of us, our Customers, and
          third parties.
        </strong>
        <br />
        <br />
      </li>
      <li>
        <strong>To meet legal requirements</strong>, including complying with
        court orders, valid discovery requests, valid subpoenas, and other
        appropriate legal mechanisms.
        <br />
        <br />
      </li>
      <li>
        <strong>To provide information to representatives and advisors</strong>,
        including attorneys and accountants, to help us comply with legal,
        accounting, or security requirements.
        <br />
        <br />
      </li>
      <li>
        <strong>
          To prosecute and defend a court, arbitration, or similar legal
          proceeding.
        </strong>
        <br />
        <br />
      </li>
      <li>
        <strong>To respond to lawful requests by public authorities</strong>,
        including to meet national security or law enforcement requirements.
        <br />
        <br />
      </li>
      <li>
        <strong>To provide, support, and improve the Services</strong>. This may
        include using your or your Contacts’ information on non-production
        environments for testing or QA purposes, or sharing information with
        third parties in order to provide and support our Services or to make
        certain features of the Services available to you.
        <br />
        <br />
        <LegalIndent>
          When we share Personal Information with third parties, we evaluate
          these third parties' privacy policies and overall security posture to
          ensure they use the Personal Information we transfer to them in a
          manner that is consistent with this privacy policy and applicable
          privacy laws.
        </LegalIndent>
        <br />
      </li>
      <li>
        <strong>Combined information.</strong> We may combine Personal
        Information with other information we collect or obtain about you (such
        as information we source from our third-party partners) to serve you
        specifically, such as to deliver a product or service according to your
        preferences or restrictions, or for advertising or targeting purposes in
        accordance with this privacy policy. When we combine Personal
        Information with other information in this way, we treat it as, and
        apply all of the safeguards in this privacy policy applicable to,
        Personal Information.
        <br />
        <br />
      </li>
      <li>
        <strong>Other purposes.</strong> To carry out other legitimate business
        purposes, as well as other lawful purposes about which we will notify
        you.
      </li>
    </ul>

    <HeadingDivider variant='h2'>C. Customer Employee Lists</HeadingDivider>
    <p>
      An Employee List can be created in a number of ways, including by adding
      Contacts individually, through a sync with your HRIS or AD platform, or by
      importing or transferring a CSV file. Your Employee Lists are stored
      securely in Blissbook. We do not, under any circumstances, sell your
      Employee Lists. If someone on your Employee List contacts us directly
      (usually for support), we may contact that person. You may export
      (download) your Employee Lists from Blissbook at any time.
    </p>

    <p>
      If a Contact forwards an email a Customer sends to that Contact (usually
      an invitation to view and/or sign an employee handbook), it will allow the
      Contact to share the Customer’s email content with individuals not on the
      Customer’s Employee List. When a Contact forwards an email, we do not
      store the forwarding email address.
    </p>

    <h1>Privacy for Contacts</h1>

    <p>
      This section applies to the information we process about our Customers’
      Contacts as a data controller pursuant to our legitimate business
      interests, as explained in the "Legal Basis for Processing" section below.
      Our Services are intended for use by our Customers. As a result, for much
      of the Personal Information we collect and process about Contacts through
      the Services, we act as a processor on behalf of our Customers. Blissbook
      is not responsible for the privacy or security practices of our Customers,
      which may differ from those set forth in this privacy policy. Please check
      with individual Customers about the policies they have in place. For
      purposes of this section, "you" and "your" refer to Contacts.
    </p>

    <HeadingDivider variant='h2'>A. Information We Collect</HeadingDivider>

    <p>
      The Personal Information that we may collect or receive about you broadly
      falls into the following categories:
    </p>

    <p>
      <strong>Information we receive about Contacts from our Customers:</strong>{' '}
      A Customer may provide Personal Information about you to us through the
      Services. For example, when a Customer uploads their Employee List,
      integrates the Services with another website or service (e.g. a Customer
      chooses to integrate Blissbook with their HRIS software), or shares a
      handbook with you, they may provide us with certain contact information or
      other Personal Information about you such as your name, email address,
      telephone number, or work location.
    </p>

    <p>
      <strong>Information we collect automatically:</strong> When you interact
      with the Services (usually by accessing, viewing, and/or signing a
      Customer’s employee handbook), we may collect information about you. We
      use cookies and other tracking technologies to collect some of this
      information. Our use of cookies and other tracking technologies is
      discussed more in the Cookie section below.
    </p>

    <LegalIndent>
      <p>
        <strong>Device information:</strong> We collect information about the
        device and applications you use to access our Services, such as your IP
        address, your operating system, your browser ID, and other information
        about your system and connection.
      </p>

      <p>
        <strong>Product usage data:</strong> We collect usage data about you
        whenever you interact with our Services, which may include dates and
        times you access the Services and your browsing activities (such as what
        sections are viewed). We also collect information regarding the
        performance of the Services, including metrics related to the
        deliverability of emails and other electronic communications our
        Customers send through the Services. This information allows us to
        improve the content and operation of the Services, provide reporting
        information to Customers, and facilitate research and analysis of the
        Services.
      </p>
    </LegalIndent>

    <p>
      <strong>Information we collect from other sources:</strong> We may obtain
      information about you from third-party sources, such as public databases,
      and third-party data providers.
    </p>

    <HeadingDivider variant='h2'>B. Use of Personal Information</HeadingDivider>

    <p>
      We may use the Personal Information we collect or receive about you for
      our legitimate business interests, including:
    </p>
    <ul>
      <li>
        <strong>To provide, support, and improve the Services.</strong> This may
        include using your information on non-production environments for
        testing or QA purposes, or sharing information with third parties in
        order to provide and support our Services or to make certain features of
        the Services available to you. We will not use a Contact's Personal
        Information for marketing unless you have separately opted into receive
        marketing communications from us.
        <br />
        <br />
        <LegalIndent>
          When we share Personal Information with third parties, we evaluate
          these third parties' privacy policies and overall security posture to
          ensure they use the Personal Information we transfer to them in a
          manner that is consistent with this privacy policy and applicable
          privacy laws.
        </LegalIndent>
        <br />
      </li>
      <li>
        <strong>
          To enforce compliance with our Terms &amp; Conditions and applicable
          law.
        </strong>
        <br />
        <br />
      </li>
      <li>
        <strong>To meet legal requirements</strong>, including complying with
        court orders, valid discovery requests, valid subpoenas, and other
        appropriate legal mechanisms.
        <br />
        <br />
      </li>
      <li>
        <strong>To provide information to representatives and advisors</strong>,
        including attorneys and accountants, to help us comply with legal,
        accounting, or security requirements.
        <br />
        <br />
      </li>
      <li>
        <strong>
          To prosecute and defend a court, arbitration, or similar legal
          proceeding.
        </strong>
        <br />
        <br />
      </li>
      <li>
        <strong>To respond to lawful requests by public authorities</strong>,
        including to meet national security or law enforcement requirements.
        <br />
        <br />
      </li>
      <li>
        <strong>Other purposes</strong>. To carry out other legitimate business
        purposes, as well as other lawful purposes.
      </li>
    </ul>

    <HeadingDivider variant='h2'>C. Legal Basis for Processing</HeadingDivider>

    <p>
      We process Personal Information about you as a data controller as
      described in this section, where such processing is in our legitimate
      interests and not overridden by your data protection interests or
      fundamental rights and freedoms. Our legitimate interests typically
      include: improving, maintaining, providing, and enhancing our technology,
      products and services; ensuring the security of the Services and our
      Website.
    </p>

    <h1>Privacy for Visitors</h1>

    <p>
      This section applies to Personal Information that we collect and process
      through our Websites and in the usual course of our business, such as in
      connection with our recruitment, events, sales and marketing activities.
      In this section "you" and "your" refer to Visitors.
    </p>

    <HeadingDivider variant='h2'>A. Information We Collect</HeadingDivider>

    <p>
      <strong>
        Information you provide to us on the Websites or otherwise:
      </strong>{' '}
      Certain parts of our Websites may ask you to provide Personal Information
      voluntarily. For example, we may ask you to provide certain Personal
      Information (such as your name, contact details, organization name,
      profile information) in order to sign up for a Blissbook account, sign up
      to receive information, products, or services from Blissbook, or otherwise
      submit inquiries to us. We may also collect Personal Information, such as
      your contact and job details and feedback, when you attend our events,
      take part in surveys, or through other business or marketing interactions
      we may have with you. You may choose to provide additional information
      when you communicate with us or otherwise interact with us, and we will
      keep copies of any such communications for our records.
    </p>

    <p>
      The Personal Information that you are asked to provide, and the reasons
      why you are asked to provide it, will be made clear to you at the point we
      ask you to provide your Personal Information. We will also do our best to
      inform you prior to collection whether the provision of the Personal
      Information we are collecting is compulsory or may be provided on a
      voluntary basis and the consequences, if any, of not providing the
      information.
    </p>

    <p>
      <strong>
        Information we collect automatically through the Websites:
      </strong>{' '}
      When you visit our Websites, we may also collect certain information
      automatically from your device. In some countries, including countries in
      the European Economic Area, this information may be considered Personal
      Information under applicable data protection laws.
    </p>

    <LegalIndent>
      <p>
        Specifically, the information we collect automatically may include your
        IP address, your operating system, your browser ID, your browsing
        activity, and other information about your system and connection and how
        you interact with our Websites and other websites. We may collect this
        information as a part of log files as well as through the use of cookies
        or other tracking technologies. Our use of cookies and other tracking
        technologies is discussed more in the Cookie section below.
      </p>
    </LegalIndent>

    <HeadingDivider variant='h2'>B. Use of Personal Information</HeadingDivider>

    <p>
      We may use the information we collect through our Websites for a range of
      reasons, including:
    </p>
    <ul>
      <li>To provide, operate, optimize, and maintain our Websites.</li>
      <li>
        To send you information for marketing purposes, in accordance with your
        marketing preferences.
      </li>
      <li>
        For recruitment purposes if you have applied for a role with Blissbook.
      </li>
      <li>
        To respond to your online inquiries and requests, and to provide you
        with information and access to resources or services that you have
        requested from us.
      </li>
      <li>To manage our Websites and system administration and security.</li>
      <li>To improve the navigation and content of our Websites.</li>
      <li>To identify any server problems or other IT or network issues.</li>
      <li>To process transactions and to set up online accounts.</li>
      <li>
        To compile aggregated statistics about site usage and to better
        understand the preferences of our Visitors.
      </li>
      <li>
        To carry out research and development to improve our products and
        services.
      </li>
      <li>To customize content and layout of the Websites.</li>
      <li>
        To carry out other legitimate business purposes, as well as other lawful
        purposes.
      </li>
    </ul>

    <p>
      In addition, we may combine Personal Information with other information we
      collect or obtain about you (such as information we source from our
      third-party partners) to serve you specifically, such as to deliver a
      product or service according to your preferences or restrictions, or for
      advertising or targeting purposes in accordance with this privacy policy.
      When we combine Personal Information with other information in this way,
      we treat it as, and apply all of the safeguards in this privacy policy
      applicable to, Personal Information.
    </p>

    <HeadingDivider variant='h2'>
      C. Public Information and Third-Party Websites
    </HeadingDivider>
    <ul>
      <li>
        <strong>Blog</strong>. We have a public blog on our Website. Any
        information you include in a comment on our blog may be read, collected,
        and used by anyone. If your Personal Information appears on our blogs
        and you want it removed, contact us at <MailtoSupportLink />
        .<br />
        <br />
      </li>
      <li>
        <strong>Links to third-party websites.</strong> Our Websites include
        links to other websites, whose privacy practices may be different from
        ours. If you submit Personal Information to any of those sites, your
        information is governed by their privacy policies. We encourage you to
        carefully read the privacy policy of any website you visit.
        <br />
        <br />
      </li>
      <li>
        Contests and sweepstakes. We may, from time to time, offer surveys,
        contests, sweepstakes, or other promotions on our Websites or through
        social media (collectively, "Promotions").
        <br />
        <br />
        <LegalIndent>
          Participation in our Promotions is completely voluntary. Information
          requested for entry may include Personal Information such as your
          name, address, date of birth, phone number, email address, username,
          and similar details. We use the information you provide to administer
          our Promotions. We may also, unless prohibited by the Promotion’s
          rules or law, use the information provided to communicate with you, or
          other people you select, about our Services. We may share this
          information with our affiliates and other organizations or service
          providers in line with this privacy policy and the rules posted for
          our Promotions.
        </LegalIndent>
      </li>
    </ul>

    <h1>General Information</h1>

    <HeadingDivider variant='h2'>A. How We Share Information</HeadingDivider>

    <p>
      We may share and disclose your Personal Information to the following types
      of third parties for the purposes described in this privacy policy (for
      purposes of this section, "you" and "your" refer to Customers, Contacts,
      and Visitors unless otherwise indicated):
    </p>

    <p>
      <strong>Our service providers:</strong> Sometimes, we share your personal
      information with our third-party service providers, who help us provide
      and support our Services and other business-related functions.
    </p>

    <LegalIndent>
      <p>
        These third-party service providers enter into a contract that requires
        them to use your Personal Information only for the provision of services
        to us and in a manner that is consistent with this privacy policy. We do
        not give your Personal Information to third parties in exchange for
        money or other value (“selling” or “sharing”). However, we allow trusted
        partners to collect some of your data so they can provide you with
        services. We outline those third party service providers in Appendix 1
        of our{' '}
        <Link
          to='/data-processing-addendum'
          title='Blissbook Data Processing Addendum'
        >
          Data Processing Addendum
        </Link>
        , which is incorporated by this reference.
      </p>
    </LegalIndent>

    <p>
      <strong>Advertising partners:</strong> We may partner with third-party
      advertising networks and exchanges to manage and serve our advertising on
      other sites and may share Personal Information with them for this purpose.
    </p>

    <LegalIndent>
      <p>
        We and our third-party partners may use cookies and other tracking
        technologies, such as pixels and web beacons, to gather information
        about your activities on our Websites and other sites in order to
        provide you with targeted advertising based on your browsing activities
        and interests. Our use of cookies and other tracking technologies is
        discussed more in the Cookie section below.
      </p>
    </LegalIndent>

    <p>
      <strong>
        Any competent law enforcement body, regulatory body, government agency,
        court or other third party
      </strong>{' '}
      where we believe disclosure is necessary (a) as a matter of applicable law
      or regulation, (b) to exercise, establish, or defend our legal rights, (c)
      to protect our rights or property or the rights or property of our
      affiliated companies, (d) prevent a crime or protect national security,
      (e) protect the personal safety of Service users or the public, or (f) to
      protect your vital interests or those of any other person.
    </p>

    <p>
      <strong>A potential buyer (and its agents and advisors)</strong> in the
      case of a sale, merger, consolidation, liquidation, reorganization, or
      acquisition. In addition, in the event that we become the subject of an
      insolvency proceeding, whether voluntary or involuntary, we or our
      liquidator, administrator, receiver or administrative receiver may sell,
      license or otherwise dispose of such information in a transaction approved
      by the bankruptcy court. Potential purchasers and their advisors may have
      access to data as part of the sale process.
    </p>

    <LegalIndent>
      <p>
        In that event, any acquirer will be subject to our obligations under
        this privacy policy, including your rights to access and choice. We will
        notify you of the change either by sending you an email or posting a
        notice on our Website.
      </p>
    </LegalIndent>

    <p>
      <strong>Any other person with your consent.</strong>
    </p>

    <HeadingDivider variant='h2'>
      B. Cookies and Other Tracking Technologies
    </HeadingDivider>

    <h3>What Are Cookies?</h3>
    <p>
      Cookies are small data files that are placed on your computer or mobile
      device when you visit a website. Cookies are widely used by online service
      providers in order to (for example) make their websites or services work,
      or to work more efficiently, as well as to provide reporting information.
    </p>

    <p>
      Cookies set by the website owner or service provider (in this case,
      Blissbook) are called “first party cookies”. Cookies set by parties other
      than the website owner are called “third party cookies”. Third party
      cookies enable third party features or functionality to be provided on or
      through the website or service you are using (such as advertising,
      interactive content and analytics). The third parties that set these third
      party cookies can recognize your computer both when it visits the website
      or service in question and also when it visits certain other websites or
      services.
    </p>

    <h3>Other Tracking Technologies</h3>
    <p>
      We and our third party partners may use other, similar technologies from
      time to time, like web beacons, pixels (or “clear gifs”) and other
      tracking technologies. These are tiny graphics files that contain a unique
      identifier that enable us to recognize when someone has visited our
      Websites or, in the case of web beacons, opened an email that we have sent
      them (or our Customer has sent a Contact). This allows us, for example, to
      monitor the traffic patterns of users from one page within our Websites to
      another, to deliver or communicate with cookies, to understand whether you
      have come to our Websites from an online advertisement displayed on a
      third-party website, to serve targeted advertisements to you and others
      like you, to improve site performance, and to measure the success of
      emails sent by Customers. While you may not have the ability to
      specifically reject or disable these tracking technologies, in many
      instances, these technologies are reliant on cookies to function properly;
      accordingly, in those instances, declining cookies will impair functioning
      of these technologies.
    </p>

    <p>
      We may also store information in log files, such as IP addresses, browser
      types, the date, time and duration of access and usage of the Website and
      whether emails were opened.
    </p>

    <h3>Why Do We Use Cookies and Other Tracking Technologies?</h3>
    <p>
      We use first party and third party cookies for several reasons. Some
      cookies are required for technical reasons in order for our Websites and
      Services to operate, and we refer to these as “essential” or “strictly
      necessary” cookies. Other cookies enable us and the third parties we work
      with to track and target the interests of visitors to our Websites, and we
      refer to these as “performance” or “functionality” cookies. For example,
      we use cookies to tailor content and information that we may send or
      display to you and otherwise personalize your experience while interacting
      with our Websites and to otherwise improve the functionality of the
      Services we provide. Finally, third parties serve cookies through our
      Websites and Services for advertising, analytics, and other purposes.
    </p>

    <h3>How Can I Control Cookies?</h3>
    <p>
      <strong>Through your browser:</strong> If you are uncomfortable with the
      collection of such information through the use of cookies and similar
      technologies, we recommend disabling these features through your browser
      preferences. You can configure your browser to refuse all cookies or to
      display a message when a cookie is being sent. Your browser documentation
      should provide specific procedures for disabling cookie and web beacon
      support. Please be aware that if you configure your browser in this way,
      it will limit the performance of the Website and/or Service and some of
      the Website's and/or Service's features and functionality may not be
      available to you.
    </p>

    <p>
      <strong>Disabling Most Interest Based Advertising:</strong> Most
      advertising networks offer you a way to opt out of Interest Based
      Advertising. If you would like to find out more information, please visit{' '}
      <a
        href='http://www.aboutads.info/choices'
        rel='noopener noreferrer'
        target='_blank'
      >
        http://www.aboutads.info/choices
      </a>{' '}
      or{' '}
      <a
        href='http://www.youronlinechoices.com'
        rel='noopener noreferrer'
        target='_blank'
      >
        http://www.youronlinechoices.com
      </a>
      .
    </p>

    <p>
      <strong>Do Not Track:</strong> Some Internet browsers include the ability
      to transmit “Do Not Track” or “DNT” signals. Since uniform standards for
      “DNT” signals have not been adopted, our Websites do not currently process
      or respond to “DNT” signals. Blissbook takes privacy and meaningful choice
      seriously and will make efforts to continue to monitor developments around
      DNT browser technology and the implementation of a standard.
    </p>

    <HeadingDivider variant='h2'>
      C. Legal Basis for Processing Personal Information (EEA Persons Only)
    </HeadingDivider>

    <p>
      If you are from the European Economic Area, our legal basis for collecting
      and using the Personal Information described above will depend on the
      Personal Information concerned and the specific context in which we
      collect it.
    </p>

    <p>
      However, we will normally collect and use Personal Information from you
      where the processing is in our legitimate interests and not overridden by
      your data-protection interests or fundamental rights and freedoms. Our{' '}
      <strong>legitimate interests</strong> are (i) performing our contracts
      with Customers; (ii) improving, maintaining, providing, and enhancing our
      technology, products, and services; (iii) ensuring the security of the
      Services and our Websites; and (iv) engaging in marketing activities.
    </p>

    <p>
      If you are a Customer, we may need your or your Contacts’ Personal
      Information to <strong>perform a contract</strong> with you. In some
      limited cases, we may also have a <strong>legal obligation</strong> to
      collect Personal Information from you.
    </p>

    <p>
      If we ask you to provide Personal Information to comply with a legal
      requirement or to perform a contact with you, we will make this clear at
      the relevant time and advise you whether the provision of your or your
      Contacts’ Personal Information is mandatory or not, as well as of the
      possible consequences if you do not provide your or your Contacts’
      Personal Information.
    </p>

    <p>
      Where required by law, we will collect Personal Information only where we
      have your <strong>consent</strong> to do so.
    </p>

    <p>
      If you have questions about or need further information concerning the
      legal basis on which we collect and use your Personal Information, please
      contact us at <MailtoSupportLink />.
    </p>

    <HeadingDivider variant='h2'>D. Your Choices and Opt-Outs</HeadingDivider>
    <p>
      Customers, Contacts, and Visitors who have opted in to our marketing
      emails can opt out of receiving marketing emails from us at any time by
      clicking the "unsubscribe" link at the bottom of our marketing messages.
      Opt-out requests can also be made by emailing <MailtoSupportLink />.
    </p>

    <p>
      A Contact who seeks access, or who seeks to correct, amend, or delete data
      should first directly query the relevant Blissbook Customer and, should
      that fail, then contact Blissbook at <MailtoSupportLink />. We will
      consider your request in accordance with applicable laws and will respond
      within the applicable legal timeframe. We may notify the relevant Customer
      in any response.
    </p>

    <p>
      Please note that some communications (such as service messages, account
      notifications, billing information) are considered transactional and
      necessary for account management, and Customers cannot opt out of these
      messages unless you cancel your Blissbook subscription.
    </p>

    <p />

    <HeadingDivider variant='h2'>
      E. Other Data Protection Rights
    </HeadingDivider>
    <p>
      Depending on where you reside, you may have the following data protection
      rights:
    </p>
    <ul>
      <li>
        To <strong>access, correct, update or request deletion</strong> of
        Personal Information. Blissbook takes reasonable steps to ensure that
        the data we collect is reliable for its intended use, accurate, complete
        and up to date. As a Customer, you can manage many of your individual
        account and profile settings within the dashboard provided through the
        Services, or you may contact us directly by emailing us at{' '}
        <MailtoSupportLink />. You can also manage information about your
        Contacts within the dashboard provided through the Services to assist
        you with responding to requests to access, correct, update or delete
        information that you receive from your Contacts. If you are a Visitor or
        a Contact and you'd like to access, correct, update or request deletion
        of Personal Information,{' '}
        <Link to='/gdpr' title='Exercise data privacy rights under GDPR'>
          click here
        </Link>
        .
        <br />
        <br />
      </li>
      <li>
        In addition, individuals who are residents of the EEA can{' '}
        <strong>object to processing</strong> of their Personal Information, ask
        to <strong>restrict processing</strong> of their Personal Information or{' '}
        <strong>request portability</strong> of their Personal Information. You
        can exercise these rights by contacting us via the form on{' '}
        <Link to='/gdpr' title='Exercise data privacy rights under GDPR'>
          this page
        </Link>
        . If any of your Contacts wishes to exercise any of these rights, they
        should contact you directly or, if you cannot help them, contact us at{' '}
        <MailtoSupportLink />.<br />
        <br />
      </li>
      <li>
        Similarly, if Personal Information is collected or processed on the
        basis of consent, the data subject can withdraw their consent at any
        time. <strong>Withdrawing your consent</strong> will not affect the
        lawfulness of any processing we conducted prior to your withdrawal, nor
        will it affect processing of your Personal Information conducted in
        reliance on lawful processing grounds other than consent. If you receive
        these requests from Contacts and you need assistance fulfilling those
        requests, please contact us at <MailtoSupportLink />.
        <br />
        <br />
      </li>
      <li>
        The <strong>right to complain to a data protection authority</strong>{' '}
        about the collection and use of Personal Information. For more
        information, please contact your local data protection authority.
        Contact details for data protection authorities in the EEA are available{' '}
        <a
          href='http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm'
          rel='noopener noreferrer'
          target='_blank'
        >
          here
        </a>
        .
      </li>
    </ul>

    <p>
      We respond to all requests we receive from individuals wishing to exercise
      their data protection rights in accordance with applicable data protection
      law. We may ask you to verify your identity in order to help us respond
      efficiently to your request. If we receive a request from one of your
      Contacts, we will either direct the Contact to reach out to you, or, if
      appropriate, we may respond directly to their request and notify you if
      necessary.
    </p>

    <p>
      If you are a Contact and want to exercise any data protection rights that
      may be available to you under applicable law or have questions or concerns
      about how your Personal Information is handled by Blissbook as a processor
      on behalf of our individual Customers, you should contact the relevant
      Customer that is using the Blissbook Services, and refer to their separate
      privacy policies.
    </p>

    <HeadingDivider variant='h2'>F. Security</HeadingDivider>
    <p>
      We take appropriate and reasonable technical and organizational measures
      to protect Personal Information from loss, misuse, unauthorized access,
      disclosure, alteration, and destruction, taking into account the risks
      involved in the processing and the nature of the Personal Information. We
      also use automated and other measures to enhance security and may suspend
      or disable accounts for violations of our{' '}
      <Link to='/terms' title='Blissbook Terms and Conditions'>
        Terms &amp; Conditions
      </Link>{' '}
      (which is incorporated by reference herein) and this privacy policy.
      Please be aware that no security measures are perfect or impenetrable, and
      no method of transmission over the Internet, or method of electronic
      storage, is 100% secure. We are not responsible for third party
      circumvention of any privacy or security measures of Blissbook. You can
      reduce these risks by using common sense security practices such as
      choosing a strong password, using different passwords for different
      services and using up to date antivirus software.
    </p>

    <p>
      If you have any questions about the security of your Personal Information,
      review our{' '}
      <Link to='/information-security' title='Blissbook IT Information'>
        Information Security page
      </Link>
      . To report a concern or violation of security, contact us at{' '}
      <MailtoSupportLink />.
    </p>

    <HeadingDivider variant='h2'>G. International Transfers</HeadingDivider>

    <p>
      <strong>We operate in the United States</strong>
      <br />
      Our servers and offices are located in the United States, so your Personal
      Information may be transferred to, stored, or processed in the United
      States. While the data protection, privacy, and other laws of the United
      States may differ from those in your country, we take steps to secure and
      protect your Personal Information. One such step is by including a{' '}
      <Link
        to='/data-processing-addendum'
        title='Blissbook Data Processing Addendum'
      >
        Data Processing Addendum
      </Link>{' '}
      for Customers in our{' '}
      <Link to='/terms' title='Blissbook Terms and Conditions'>
        Terms &amp; Conditions
      </Link>
      .
    </p>

    <p>
      Because Blissbook is a United States company and information collected on
      our Website is stored in whole or in part in the United States, your
      Personal Information may be subject to U.S. law.
    </p>

    <p>
      <strong>
        Data transfers from Switzerland, the UK, or the EEA to the United States
      </strong>
      <br />
      Blissbook complies with the EU-U.S. Data Privacy Framework (“EU-U.S.
      DPF”), the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data
      Privacy Framework (“Swiss-U.S. DPF”) as set forth by the U.S. Department
      of Commerce. Blissbook has certified to the U.S. Department of Commerce
      that it adheres to the EU-U.S. Data Privacy Framework Principles (“EU-U.S.
      DPF Principles”) with regard to the processing of personal data received
      from the European Union in reliance on the EU-U.S. DPF and from the United
      Kingdom (and Gibraltar) in reliance on the UK Extension to the EU-U.S.
      DPF. Blissbook has certified to the U.S. Department of Commerce that it
      adheres to the Swiss-U.S. Data Privacy Framework Principles (“Swiss-U.S.
      DPF Principles”) with regard to the processing of personal data received
      from Switzerland in reliance on the Swiss-U.S. DPF. If there is any
      conflict between the terms in this privacy policy and the EU-U.S. DPF
      Principles and/or the Swiss-U.S. DPF Principles, the Principles shall
      govern. To learn more about the Data Privacy Framework (DPF) program, and
      to view our certification, please visit the{' '}
      <a
        href='https://www.dataprivacyframework.gov/'
        rel='noopener noreferrer'
        target='_blank'
      >
        Data privacy framework website
      </a>
      .
    </p>

    <p>
      Blissbook is responsible for the processing of Personal Information (also
      called personal data herein) it receives or subsequently transfers to a
      third party acting as an agent on its behalf. Blissbook has agreed to the
      onward transfer liability provisions in the Principles with respect to
      onward transfers to third parties.
    </p>

    <p>
      With respect to personal data received or transferred pursuant to the
      Principles, Blissbook is subject to the regulatory enforcement powers of
      the U.S. Federal Trade Commission and any other authorized U.S. statutory
      body. In certain situations, Blissbook may be required to disclose
      personal data in response to lawful requests by public authorities,
      including to meet national security or law enforcement requirements.
    </p>

    <p>
      In compliance with the Principles, Blissbook commits to resolve complaints
      about your privacy and our collection or use of your personal data
      transferred to the United States pursuant to the Principles. EEA, Swiss,
      and United Kingdom individuals with DPF inquiries or complaints should
      first contact the Regulator Contact above.
    </p>

    <p>
      Blissbook has also committed to cooperate with the EU authority or
      authorities concerned in conformity with the EU-U.S. DPF Supplemental
      Principles on Human Resources Data and the Role of the Data Protection
      Authorities. Blissbook will cooperate with, and comply with the advice
      given by, competent EU, UK, or Swiss authorities in such cases: in the EU,
      the relevant data protection authorities; in the UK, the UK Information
      Commissioner's Office; and in Switzerland, the Swiss Federal Data
      Protection and Information Commissioner.
    </p>

    <p>
      Blissbook has further committed to refer unresolved privacy complaints
      under the Principles to an independent dispute resolution mechanism, Data
      Privacy Framework Services, operated by JAMS, an alternative dispute
      resolution provider based in the United States. If you do not receive
      timely acknowledgment of your complaint, or if your complaint is not
      satisfactorily addressed, please visit{' '}
      <a
        href='https://www.jamsadr.com/DPF-Dispute-Resolution'
        rel='noopener noreferrer'
        target='_blank'
      >
        https://www.jamsadr.com/DPF-Dispute-Resolution
      </a>{' '}
      for more information and/or to file a complaint. This service is provided
      free of charge to you.
    </p>

    <p>
      If your DPF complaint cannot be resolved through the above independent
      recourse mechanism, under certain conditions, you may invoke binding
      arbitration for some residual claims not resolved by other redress
      mechanisms. See{' '}
      <a
        href='https://www.dataprivacyframework.gov/s/article/G-Arbitration-Procedures-dpf?tabset-35584=2'
        rel='noopener noreferrer'
        target='_blank'
      >
        https://www.dataprivacyframework.gov/s/article/G-Arbitration-Procedures-dpf?tabset-35584=2
      </a>
    </p>

    <p>
      You have the right to access, limit the use, and limit the disclosure of
      your personal data as set forth in the “Other Data Protection Rights”
      sections above. If you have additional questions about our Data Privacy
      Framework certification, contact us at <MailtoSupportLink />.
    </p>

    <p>
      <strong>Customers located in Australia</strong>
      <br />
      If you are a Customer who lives in Australia, this section applies to you.
      We are subject to the operation of the Privacy Act 1988 ("Australian
      Privacy Act"). Here are the specific points you should be aware of:
    </p>
    <ul>
      <li>
        Where we say we assume an obligation about Personal Information, we are
        also requiring our subcontractors to undertake a similar obligation,
        where relevant.
        <br />
        <br />
      </li>
      <li>
        We will not use or disclose Personal Information for the purpose of our
        direct marketing to you unless you have consented to receive direct
        marketing; you would reasonably expect us to use your personal details
        for the marketing; or we believe you may be interested in the material
        but it is impractical for us to obtain your consent. You may opt out of
        any marketing materials we send to you through an unsubscribe mechanism
        or by contacting us directly. If you have requested not to receive
        further direct marketing messages, we may continue to provide you with
        messages that are not regarded as "direct marketing" under the
        Australian Privacy Act, including changes to our terms, system alerts,
        and other information related to your account.
        <br />
        <br />
      </li>
      <li>
        Our servers are located in the United States. In addition, we or our
        subcontractors may use cloud technology to store or process Personal
        Information, which may result in storage of data outside Australia. It
        is not practicable for us to specify in advance which country will have
        jurisdiction over this type of offshore activity. All of our
        subcontractors, however, are required to comply with the Australian
        Privacy Act in relation to the transfer or storage of Personal
        Information overseas.
        <br />
        <br />
      </li>
      <li>
        If you think the information we hold about you is inaccurate, out of
        date, incomplete, irrelevant, or misleading, we will take reasonable
        steps, consistent with our obligations under the Australian Privacy Act,
        to correct that information upon your request.
        <br />
        <br />
      </li>
      <li>
        If you are unsatisfied with our response to a privacy matter then you
        may consult either an independent advisor or contact the Office of the
        Australian Information Commissioner for additional help. We will provide
        our full cooperation if you pursue this course of action.
      </li>
    </ul>

    <HeadingDivider variant='h2'>H. Retention of Data</HeadingDivider>
    <p>
      We retain Personal Information where we have an ongoing legitimate
      business or legal need to do so. Our retention periods will vary depending
      on the type of data involved, but, generally, we'll refer to these
      criteria in order to determine retention period:
    </p>
    <ul>
      <li>
        Whether we have a legal or contractual need to retain the data.
        <br />
        <br />
      </li>
      <li>
        Whether the data is necessary to provide our Services.
        <br />
        <br />
      </li>
      <li>
        Whether our Customers can access and delete the data within their
        accounts.
        <br />
        <br />
      </li>
      <li>
        Whether our Customers would reasonably expect that we would retain the
        data until they remove it or until their Blissbook accounts are closed
        or terminated.
      </li>
    </ul>

    <p>
      When we have no ongoing legitimate business need to process your Personal
      Information, we will either delete or anonymize it or, if this is not
      possible (for example, because your Personal Information has been stored
      in backup archives), then we will securely store your Personal Information
      and isolate it from any further processing until deletion is possible.
    </p>

    <HeadingDivider variant='h2'>I. California Privacy</HeadingDivider>
    <p>
      Under California's 'Shine the Light' law, California residents have the
      right to request in writing from businesses with whom they have an
      established business relationship, (a) a list of the categories of
      Personal Information, such as name, email, and mailing address, and the
      type of services provided to the customer that a business has disclosed to
      third parties (including affiliates that are separate legal entities)
      during the immediately preceding calendar year for the third-parties’
      direct marketing purposes, and (b) the names and addresses of all such
      third parties. To request the above information, please contact us at{' '}
      <MailtoSupportLink />.
    </p>

    <HeadingDivider variant='h2'>J. Children</HeadingDivider>
    <p>
      The Website and the Services are not directed to individuals under 18
      years old, and we do not knowingly collect Personal Information from
      persons under 18 years old without parent or guardian consent. If we learn
      that we have collected Personal Information from a person who is under 18
      years old without proper consent, we will take reasonable steps to delete
      such information from our files as soon as possible. If you believe that
      we might have any Personal Information from a child under age 18 without
      proper consent, please contact us by email at <MailtoSupportLink />.
    </p>

    <HeadingDivider variant='h2'>K. Contact Us</HeadingDivider>
    <p>
      If you have concerns about your privacy in connection with your use of the
      Services or any general questions related thereto, please tell us by
      emailing us at <MailtoSupportLink />.
    </p>

    <p>
      For the purposes of EU data protection legislation, Rocket Whale Products,
      LLC is the controller of your Personal Information. Our Data Protection
      Officer can be contacted at <MailtoSupportLink />. Our physical address is
      1442 Miller Avenue, Atlanta, GA 30307.
    </p>
    <br />
    <br />
  </LegalLayout>
)
