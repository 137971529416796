import { gridBreakpoints } from '@blissbook/ui/branding'
import { useEffect, useState } from 'react'

// Bound this value
const bound = (value, minValue, maxValue) => {
  value = Math.max(value, minValue)
  value = Math.min(value, maxValue)
  return value
}

// Round to this precision
const round = (value, precision = 0.001) =>
  Math.round(value / precision) * precision

// Determine the position of this node on the screen
const getNodePosition = (node, attachment, styleKeys = []) => {
  // Cache the styles, then clear them
  const styles = styleKeys.reduce((styles, key) => {
    styles[key] = node.style[key]
    node.style[key] = null
    return styles
  }, {})

  // Get the rect without the styles
  const rect = node.getBoundingClientRect()

  // Reset the styles back
  styleKeys.forEach((key) => {
    node.style[key] = styles[key]
  })

  // Return the position of the node
  if (attachment === 'top') return rect.top
  if (attachment === 'center') return rect.top + rect.height / 2
  if (attachment === 'bottom') return rect.top + rect.height
}

// Calculate the value is this range from 0 to 1
const getRatioInRange = (value, range) => {
  let ratio = (value - range[0]) / (range[1] - range[0])
  ratio = bound(ratio, 0, 1)
  return round(ratio)
}

const getValue = (node, calcValue, mobileValue = false) => {
  // If we are on the server, we have no idea
  if (typeof window === 'undefined') return false
  // If we are too small to parallax
  if (window.innerWidth < gridBreakpoints.lg) return mobileValue
  // If no node, we don't know the value
  if (!node) return
  // At last, we can calculate
  return calcValue(node)
}

export const useParallax = (node, ...args) => {
  const [value, setValue] = useState(getValue(node, ...args))

  useEffect(() => {
    // If no node, reset and don't listen
    if (!node) {
      const nextValue = getValue(node, ...args)
      setValue(nextValue)
      return
    }

    // Set that we can parallax
    $(node).addClass('parallax-loaded')

    // Handle changes
    const onChange = () => {
      const nextValue = getValue(node, ...args)
      setValue(nextValue)
    }

    // Update on bind
    onChange()

    // Add / Remove handlers
    window.addEventListener('resize', onChange, false)
    window.addEventListener('scroll', onChange, false)
    return () => {
      window.removeEventListener('resize', onChange, false)
      window.removeEventListener('scroll', onChange, false)
    }
  }, [node])

  return value
}

const wrapUseParallax =
  (calcValue, mobileValue) =>
  (node, ...args) =>
    useParallax(node, (node) => calcValue(node, ...args), mobileValue)

// Get the ratio of the node in between the on screen percentages
export const getScreenRatio = (
  node,
  { attachment = 'center', range = [0, 1], styleKeys } = {},
) => {
  // Calculate where on the screen the node is
  const ratio =
    getNodePosition(node, attachment, styleKeys) / window.innerHeight
  return getRatioInRange(ratio, range)
}

export const useParallaxScreenRatio = wrapUseParallax(getScreenRatio)

// Calculate the delta in px from a position on the screen
export const getScreenDelta = (node, offsetRatio = 0) => {
  // Calculate where on the screen the node is
  const position = getNodePosition(node, 'center')
  const ratio = position / window.innerHeight
  const centerRatio = 0.5
  const deltaRatio = ratio - centerRatio
  const maxDelta =
    node.offsetHeight / 2 + (0.5 - offsetRatio) * window.innerHeight

  if (Math.abs(deltaRatio) < offsetRatio) {
    // Locked
    return 0
  }
  if (deltaRatio < 0) {
    // Top side
    const offsetPosition = window.innerHeight * (centerRatio - offsetRatio)
    const delta = Math.round(position - offsetPosition)
    return Math.max(delta, -maxDelta)
  }
  // Bottom side
  const offsetPosition = window.innerHeight * (centerRatio + offsetRatio)
  const delta = Math.round(position - offsetPosition)
  return Math.min(delta, maxDelta)
}

// Calculate the ratio from the center of the screen (0 = no delta)
export const getScreenDeltaRatio = (node, offset = 0) => {
  // Calculate where on the screen the node is
  const ratio = getNodePosition(node, 'center') / window.innerHeight
  const delta = ratio - 0.5
  const edgeRatio = node.offsetHeight / 2 / window.innerHeight

  if (Math.abs(delta) < offset) {
    // Locked
    return 0
  }
  if (delta < 0) {
    // Top side
    return -getRatioInRange(ratio, [0.5 - offset, -edgeRatio])
  }
  // Bottom side
  return getRatioInRange(ratio, [0.5 + offset, 1 + edgeRatio])
}
