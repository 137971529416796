import { colors } from '@blissbook/ui/branding'
import { DesktopBrowser, MobileBrowser } from '@blissbook/ui/lib'
import React from 'react'
import { animationCompleteCss } from './animations'

const boxShadow =
  '0 30px 60px -12px rgba(57,57,138,.25), 0 18px 36px -18px rgba(0,0,0,0.3), 0 -12px 36px -8px rgba(0,0,0,.025), 1px 1px 5px 0 rgba(57,57,138,.1), 40px 60px 125px -25px rgba(57,57,138,0.5), 20px 40px 75px -40px rgba(0,0,0,.6);'
export const demoBrowserDisabledOpacity = 0.3

export const DemoDesktopBrowser = React.memo(
  ({ children, disabled, height = 500, width = 400, ...props }) => (
    <DesktopBrowser
      {...props}
      css={{
        boxShadow,
        color: colors.black,
        width,
      }}
      height={height}
    >
      {children}

      <DemoBrowserOverlay
        opacity={disabled ? 1 - demoBrowserDisabledOpacity : 0}
      />
    </DesktopBrowser>
  ),
)

export const DemoMobileBrowser = React.memo(
  ({ children, disabled, width, ...props }) => (
    <MobileBrowser
      {...props}
      css={{
        boxShadow,
        color: colors.black,
        width,
        zIndex: 1,
      }}
    >
      {children}

      <DemoBrowserOverlay
        css={{ bottom: 25, left: 5, right: 5, top: 25 }}
        opacity={disabled ? 1 - demoBrowserDisabledOpacity : 0}
      />
    </MobileBrowser>
  ),
)

export const DemoBrowserOverlay = ({ opacity = 0, ...props }) => (
  <div
    {...props}
    css={{
      ...animationCompleteCss,
      background: 'white',
      bottom: 0,
      left: 0,
      opacity,
      position: 'absolute',
      pointerEvents: 'none',
      right: 0,
      top: 0,
      zIndex: 1,
    }}
  />
)
