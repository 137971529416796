import { colors, getColor } from '@blissbook/ui/branding'
import { Shape } from '@blissbook/ui/lib/shape'
import classnames from 'classnames'
import React from 'react'

const defaultColor = colors['sunshine-500']

export default ({
  children,
  includeIcon,
  borderColor,
  className,
  ...props
}) => (
  <div className={classnames('tw-p-2 tw-mb-1', className)} {...props}>
    <div
      className='tw-p-14 tw-pt-14'
      style={{
        position: 'relative',
        border: `1px solid ${borderColor || defaultColor}`,
        borderRadius: '5px',
        height: '100%',
      }}
    >
      <If condition={includeIcon}>
        <div
          style={{
            position: 'absolute',
            top: '-35px',
            borderColor: borderColor || defaultColor,
            backgroundColor: getColor('white'),
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '35px',
            width: '70px',
            height: '70px',
          }}
        >
          <Shape
            style={{
              position: 'absolute',
              top: '20px',
              left: '9px',
            }}
            type='quote'
            color={borderColor || defaultColor}
            fill
            size={30}
          />
          <Shape
            style={{
              position: 'absolute',
              top: '20px',
              left: '30px',
            }}
            type='quote'
            color={borderColor || defaultColor}
            fill
            size={30}
          />
        </div>
      </If>
      {children}
    </div>
  </div>
)
