import { Link } from '@blissbook/ui/lib'
import React from 'react'
import { Section, Typography } from '../../components'

export default () => (
  <Section
    id='contact'
    className='text-center py-lg'
    css={{ backgroundColor: '#f6f6fc' }}
  >
    <img
      src='/images/marketing/pricing/Phone.svg'
      className='tw-mb-10'
      aria-label='a phone'
    />
    <Typography variant='h1' className='tw-mx-auto' style={{ maxWidth: 500 }}>
      Talk to a real, live human being.
    </Typography>
    <Typography variant='p1' className='tw-mx-auto' style={{ maxWidth: 700 }}>
      Sometimes it’s just easier to ask questions and hear answers out loud, ya
      know? Get in touch and we’ll schedule a time to talk through your project.
      We can also do a demo!
    </Typography>
    <Link to='/request-a-demo' className='btn btn-primary tw-mx-auto'>
      Contact Us
    </Link>
  </Section>
)
