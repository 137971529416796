import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import { scrollToHash } from '@blissbook/ui/util/scroll'
import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToHash extends Component {
  componentDidUpdate(prevProps) {
    const { history, location } = this.props
    if (history.action !== 'REPLACE') {
      if (location.pathname !== prevProps.location.pathname) {
        scrollToHash(location.hash).catch(logUIError)
      } else if (location.hash !== prevProps.location.hash) {
        scrollToHash(location.hash, { delay: 250 }).catch(logUIError)
      }
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToHash)
