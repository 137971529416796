import { Field } from '@blissbook/ui/lib'
import { enterAuthCode } from '@blissbook/ui/marketing/queries'
import { handleError } from '@blissbook/ui/util/errors'
import { Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { AuthButton } from '../../components'

const authCodeLength = 6

const validationSchema = Yup.object().shape({
  authCode: Yup.string().required(),
})

export const AuthCodeForm = ({ userId }) => {
  const [lastAuthCode, setLastAuthCode] = useState()

  const onSubmit = async (values, { setStatus }) => {
    setStatus({ isSubmitting: true })

    try {
      const { authCode } = values
      setLastAuthCode(authCode)
      const isValid = await enterAuthCode({ authCode, userId })
      if (isValid) {
        window.location.href = '/home'
      }
    } catch (error) {
      handleError(error)
      setStatus()
    }
  }

  return (
    <Formik
      initialValues={{
        authCode: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({
        handleSubmit,
        status: { isSubmitting } = {},
        values: { authCode },
      }) => (
        <form id='sign-in-auth-code' noValidate onSubmit={handleSubmit}>
          <fieldset disabled={isSubmitting}>
            <Field
              codeType='number'
              fields={authCodeLength}
              name='authCode'
              type='code'
              autoFocus
            />

            <div className='form-group'>
              <AuthButton
                disabled={[
                  authCode.length < authCodeLength,
                  authCode === lastAuthCode,
                ].some(Boolean)}
                style={{ minWidth: 'auto' }}
                type='submit'
              >
                Sign In
              </AuthButton>
            </div>
          </fieldset>
        </form>
      )}
    </Formik>
  )
}
