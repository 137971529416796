import config from '@blissbook/ui-config'
import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import { Col, Container, Row } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Typography from '../Typography'
const { assetsUrl = '', blogUrl } = config

const FooterColumn = (props) => (
  <Col
    {...props}
    className={'tw-mb-4 tw-px-0'}
    css={css`
      @media (max-width: ${gridBreakpoints.sm - 1}px) {
        text-align: center;
      }
    `}
    xs={12}
    sm={6}
    md='auto'
  />
)

const FooterHeader = (props) => (
  <Typography {...props} variant='h4' css={{ color: 'white' }} />
)

const wrapFooterLink = (Link) => (props) => (
  <Link
    css={css`
        color: inherit;
        &:hover {
          color: inherit;
        }
      `}
    className='tw-block tw-my-2'
    {...props}
  />
)

export const FooterContainer = ({ Link }) => {
  const FooterLink = wrapFooterLink(Link)
  return (
    <Container>
      <Row className={'tw-pt-10 tw-pb-4 tw-mx-0'}>
        <Col className={'tw-px-0'} xs={12} lg={8}>
          <Row className={'tw-justify-between tw-mx-0'}>
            <FooterColumn>
              <FooterHeader>Solutions</FooterHeader>
              <FooterLink to='/employee-handbook-software'>
                Online Employee Handbook
              </FooterLink>
              <FooterLink to='/enterprise-policy-management'>
                Enterprise Policy Management
              </FooterLink>
              <FooterLink to='/employee-handbook-hr-policy-content'>
                Handbook Content Services
              </FooterLink>
              <FooterLink to='/legal-compliance'>
                SOC 2 Audit Readiness
              </FooterLink>
              <FooterLink to='/branded-employee-handbook'>
                Branded Employee Handbook
              </FooterLink>
              <FooterLink to='/employee-handbook-management'>
                PEOs and Agencies
              </FooterLink>
            </FooterColumn>

            <FooterColumn>
              <FooterHeader>Capabilities</FooterHeader>
              <FooterLink to='/personalized-handbooks'>
                Personalized Handbooks
              </FooterLink>
              <FooterLink to='/policy-communication'>
                Policy Communication
              </FooterLink>
              <FooterLink to='/manager-participation'>
                Manager Participation
              </FooterLink>
            </FooterColumn>

            <FooterColumn>
              <FooterHeader>Resources</FooterHeader>
              <FooterLink href={blogUrl}>Blog</FooterLink>
              <FooterLink to='/request-a-demo'>Request a Demo</FooterLink>
              <FooterLink to='/newsletter'>Newsletter</FooterLink>
              <FooterLink to='/how-to-write-a-culture-first-employee-handbook'>
                Culture Handbooks
              </FooterLink>
              <FooterLink to='/how-to-write-policies-procedures'>
                How to Write Policies
              </FooterLink>
              <FooterLink to='/awkward-hr-conversations'>
                Awkward HR Convos
              </FooterLink>
            </FooterColumn>

            <FooterColumn>
              <FooterHeader>Support</FooterHeader>
              <FooterLink to='/about'>About</FooterLink>
              <FooterLink href='https://help.blissbook.com/'>
                Help Center
              </FooterLink>
              <FooterLink href='mailto:support@blissbook.com'>
                Email Us
              </FooterLink>
              <FooterLink to='/pricing'>Pricing</FooterLink>
              <FooterLink href='https://status.blissbook.com/'>
                Status
              </FooterLink>
              <FooterLink href='https://twitter.com/blissbookapp'>
                Twitter
              </FooterLink>
            </FooterColumn>
          </Row>
        </Col>

        <Col
          className={'tw-mb-4 tw-px-0'}
          xs={12}
          lg={4}
          css={{ color: 'white', fontSize: 12 }}
        >
          <div className='tw-flex tw-items-center tw-justify-center tw-mb-4'>
            <img
              alt='rainbow'
              role='presentation'
              src={assetsUrl + '/images/monogram-rainbow.svg'}
            />
          </div>
          <div className='tw-flex tw-items-center tw-justify-center'>
            Built alongside&nbsp;
            <FontAwesomeIcon icon='cat' />s &amp;&nbsp;
            <FontAwesomeIcon icon='dog' />s in the good ol’ USA
          </div>
          <div className='tw-flex tw-items-center tw-justify-center'>
            <span>© {new Date().getFullYear()} Blissbook</span>
            <span className='tw-mx-4'>|</span>
            <FooterLink to='/terms'>Terms</FooterLink>
            <span className='tw-mx-4'>|</span>
            <FooterLink to='/privacy-policy'>Privacy</FooterLink>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export const footerStyle = {
  backgroundColor: colors['blurple-500'],
  color: colors['sunshine-300'],
}

export default (props) => (
  <footer css={footerStyle}>
    <FooterContainer {...props} />
  </footer>
)
