import { fontFamily, getColor } from '@blissbook/ui/branding'
import { Shape } from '@blissbook/ui/lib/shape'
import React from 'react'

import './styles/quote_bordered.scss'

const borderColor = getColor('sunshine-500')

export default ({ quote, attribution, ...props }) => (
  <blockquote
    className='blockquote quote-bordered'
    {...props}
    style={{
      position: 'relative',
      borderColor: borderColor,
      borderWidth: '1px',
      borderRadius: '5px',
      borderStyle: 'solid',
      padding: '70px 55px 40px',
      marginTop: '40px',
    }}
  >
    <div
      style={{
        position: 'absolute',
        top: '-35px',
        borderColor: borderColor,
        backgroundColor: getColor('white'),
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '35px',
        width: '70px',
        height: '70px',
      }}
    >
      <Shape
        style={{
          position: 'absolute',
          top: '20px',
          left: '9px',
        }}
        type='quote'
        color={borderColor}
        fill
        size={30}
      />
      <Shape
        style={{
          position: 'absolute',
          top: '20px',
          left: '30px',
        }}
        type='quote'
        color={borderColor}
        fill
        size={30}
      />
    </div>
    {quote}
    <footer
      className='blockquote-attribution'
      style={{
        marginTop: '40px',
        fontSize: '80%',
        fontStyle: 'normal',
        fontFamily: fontFamily,
      }}
    >
      <span
        style={{
          textTransform: 'uppercase',
          fontWeight: 'bold',
          display: 'block',
          fontSize: '95%',
          letterSpacing: '2px',
        }}
      >
        {attribution.name}
      </span>
      <cite
        style={{
          display: 'block',
          fontStyle: 'normal',
        }}
      >
        {attribution.title}
      </cite>
    </footer>
  </blockquote>
)
