import { Session } from '@blissbook/common/session'
import { Store } from '@blissbook/ui/util/store'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'

export const defaultState = {
  auth: {
    company: '',
    email: '',
    fullName: '',
    newsletter: false,
    phone: '',
    subdomain: '',
  },
  signUp: {
    step: 0,
    maxStep: 0,
    showRedirectSuggestion: false,
  },
}

export const createStore = (initialState: any) => {
  // Add default state
  initialState = merge(cloneDeep(defaultState), initialState)

  // Create the store
  const { session } = initialState
  return Store.create({
    ...initialState,
    session: Session.fromJSON(session),
  })
}
