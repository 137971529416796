import { Button, Col, Container, Field, Row } from '@blissbook/ui/lib'
import { handleError } from '@blissbook/ui/util/errors'
import { submitForm } from '@blissbook/ui/util/integrations/intercom'
import { addToast } from '@blissbook/ui/util/toaster'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { MarketingForm, Section, Typography } from '../../components'

const validationSchema = Yup.object().shape({
  body: Yup.string().required(),
  email: Yup.string().required().email().companyEmail(),
  name: Yup.string().required(),
})

const onSubmit = async (values, { setStatus }) => {
  setStatus({ isSubmitting: true })

  try {
    await submitForm(values)
    addToast(`Thanks! We'll get back to you as soon as we can.`, {
      type: 'success',
    })
    setStatus({ hasSubmitted: true })
  } catch (error) {
    handleError(error, 'There was an error sending your message')
    setStatus()
  }
}

export default () => (
  <Section
    id='contact'
    className='py-sm'
    css={{
      background:
        'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(248,248,248,1) 100%);',
    }}
  >
    <Container>
      <Typography variant='h1' className='tw-mx-auto text-center'>
        Get in touch.
      </Typography>

      <div className='tw-mx-auto' css={{ width: '100%', maxWidth: 900 }}>
        <Formik
          initialValues={{
            body: '',
            email: '',
            name: '',
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ handleSubmit, status: { hasSubmitted, isSubmitting } = {} }) => (
            <MarketingForm noValidate onSubmit={handleSubmit}>
              <fieldset disabled={isSubmitting || hasSubmitted}>
                <Row>
                  <Col xs={12} lg={6}>
                    <Field name='name' label='Name' />
                  </Col>
                  <Col xs={12} lg={6}>
                    <Field name='email' label='Work Email Address' />
                  </Col>
                </Row>
                <Field
                  name='body'
                  label='How can we help you?'
                  type='textarea'
                  minRows={3}
                />

                <Button type='submit' color='primary'>
                  {hasSubmitted ? 'Sent!' : 'Send'}
                </Button>
              </fieldset>
            </MarketingForm>
          )}
        </Formik>
      </div>
    </Container>
  </Section>
)
