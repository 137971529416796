import { subdomainRegEx } from '@blissbook/lib/sanitize/domain'
import {
  BackLink,
  Button,
  DotsStepper,
  Field,
  FullScreenPage,
  Head,
  Link,
} from '@blissbook/ui/lib'
import {
  setAuthValue,
  setShowRedirectSuggestion,
  setSignUpStep,
} from '@blissbook/ui/marketing/actions'
import { isExistingOrganization, signUp } from '@blissbook/ui/marketing/queries'
import { handleError } from '@blissbook/ui/util/errors'
import { useStore } from '@blissbook/ui/util/store'
import { Formik } from 'formik'
import { Fragment } from 'react'
import React from 'react'
import * as Yup from 'yup'
import { AuthBackLink, AuthButton, AuthLayout, Typography } from '../components'

const STEPS = [
  {
    id: 'email',
    title: 'Get Started.',
    description:
      'Create a branded, digital handbook, launch it to an audience, and collect & report on e-signatures. It’s free until you launch.',
    validationSchema: Yup.object().shape({
      email: Yup.string().required().email().companyEmail().validEmail(),
    }),
    Component: ({ onChange }) => (
      <Field
        name='email'
        label='Work Email Address'
        onChange={onChange}
        type='email'
        autoFocus
      />
    ),
  },
  {
    id: 'name',
    title: 'Welcome.',
    description:
      'It’s nice to meet you! What should we call you and where do you work?',
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required(),
      company: Yup.string().required(),
    }),
    Component: ({ onChange }) => (
      <Fragment>
        <Field
          name='fullName'
          label='Your Full Name'
          onChange={onChange}
          autoFocus
        />

        <Field
          name='company'
          label={`Your Organization's Name`}
          onChange={onChange}
        />
      </Fragment>
    ),
  },
  {
    id: 'subdomain',
    title: 'Pick a subdomain.',
    description:
      'Your organization’s subdomain is part of the link or URL used to access your handbook(s), so pick something memorable.',
    validationSchema: Yup.object().shape({
      subdomain: Yup.string()
        .required()
        .matches(subdomainRegEx, 'Only letters, numbers and dashes')
        .validSubdomain(),
    }),
    Component: ({ onChange }) => (
      <Field
        name='subdomain'
        label='Subdomain'
        append='.blissbook.com'
        onChange={onChange}
        autoFocus
      />
    ),
  },
]

export default () => {
  // Current state
  const values = useStore('auth')
  const { step, maxStep, showRedirectSuggestion } = useStore('signUp')

  // Handle use submitting a form
  const onSubmit = async (values, { setStatus }) => {
    const { company, email, fullName, subdomain } = values
    //check for duplicate org
    if (step === 0) {
      const emailDomain = email.split('@')[1]
      const organizationExists = await isExistingOrganization(emailDomain)

      if (organizationExists) {
        setShowRedirectSuggestion(true)
        return
      }
    }
    // If this is
    const nextStep = step + 1
    if (nextStep < STEPS.length) {
      setSignUpStep(nextStep)
    } else {
      setStatus({ isSubmitting: true })

      try {
        await signUp({
          organization: { name: company, subdomain },
          owner: { email, fullName },
        })
        window.location.href = '/home'
      } catch (error) {
        handleError(error)
        setStatus()
      }
    }
  }

  // Pull from the current step
  const { id, title, description, validationSchema, Component } = STEPS[step]

  const resumeAuthStep = () => {
    setShowRedirectSuggestion(false)
    setSignUpStep(step + 1)
  }

  const RedirectSuggestion = () => {
    return (
      <>
        <Typography className='auth' variant='h1'>
          Sign in instead?
        </Typography>
        <Typography className='auth' variant='p2'>
          An organization already exists with others who have{' '}
          <span className='tw-font-semibold'>
            @{values.email.split('@')[1]}
          </span>{' '}
          in their email address.
          {'\n\n'}
        </Typography>
        <Typography className='auth' variant='p2'>
          Are you trying to sign in to that organization?
        </Typography>

        <Link className='btn btn-primary tw-mt-8' href={'/sign-in'}>
          Sign into your existing Organization
        </Link>
        <Button
          className='btn btn-outline-primary tw-mt-4'
          onClick={() => resumeAuthStep()}
        >
          Continue Creating a New Organization
        </Button>
      </>
    )
  }

  return (
    <FullScreenPage>
      <Head
        title='Sign Up for Blissbook'
        description='Upgrade your old pdf employee handbook and join other HR pros who use Blissbook to manage their policies online. Sign up today!'
      />

      <AuthLayout imageUrl='/images/stamp-angled-offblack.svg'>
        <DotsStepper
          currentStep={step - 1}
          onClickStep={(index) => {
            if (index < maxStep) {
              setSignUpStep(index + 1)
            }
          }}
          stepCount={STEPS.length - 1}
          style={{
            marginTop: -24,
            visibility: step > 0 ? 'visible' : 'hidden',
          }}
        />

        <Choose>
          <When condition={showRedirectSuggestion}>
            <RedirectSuggestion />
          </When>
          <Otherwise>
            <Formik
              key={step}
              initialValues={values}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, status: { isSubmitting } = {} }) => (
                <form id={`sign-up-${id}`} noValidate onSubmit={handleSubmit}>
                  <fieldset disabled={isSubmitting}>
                    <Typography className='auth' variant='h1'>
                      {title}
                    </Typography>
                    <Typography className='auth' variant='p2'>
                      {description}
                    </Typography>

                    <Component
                      onChange={(event, value) => {
                        const { name } = event.target
                        setAuthValue(name, value)
                      }}
                    />

                    <div className='tw-flex tw-items-center'>
                      <AuthButton type='submit'>
                        <Choose>
                          <When condition={step === 0}>Get Started!</When>
                          <When condition={step === STEPS.length - 1}>
                            Start Using Blissbook
                          </When>
                          <Otherwise>Continue</Otherwise>
                        </Choose>
                      </AuthButton>

                      <If condition={step === 0}>
                        <Typography
                          className='tw-ml-6'
                          style={{ maxWidth: 230 }}
                          variant='muted'
                        >
                          By clicking this button, you agree to Blissbook’s{' '}
                          <Link className='btn-link -underline' href='/terms'>
                            Terms of Use
                          </Link>
                          .
                        </Typography>
                      </If>
                    </div>

                    <Choose>
                      <When condition={isSubmitting} />
                      <When condition={step > 0}>
                        <BackLink
                          className='tw-block tw-mt-4'
                          css={{ fontSize: 14, fontWeight: 300 }}
                          onClick={() => setSignUpStep(step - 1)}
                        >
                          Previous
                        </BackLink>
                      </When>
                      <Otherwise>
                        <Typography className='tw-mt-8' variant='muted'>
                          Already have an account?{' '}
                          <Link to='/sign-in'>Sign In</Link>
                        </Typography>
                        <AuthBackLink className='tw-block tw-mt-6' />
                      </Otherwise>
                    </Choose>
                  </fieldset>
                </form>
              )}
            </Formik>
          </Otherwise>
        </Choose>
      </AuthLayout>
    </FullScreenPage>
  )
}
