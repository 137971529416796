import { colors } from '@blissbook/ui/branding'
import { keyframes } from '@emotion/react'

const getAnimation = ({
  duration,
  fillMode = 'forwards',
  iterationCount = 1,
  name,
  timingFunction = 'ease',
}) => `${name} ${duration}ms ${timingFunction} ${iterationCount} ${fillMode}`

export class Animation {
  constructor(props) {
    Object.assign(this, props)
  }

  get style() {
    return getAnimation(this)
  }
}

export const animationCompleteCss = {
  transitionDelay: '500ms',
  transitionDuration: '1000ms',
}

export const clickAnimation = new Animation({
  name: keyframes`
    from, to { transform: scale(1); }
    50% { transform: scale(0.85); }
  `,
  duration: 500,
  timingFunction: 'ease',
})

export const getClickExplosionAnimation = (key, props) =>
  new Animation({
    duration: 750,
    timingFunction: 'linear',
    ...props,
    name: keyframes`
    from { transform: scale(1); }
    25% { ${key}: ${colors['blurple-500']}; opacity: 1; transform: scale(1); }
    to { ${key}: ${colors['blurple-500']}; opacity: 0; transform: scale(1.25); }
  `,
  })

export const scrollAnimation = {
  duration: 500,
}
