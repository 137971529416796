import { colors } from '@blissbook/ui/branding'
import {
  // @ts-ignore: WIP imports
  BackgroundShapeGrid,
  Container,
  IconTooltip,
  Link,
  // @ts-ignore: WIP imports
  gridShapeSize,
} from '@blissbook/ui/lib'
import { SliderInput } from '@blissbook/ui/lib/input/SliderInput'
import { css } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import { type RouteComponentProps, withRouter } from 'react-router-dom'
import { Section, Typography } from '../../components'
import { TrustedByLogoGrid } from '../Home/TestimonialsSection'
import AddOns from './AddOns'
import WhatsIncluded from './WhatsIncluded'

interface Plan {
  id: string
  title: string
  icon: React.ReactNode
  description: React.ReactNode
  features?: string[]
  price?: number
  isPro?: boolean
  customCard?: boolean
}

interface PricingCardProps extends Omit<Plan, 'id'> {
  // Extend Plan and omit unnecessary fields
  isHighlighted: boolean
  customerPercentage?: number
}

interface EmployeePlanMapping {
  minEmployeesCount: number
  maxEmployeesCount: number
  planId: string
  planPercentage: number
}

export const plans: Plan[] = [
  {
    id: 'blissbook',
    title: 'Blissbook',
    icon: (
      <img
        src='/images/marketing/home/ice-cream.svg'
        className='tw-mb-[15px] tw-h-[50px]'
        aria-label='a surprise box'
      />
    ),
    description: <div>Ideal for most SMBs launching digital handbooks.</div>,
    features: [
      'Import, edit, and manage policies',
      'Collaborate with your team',
      'Publish branded, digital documents',
      'Collect & report on e-signatures',
      'Stay compliant with changing laws',
      'Automate the busy work',
    ],
    price: 9,
  },
  {
    id: 'blissbook-pro',
    title: 'Blissbook',
    icon: (
      <img
        src='/images/marketing/home/banana-split.svg'
        className='tw-mb-[15px] tw-h-[50px]'
        aria-label='a surprise box'
      />
    ),
    description: (
      <div>
        Perfect for enterprise, those striving for 100% compliance, and teams
        that want the best.
      </div>
    ),
    features: [
      'Policy approval workflows',
      'Granular policy collaboration',
      'Automated manager escalation',
      'HRBP reporting role',
      'Extended version history',
      'Paper signature tracking',
    ],
    price: 11,
    isPro: true,
  },
  {
    id: 'custom',
    title: 'Custom',
    icon: (
      <img
        src='/images/marketing/home/surprise.svg'
        className='tw-mb-[15px] tw-h-[50px]'
        aria-label='a surprise box'
      />
    ),
    description: (
      <>
        <div>Special requirements?</div>
        <div>Don't see what you need?</div>
        <div className='tw-text-lg tw-font-light'>
          Let's work together to build the best Blissbook for you.
        </div>
      </>
    ),
    customCard: true,
  },
]

export const employeePlanMapping: EmployeePlanMapping[] = [
  {
    minEmployeesCount: 1,
    maxEmployeesCount: 100,
    planId: 'blissbook',
    planPercentage: 70,
  },
  {
    minEmployeesCount: 101,
    maxEmployeesCount: 150,
    planId: 'blissbook',
    planPercentage: 75,
  },
  {
    minEmployeesCount: 151,
    maxEmployeesCount: 200,
    planId: 'blissbook',
    planPercentage: 80,
  },
  {
    minEmployeesCount: 201,
    maxEmployeesCount: 250,
    planId: 'blissbook',
    planPercentage: 85,
  },
  {
    minEmployeesCount: 251,
    maxEmployeesCount: 300,
    planId: 'blissbook-pro',
    planPercentage: 90,
  },
  {
    minEmployeesCount: 301,
    maxEmployeesCount: 400,
    planId: 'blissbook-pro',
    planPercentage: 92,
  },
  {
    minEmployeesCount: 401,
    maxEmployeesCount: 500,
    planId: 'blissbook-pro',
    planPercentage: 94,
  },
  {
    minEmployeesCount: 501,
    maxEmployeesCount: 600,
    planId: 'blissbook-pro',
    planPercentage: 96,
  },
  {
    minEmployeesCount: 601,
    maxEmployeesCount: 700,
    planId: 'custom',
    planPercentage: 97,
  },
  {
    minEmployeesCount: 701,
    maxEmployeesCount: 800,
    planId: 'custom',
    planPercentage: 98,
  },
  {
    minEmployeesCount: 801,
    maxEmployeesCount: Number.POSITIVE_INFINITY,
    planId: 'custom',
    planPercentage: 99,
  },
]

const PricingCard: React.FC<PricingCardProps> = ({
  title,
  icon,
  description,
  features,
  price,
  isPro,
  customCard,
  isHighlighted,
  customerPercentage,
}) => {
  const [selectedPackage, setSelectedPackage] = useState('Standard')

  const supportPackages = ['Standard', 'Standard +', 'Premium']

  return (
    <div
      className={`
        tw-flex tw-flex-col tw-items-start tw-bg-white tw-rounded-lg tw-mx-auto tw-mb-10 tw-overflow-hidden
        ${isHighlighted ? 'lg:tw-transform lg:tw-scale-105 tw-z-10 !tw-bg-[#FFF9EC]' : ''}
        ${
          isHighlighted
            ? 'tw-shadow-pricing-highlighted hover:tw-shadow-pricing-highlighted-hover'
            : 'tw-shadow-pricing hover:tw-shadow-pricing-hover'
        }
        tw-transition-all tw-duration-300 tw-ease-in-out
        tw-max-w-full tw-w-[350px]
      `}
    >
      {isHighlighted && (
        <div className='tw-bg-red-500 tw-text-white tw-text-sm tw-py-1 tw-px-3 tw-text-center tw-w-full'>
          {customerPercentage}% of customers your size pick this plan
        </div>
      )}
      <div
        className={`tw-p-6 tw-w-full tw-flex tw-flex-col tw-h-full tw-border-[3px] tw-border-gray-300 ${isHighlighted ? 'tw-bg-[#FFF9EC] tw-border-t-0' : 'tw-rounded-lg'}`}
      >
        <div>
          <span className='tw-text-3xl tw-mr-2'>{icon}</span>
          <h3 className='tw-text-xl tw-font-bold tw-text-gray-800 tw-grid tw-grid-flow-col tw-grid-cols-[auto_1fr] tw-justify-items-start tw-items-center tw-gap-x-1.5'>
            {title}{' '}
            {isPro && (
              <span className='tw-bg-[#AC11D8] tw-text-white tw-text-xs tw-px-2 tw-py-1 tw-rounded-full'>
                PRO
              </span>
            )}
          </h3>
        </div>
        <p className='tw-text-gray-800 tw-mb-5 tw-text-xl tw-leading-[34px] tw-font-normal'>
          {description}
        </p>
        {!customCard ? (
          <>
            <p className='tw-font-semibold tw-mb-2'>
              {isPro
                ? 'Everything in Blissbook, plus:'
                : 'Everything you need to:'}
            </p>
            <ul className='tw-space-y-2 tw-mb-6 tw-w-full tw-px-1'>
              {features.map((feature, index) => (
                <li key={index} className='tw-flex tw-items-start tw-text-sm'>
                  <span className='tw-text-blue-500 tw-mr-2'>•</span>
                  {feature}
                </li>
              ))}
            </ul>
            <div className='tw-mt-auto !tw-mb-auto'>
              {' '}
              <div className='tw-text-lg tw-font-bold tw-mb-2 tw-w-full tw-grid tw-justify-items-center tw-grid-cols-[repeat(2,_auto)]'>
                <span className='tw-text-xl tw-col-span-full'>UNDER</span>
                <div className='tw-text-3xl tw-col-span-full tw-grid tw-grid-cols-[repeat(2,_auto)] tw-gap-x-1.5'>
                  <span className='tw-text-[44px]'>${price} </span>
                  <div className='tw-grid tw-py-[1px]'>
                    <span className='tw-text-sm tw-font-normal tw-text-gray-500'>
                      / employee
                    </span>
                    <span className='tw-text-sm tw-font-normal tw-text-gray-500'>
                      / year
                    </span>
                  </div>
                </div>
              </div>
              <div className='tw-text-sm tw-text-blue-600 tw-mb-4 tw-text-[#FC9D06] tw-font-bold tw-tracking-[1.75px] tw-text-center'>
                + ADD-ONS
              </div>
            </div>
          </>
        ) : (
          <div className='tw-mb-4 tw-w-full'>
            <Typography
              variant='h4'
              className='tw-text-center tw-text-gray-500 tw-mb-3'
            >
              TRUSTED BY
            </Typography>{' '}
            <TrustedByLogoGrid />
          </div>
        )}
        {isPro && (
          <div className='tw-mt-auto tw-mb-6 tw-text-center tw-w-full'>
            <span className='tw-text-xs tw-mr-2 tw-text-gray-800 tw-font-bold tw-tracking-[1.75px] tw-uppercase'>
              Incl. Support Package{' '}
              <IconTooltip
                className='tw-text-aqua-500'
                icon={['far', 'info-circle']}
                placement='top'
              >
                <p>
                  Import, edit, organize, and manage policies. Automated version
                  and audit trails and approval workflows.
                </p>
                <p>
                  Extra toppings to take implementing and operating Blissbook to
                  the next level.
                </p>
              </IconTooltip>
            </span>
            <div
              className={`tw-inline-flex tw-relative tw-rounded-lg tw-p-1 ${isHighlighted ? 'tw-bg-[#FEF2D9]' : 'tw-bg-gray-100'}`}
            >
              {supportPackages.map((pkg) => (
                // biome-ignore lint/a11y/useButtonType: <explanation>
                <button
                  key={pkg}
                  className={`tw-text-xs tw-px-3 sm:tw-px-4 md:tw-px-3 xl:tw-px-4 tw-whitespace-nowrap tw-py-1 tw-rounded-lg tw-transition-all tw-duration-300 tw-ease-in-out ${
                    selectedPackage === pkg
                      ? 'tw-bg-white tw-shadow-md tw-text-gray-800'
                      : 'tw-text-gray-600'
                  }`}
                  onClick={() => setSelectedPackage(pkg)}
                  style={{
                    zIndex: selectedPackage === pkg ? 1 : 0,
                  }}
                >
                  {pkg}
                </button>
              ))}
            </div>
          </div>
        )}
        <Link
          to='/request-a-demo'
          className={`btn ${
            isHighlighted ? 'btn-primary' : 'btn-outline-primary'
          } tw-mt-auto tw-px-7 tw-ml-auto tw-mr-auto`}
        >
          Request a Demo
        </Link>
      </div>
    </div>
  )
}

interface PricingSectionProps extends RouteComponentProps {}

const PricingSection: React.FC<PricingSectionProps> = ({
  history,
  location,
}) => {
  const [employeeCount, setEmployeeCount] = useState(500)
  const [highlightedPlan, setHighlightedPlan] = useState(plans[1])

  useEffect(() => {
    const newHighlightedPlan = getHighlightedPlan(employeeCount)
    if (newHighlightedPlan !== highlightedPlan) {
      setHighlightedPlan(newHighlightedPlan)
    }
  }, [employeeCount, highlightedPlan, history, location])

  const getHighlightedPlan = (count: number) => {
    const mapping = employeePlanMapping.find(
      (m: EmployeePlanMapping) =>
        count >= m.minEmployeesCount && count <= m.maxEmployeesCount,
    )
    return plans.find((plan: Plan) => plan.id === mapping?.planId)
  }

  const handleEmployeeCountChange = (newCount: number) => {
    setEmployeeCount(newCount)
  }

  const getPlanType = (plan: (typeof plans)[0]) => {
    if (plan.isPro) return 'pro'
    if (plan.customCard) return 'custom'
    return 'base'
  }

  const shapeOffset = gridShapeSize * 2

  const customerPercentage = (plan: Plan) => {
    return employeePlanMapping.find(
      (m: EmployeePlanMapping) =>
        m.planId === plan.id &&
        employeeCount >= m.minEmployeesCount &&
        employeeCount <= m.maxEmployeesCount,
    )?.planPercentage
  }

  return (
    <Section id='pricing'>
      <div
        css={css`
          position: absolute;
          top: ${gridShapeSize * 6}px;
          bottom: 0;
          left: 0;
          right: 0;
          background: ${colors['blurple-700']};
          z-index: -1;
        `}
      />

      <Container css={{ position: 'relative' }}>
        <BackgroundShapeGrid
          alignX='left'
          alignY='top'
          shapeDefaults={{ fill: true }}
          shapes={[
            { x: -1, y: 3, type: 'wedge', color: 'sunshine-700', rotate: 90 },
            { x: -1, y: 4, type: 'square', color: 'purple-500' },
            { x: 0, y: 2, type: 'square', color: 'sunshine-500' },
            { x: 1, y: 2, type: 'wedge', color: 'sunshine-300', rotate: 180 },
            { x: 1, y: 3, type: 'square', color: 'red-50' },
            { x: 1, y: 4, type: 'square', color: 'red-500' },
          ]}
          css={{ top: shapeOffset }}
        />

        <BackgroundShapeGrid
          alignX='right'
          alignY='top'
          shapeDefaults={{ fill: true }}
          shapes={[
            { x: -1, y: 2, type: 'wedge', color: 'purple-500', rotate: -90 },
            { x: -1, y: 4, type: 'square', color: 'blurple-500' },
            { x: 0, y: 3, type: 'wedge', color: 'aqua-500', rotate: 90 },
            { x: 1, y: 3, type: 'square', color: 'aqua-300' },
          ]}
          css={{ top: shapeOffset }}
        />

        <Typography
          variant='h1'
          className='tw-mx-auto text-center mt-lg tw-mb-4'
        >
          Give ice cream, not broccoli.
        </Typography>

        <Typography variant='p1' className='tw-mx-auto text-center tw-mb-6'>
          Culture-first handbooks and policy management <br /> that helps you
          "sneak the veggies in".
        </Typography>

        <EmployeeSlider
          employeeCount={employeeCount}
          setEmployeeCount={handleEmployeeCountChange}
          employeePlanMapping={employeePlanMapping}
        />

        <div className='tw-mt-10'>
          <div className='sm:tw-block md:tw-hidden'>
            <div className='tw-flex tw-justify-center'>
              <div className='tw-w-full tw-max-w-[380px]'>
                <PricingCard
                  {...highlightedPlan}
                  isHighlighted={true}
                  customerPercentage={
                    employeePlanMapping.find(
                      (m: EmployeePlanMapping) =>
                        m.planId === highlightedPlan.id,
                    )?.planPercentage
                  }
                />
              </div>
            </div>
          </div>

          <div className='tw-hidden md:tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-8'>
            {plans.map((plan: Plan) => (
              <PricingCard
                key={plan.id}
                {...plan}
                isHighlighted={plan === highlightedPlan}
                customerPercentage={customerPercentage(plan)}
              />
            ))}
          </div>
        </div>
        <WhatsIncluded selectedPlan={getPlanType(highlightedPlan)} />
        <AddOns selectedPlan={getPlanType(highlightedPlan)} />
      </Container>
    </Section>
  )
}

export default withRouter(PricingSection)

interface EmployeeSliderProps {
  employeeCount: number
  setEmployeeCount: (count: number) => void
  employeePlanMapping: typeof employeePlanMapping
}

const EmployeeSlider: React.FC<EmployeeSliderProps> = ({
  employeeCount,
  setEmployeeCount,
  employeePlanMapping,
}) => {
  const getEmployeeRangeText = () => {
    const currentStep = employeePlanMapping.find(
      (step: EmployeePlanMapping) =>
        employeeCount >= step.minEmployeesCount &&
        employeeCount <= step.maxEmployeesCount,
    )
    if (currentStep?.maxEmployeesCount === Number.POSITIVE_INFINITY) {
      return `${currentStep.minEmployeesCount}+`
    }
    return `${currentStep?.minEmployeesCount}-${currentStep?.maxEmployeesCount}`
  }

  const getStepFromEmployeeCount = (count: number) => {
    return employeePlanMapping.findIndex(
      (step: EmployeePlanMapping) =>
        count >= step.minEmployeesCount && count <= step.maxEmployeesCount,
    )
  }

  const getEmployeeCountFromStep = (step: number) => {
    return employeePlanMapping[step].minEmployeesCount
  }

  return (
    <div className='tw-mt-[100px] tw-max-w-xl tw-mx-auto tw-relative'>
      <div
        className='tw-absolute tw-bg-white tw-text-black tw-py-2 tw-px-4 tw-rounded-lg tw-text-sm tw-z-[2] tw-font-semibold tw-text-center'
        style={{
          top: '-60px',
          left: `calc(${(getStepFromEmployeeCount(employeeCount) / (employeePlanMapping.length - 1)) * 100}%)`,
          transform: 'translateX(-50%)',
          boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
          width: '100px',
        }}
      >
        <div className='tw-font-bold'>{getEmployeeRangeText()}</div>
        <div>employees</div>
      </div>
      <SliderInput
        min={0}
        max={employeePlanMapping.length - 1}
        value={getStepFromEmployeeCount(employeeCount)}
        onChange={(step) => setEmployeeCount(getEmployeeCountFromStep(step))}
        className='tw-mb-2'
        trackStyle={{ background: 'blue' }}
        handleStyle={{
          background: 'var(--color-blurple-500)',
          borderColor: 'var(--color-blurple-500)',
          opacity: 1,
        }}
      />
    </div>
  )
}
