import { getColor, gridBreakpoints } from '@blissbook/ui/branding'
import {
  BackgroundImage,
  Col,
  Container,
  DesktopBrowser,
  ForwardLink,
  Link,
  Row,
  Shape,
} from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  DotsHorizontal,
  HeadingDivider,
  Layout,
  MaxWidthContainer,
  Section,
  Typography,
} from '../components'
import { CallToActionSection, SolutionSection } from './sections'

const color = 'sunshine-500'

const FEATURES = [
  {
    title: 'Paperless = Accessible',
    description:
      'Handbooks are accessible 24/7 via a web browser from any device at a single, easy-to remember URL with a 99.9% uptime guarantee. And yes, you can print too!',
  },
  {
    title: 'Personalized Content',
    description:
      'Content can be personalized for each employee, and search makes it easy to find the content they’re looking for. Employees don’t see content that doesn’t apply to them.',
  },
  {
    title: 'Always Up-To-Date',
    description:
      'Don’t wait months or years to get the latest policy information in front of employees. Publish new content any time! All versions are tracked in Blissbook.',
  },
  {
    title: 'Share Policies With Legal',
    description:
      'It’s a snap to send your legal team content to review. Send a special preview link or print to PDF any version of any policy (back to the first time it was published).',
  },
]

const quoteShape = (
  <Shape
    style={{
      margin: '0 -10px 0 -10px',
      display: 'inline-block',
    }}
    type='quote'
    color={color}
    fill
    size={58}
  />
)

export default () => (
  <Layout
    title='HR and Policy Compliance the Blissbook Way'
    description={
      'Blissbook’s enterprise policy management software compliance features reduce your company’s HR compliance risk.'
    }
  >
    <SolutionSection
      color={color}
      image='Papers.svg'
      imageDesktopCss={css(`
        top: 40px;
        right: -230px;
        height: 480px;

        @media (min-width: ${gridBreakpoints.xl}px) {
          right: -110px;
        }
      `)}
      greeting='Legal Compliance aka C.Y.A.'
      title='Be audit-ready (SOC 2 or otherwise).'
      description='If litigation, hearings, third-party oversight, or customer audits are a part of your life, Blissbook has your back. Simple, fast reporting will demonstrate the effectiveness and professionalism of your governance.'
    />

    <Section>
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row>
            <Col lg={5} className='tw-mb-6'>
              <div
                className='tw-p-4 tw-mb-8 box-shadow'
                style={{
                  position: 'relative',
                  borderRadius: 5,
                  width: 164,
                }}
              >
                <img
                  alt='A signed document in Blissbook'
                  src='/images/marketing/solutions/Signed.svg'
                  style={{ width: '100%' }}
                />
                <FontAwesomeIcon
                  icon='check-circle'
                  className='tw-mr-4'
                  color={getColor('aqua-500')}
                  style={{
                    position: 'absolute',
                    right: -34,
                    bottom: -17,
                    fontSize: 34,
                  }}
                />
              </div>
              <Typography variant='h2'>Electronic Signatures</Typography>
              <Typography variant='p2'>
                Request and collect legally binding electronic signatures – as
                many as you’d like, whenever you’d like – from every employee.
              </Typography>
            </Col>
            <Col lg={5} className='tw-mb-6 offset-lg-2'>
              <img
                alt='A diff of a policy in Blissbook'
                className='tw-p-2 tw-mb-8 box-shadow'
                src='/images/marketing/resources/screens/diff.png'
                style={{
                  maxWidth: 240,
                  borderRadius: 5,
                }}
              />
              <Typography variant='h2'>Version Control</Typography>
              <Typography variant='p2'>
                Each policy’s version history tells you exactly what content
                changed, who published the new content, and when it was
                published.
              </Typography>
            </Col>
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section>
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <Row className='tw-flex-row-reverse tw-items-center'>
            <Col lg={6}>
              <DesktopBrowser
                className='box-shadow tw-mx-auto tw-mb-8 lg:tw-mb-0'
                css={css(`
                  max-width: 100%;
                  @media (min-width: ${gridBreakpoints.lg}px) {
                    width: 1000px;
                    max-width: 1000px;
                  }
                `)}
              >
                <img
                  alt='Blissbook Handbook in a browser window'
                  src='/images/marketing/resources/screens/browser-handbook.png'
                  style={{ width: '100%' }}
                />
              </DesktopBrowser>
            </Col>
            <Col lg={6}>
              <Typography variant='h3'>Your Digital File Cabinet</Typography>
              <Typography variant='h2'>Document Everything</Typography>
              <Typography variant='p2'>
                Blissbook documents everything and allows you to slice and dice
                documentation to create the reports you need:
              </Typography>
              <Typography variant='ul2'>
                <li>
                  Print the exact handbook (or just a single policy/section) an
                  employee signed off on.
                </li>
                <li>
                  Print all documentation – signatures, views, and emails.
                </li>
                <li>Filter documentation by location, manager, & more.</li>
                <li>Export to PDF or CSV.</li>
              </Typography>
            </Col>
            <Col lg={6} className='tw-mb-6 offset-lg-2' />
          </Row>
        </MaxWidthContainer>
      </Container>
    </Section>

    <Section
      css={{
        background: `linear-gradient(180deg, white 0%, ${getColor(
          color + 'White',
        )} 100%);`,
      }}
    >
      <Container className='py-sm'>
        <MaxWidthContainer className='tw-mx-auto' xs={960}>
          <div className='tw-flex tw-items-center tw-mb-14'>
            <DotsHorizontal color={color} css={{ flex: 1 }} />
            <BackgroundImage
              src='/images/marketing/solutions/Folder.svg'
              className='tw-mx-2'
              width={133}
              height={109}
              size='contain'
            />
            <DotsHorizontal color={color} css={{ flex: 1 }} />
          </div>

          <Row className='tw-mb-14'>
            {FEATURES.map((feature, index) => (
              <Col
                key={index}
                xs={12}
                md={6}
                lg={index % 2 === 0 ? 7 : 5}
                className='tw-mb-6'
              >
                <MaxWidthContainer lg={380}>
                  <HeadingDivider variant='h2' divider={{ color }}>
                    {feature.title}
                  </HeadingDivider>
                  <Typography variant='p2'>{feature.description}</Typography>
                </MaxWidthContainer>
              </Col>
            ))}
          </Row>

          <div className='text-center'>
            <Typography variant='h1'>Get emails like this.</Typography>
            <div className='tw-my-18'>
              {quoteShape} {quoteShape}
            </div>
            <Typography
              variant='quote'
              className='tw-px-8 text-left tw-mx-auto tw-my-8'
              style={{ maxWidth: 820 }}
            >
              “Yesterday we concluded our annual 3-day on-site assessment. The
              effort is significant and scope of the review covers all facets of
              information security and many areas of enterprise risk management,
              fraud, privacy, training, and human resources management.
              <br />
              <br />I wanted to let you know that{' '}
              <strong>Blissbook was invaluable</strong> to support this project
              and our recent SOC2 readiness assessment. In my opinion, this SaaS
              tool has <strong>saved many hours</strong> and demonstrated the
              effectiveness and professionalism of our governance to external
              assessors and audits.{' '}
              <strong>The ROI for this product is very high</strong>.
              <br />
              <br />
              Thank you for your leadership to implement this product and hard
              work to ensure we have quality governance for the organization.{' '}
              <strong>
                I was unable to keep a tally of the number of times I would hear
                an assessor or auditor say, ‘This is perfect, that is what I
                need.’
              </strong>{' '}
              Thank you for delivering a valuable resource we need for the
              company.”
            </Typography>
            <Typography
              variant='h4'
              className='tw-px-8 tw-mx-auto tw-my-8'
              style={{ maxWidth: 700 }}
            >
              SVP, Chief Information Security Officer (CISO) in an email to the
              Senior VP of HR, General Counsel, CIO, and CHRO
            </Typography>
          </div>
        </MaxWidthContainer>
      </Container>
    </Section>

    <CallToActionSection
      title='Ready for smooth-sailing on your audits?'
      description='Hundreds of companies, large and small, trust Blissbook to manage their policies. Tell us about your project and we’ll give your team a customized demo.'
      contentClassName=''
      includeLogos
    >
      <div className='md:tw-flex tw-items-center tw-mb-8'>
        <Link className='btn btn-primary tw-mr-6' to='/request-a-demo'>
          Request a Demo
        </Link>
        <ForwardLink className='tw-block tw-my-6' to='/pricing'>
          Learn more
        </ForwardLink>
      </div>
    </CallToActionSection>
  </Layout>
)
