import { css } from '@emotion/react'
import React from 'react'

export default (props) => (
  <form
    {...props}
    css={css`
      label {
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    `}
  />
)
