import config from '@blissbook/ui-config'
const { assetsUrl = '' } = config
const folder = '/how-to-write-policies-procedures'
const imgFolder = assetsUrl + '/images/marketing/resources/howto-ebook'

export const urls = {
  root: folder,
  prepWork: folder + '/getting-started-handbook-policies',
  stepWork: folder + '/planning-handbook-project',
  depthWork: folder + '/drafting-policies-procedures',
  yepWork: folder + '/revisions-decisions-approval',
  pepWork: folder + '/designing-policies-handbook',
  acceptWork: folder + '/distribution-acknowledgement-signatures',
  conclusion: folder + '/conclusion',
  ebook: '/assets/how-to-write-policies-and-procedures.pdf',
  workbook: '/assets/blissbook-how-to-write-policies-companion-workbook.pdf',
}

export const images = {
  placeholder: {
    title: 'Placeholder Image',
  },
  logo: {
    src: assetsUrl + '/images/monogram-rainbow.svg',
  },
  caseStudy: {
    src: imgFolder + '/case-study@2x.svg',
    style: { maxHeight: '100%', width: 'auto' },
    alt: 'Illustration of magnifying glass icon with colored blocks in the background',
  },
  howToDesk: {
    src: imgFolder + '/howto-desk.svg',
    style: { width: '361px', maxWidth: '100%' },
  },
  linkPrepWork: {
    src: imgFolder + '/prep-work-nav.svg',
    style: { maxWidth: '100%' },
  },
  headerPrepWork: {
    src: imgFolder + '/prep-work-header@2x.svg',
    alt: 'Illustration of man holding books',
  },
  linkStepWork: {
    src: imgFolder + '/step-work-nav.svg',
    style: { maxWidth: '100%' },
  },
  headerStepWork: {
    src: imgFolder + '/step-work-header@2x.svg',
    alt: 'Illustration of woman pointing at whiteboard',
  },
  linkDepthWork: {
    src: imgFolder + '/depth-work-nav.svg',
    style: { maxWidth: '100%' },
  },
  headerDepthWork: {
    src: imgFolder + '/depth-work-header@2x.svg',
    alt: 'Illustration of woman with light bulb above head',
  },
  linkYepWork: {
    src: imgFolder + '/yep-work-nav.svg',
    style: { maxWidth: '100%' },
  },
  headerYepWork: {
    src: imgFolder + '/yep-work-header@2x.svg',
    alt: 'Illustration of woman holding laptop',
  },
  linkPepWork: {
    src: imgFolder + '/pep-work-nav.svg',
    style: { imgFolder: '100%' },
  },
  headerPepWork: {
    src: imgFolder + '/pep-work-header@2x.svg',
    alt: 'Illustration of man with a pencil',
  },
  linkAcceptWork: {
    src: imgFolder + '/accept-work-nav.svg',
    style: { maxWidth: '100%' },
  },
  headerAcceptWork: {
    src: imgFolder + '/accept-work-header@2x.svg',
    alt: 'Illustration of woman sitting with laptop',
  },
  profileChanin: {
    src: imgFolder + '//quotes/chanin_circle_small.png',
    alt: 'Photo of Chanin',
  },
  profileKate: {
    src: imgFolder + '/quotes/kate_circle_small.png',
    alt: 'Photo of Kate',
  },
  profileLindsey: {
    src: imgFolder + '/quotes/lindsey_circle_small.png',
    alt: 'Photo of Lindsey',
  },
  profileJeni: {
    src: imgFolder + '/quotes/jeni_circle_small.png',
    alt: 'Photo of Jeni',
  },
  profileCynthia: {
    src: imgFolder + '/quotes/cynthia_circle_small.png',
    alt: 'Photo of Cynthia',
  },
  workbook: {
    cover: {
      src: imgFolder + '/workbook/pandp-workbook-cover.png',
      alt: 'Workbook cover',
    },
    commsStrategy: {
      src: imgFolder + '/workbook/pandp-workbook-comms-strategy.png',
      alt: 'Comms strategy',
    },
    distributionMethods: {
      src: imgFolder + '/workbook/pandp-workbook-distribution-methods.png',
      alt: 'Distribution methods',
    },
    emailTemplates: {
      src: imgFolder + '/workbook/pandp-workbook-email-templates.png',
      alt: 'Email templates',
    },
    milestones1: {
      src: imgFolder + '/workbook/pandp-workbook-project-milestones-1.png',
      alt: 'Project milestones 1',
    },
    milestones2: {
      src: imgFolder + '/workbook/pandp-workbook-project-milestones-2.png',
      alt: 'Project milestones 2',
    },
    whoToInvolve: {
      src: imgFolder + '/workbook/pandp-workbook-who-to-involve.png',
      alt: 'Who to involve',
    },
  },
}

export const items = [
  { text: 'Introduction', href: urls.root },
  {
    text: '1: The Prep Work',
    href: urls.prepWork,
    img: images.linkPrepWork,
    alt: '1. Prep Work: Illustration of man holding books',
  },
  {
    text: '2: The Step Work',
    href: urls.stepWork,
    img: images.linkStepWork,
    alt: '2. Step Work: Illustration of woman pointing at whiteboard',
  },
  {
    text: '3: The Depth Work',
    href: urls.depthWork,
    img: images.linkDepthWork,
    alt: '3. Depth Work: Illustration of woman with light bulb above head',
  },
  {
    text: '4: The Yep Work',
    href: urls.yepWork,
    img: images.linkYepWork,
    alt: '4. Yep Work: Illustration of woman holding laptop',
  },
  {
    text: '5: The Pep Work',
    href: urls.pepWork,
    img: images.linkPepWork,
    alt: '5. Pep Work: Illustration of man with a pencil',
  },
  {
    text: '6: The Accept Work',
    href: urls.acceptWork,
    img: images.linkAcceptWork,
    alt: '6. Accept Work: Illustration of woman sitting with laptop',
  },
  { text: 'Conclusion', href: urls.conclusion },
]
