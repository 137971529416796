import React from 'react'
import { Layout } from '../../components'
import ContactSection from './ContactSection'
import FaqSection from './FaqSection'
import NewPricingSection from './NewPricingSection'

export default () => (
  <Layout
    title='Blissbook Employee Handbook and Policy Management Software Pricing'
    description='Discover how much Blissbook online employee handbook or policy management software costs (and start with a free trial).'
  >
    <NewPricingSection />
    <FaqSection />
    <ContactSection />
  </Layout>
)
