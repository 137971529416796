import React from 'react'

export default ({ main, side, id, rowClass, ...props }) => (
  <section
    {...props}
    css={{ position: 'relative', zIndex: 0, marginTop: '30px' }}
  >
    <If condition={id}>
      {/* biome-ignore lint/a11y/useAnchorContent: requires some work (div is onclick) */}
      <a name={id} />
    </If>
    <div>
      <div className={'row ' + (rowClass ? rowClass : '')}>
        <div className='col-md-8'>{main}</div>
        <div className='col-md-4'>{side}</div>
      </div>
    </div>
  </section>
)
