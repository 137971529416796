import { blogUrl } from '@blissbook/ui-config'
import { Link } from '@blissbook/ui/lib'
import React from 'react'
import {
  HubSpotForm,
  Layout,
  ResourceLink,
  ResourceSection,
  Typography,
} from '../components'

export default () => (
  <Layout
    title='HR, Policy Management and Employee Handbook News from Blissbook'
    description={`'Blissbook’s newsletter helps you become a better HR pro, leader, and person. Useful articles and resources to help you write better HR policies for employees.`}
  >
    <ResourceSection
      images={['/images/marketing/resources/newsletter.png']}
      imageFooter={
        <Typography
          variant='p2'
          className='text-center tw-mx-auto'
          style={{ maxWidth: 250 }}
        >
          Get more knowledge on{' '}
          <Link href={blogUrl} target='_blank'>
            our blog
          </Link>
          !
        </Typography>
      }
    >
      <img
        src='/images/marketing/Bugle.svg'
        className='tw-mb-10'
        alt='a bugle icon in blue and yellow'
      />

      <Typography variant='h4'>Blissbook Newsletter</Typography>

      <Typography variant='h1'>
        Tune in to what’s happening at Blissbook and with US labor laws.
      </Typography>

      <Typography variant='p2'>
        Get the latest from Blissbook delivered straight to your inbox. Stay
        informed with company news, product updates, and important{' '}
        <Link to='/employment-law-updates'>employment law changes</Link> — all
        designed to help you keep your HR policies current and your team ahead
        of the curve.
      </Typography>

      <HubSpotForm
        className='tw-mb-4'
        formId='4c2642c8-4a2d-49fe-9318-404a1d72cca4'
      />

      <ResourceLink
        to='/how-to-write-a-culture-first-employee-handbook'
        icon={['far', 'book-heart']}
      >
        Bonus Round! Get your mitts on our free eBook:{' '}
        <em>How to Write a Culture-First Employee Handbook</em>
      </ResourceLink>
    </ResourceSection>
  </Layout>
)
