import config from '@blissbook/ui-config'
import { colors, gridBreakpoints } from '@blissbook/ui/branding'
import { Collapse } from '@blissbook/ui/lib'
import { useScrollTop } from '@blissbook/ui/util/hooks'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { useState } from 'react'
const { assetsUrl = '', blogUrl } = config

const learnMoreNavItems = [
  {
    children: 'Solutions',
    to: '/employee-handbook-software',
    items: [
      {
        to: '/employee-handbook-software',
        children: 'Online employee handbook',
      },
      {
        to: '/enterprise-policy-management',
        children: 'Enterprise policy management',
      },
      {
        to: '/employee-handbook-hr-policy-content',
        children: 'HR policy content services',
      },
      {
        to: '/legal-compliance',
        children: 'Be audit-ready (SOC 2 or otherwise)',
      },
      {
        to: '/branded-employee-handbook',
        children: 'Branded employee handbook',
      },
      {
        to: '/employee-handbook-management',
        children: 'PEOs and agencies',
      },
    ],
  },
  {
    children: 'Capabilities',
    to: '/personalized-handbooks',
    items: [
      {
        to: '/personalized-handbooks',
        children: 'Personalized handbooks',
      },
      {
        to: '/policy-communication',
        children: 'Policy communication',
      },
      {
        to: '/manager-participation',
        children: 'Manager Participation',
      },
    ],
  },
  {
    to: '/information-security',
    children: 'IT & Security',
  },
  {
    to: '/customers',
    children: 'Customers',
  },
  {
    to: '/about',
    children: 'About Us',
  },
]

const learnMoreNav = {
  children: 'Learn More',
  // bubble: {
  //   to: '/about/work-here',
  //   children: 'Work Here',
  //   imageUrl: '/images/marketing/hiring-bubble.svg',
  // },
  items: learnMoreNavItems,
}

const pricingNav = {
  to: '/pricing',
  children: 'Pricing',
}

const resourcesNav = {
  children: 'Resources',
  items: [
    {
      href: blogUrl,
      children: 'Blog',
    },
    {
      to: '/request-a-demo',
      children: 'Request a Demo',
    },
    {
      to: '/employment-law-updates',
      children: 'Employment Law Updates',
    },
    {
      to: '/newsletter',
      children: 'Newsletter',
    },
    {
      to: '/how-to-write-policies-procedures',
      children: 'eBook: How to Write Policies & Procedures',
    },
    {
      to: '/how-to-write-a-culture-first-employee-handbook',
      children: 'eBook: How to Write a Culture-First Employee Handbook',
    },
    {
      to: '/awkward-hr-conversations',
      children: 'Awkward HR Conversations',
    },
  ],
}

const desktopNav = [learnMoreNav, pricingNav, resourcesNav]

const mobileNav = [...learnMoreNavItems, pricingNav, resourcesNav]

const NavBubbleMobile = ({ Link, children, to }) => (
  <Link
    className='nav-link lg:tw-px-2 lg:tw-py-4 tw-block lg:tw-hidden'
    to={to}
  >
    {children}
  </Link>
)

const NavBubbleDesktop = ({ Link, imageUrl, to }) => (
  <Link className='navbar-bubble tw-hidden lg:tw-block' to={to}>
    {/* biome-ignore lint/a11y/useAltText: if you see this line, try to fix it */}
    <img src={imageUrl} />
  </Link>
)

const NavBubble = (props) => (
  <>
    <NavBubbleMobile {...props} />
    <NavBubbleDesktop {...props} />
  </>
)

const NavDropdown = ({ Link, children, className, isOpen, ...props }) => (
  <Link {...props} className={classnames('nav-link', className)}>
    {children}
    <FontAwesomeIcon
      icon='chevron-down'
      className='tw-ml-2'
      css={{
        fontSize: 10,
        transition: 'transform linear 300ms',
      }}
      style={{
        transform: `rotate(${isOpen ? 180 : 0}deg)`,
      }}
    />
  </Link>
)

const NavItem = ({ Link, location, navItem }) => {
  const Component = navItem.items ? NavItemMenu : NavItemLink
  return (
    <Component
      className='lg:tw-mr-6'
      Link={Link}
      location={location}
      navItem={navItem}
    />
  )
}

const NavItemLink = ({ Link, className, location, navItem, ...props }) => {
  const { bubble, ...linkProps } = navItem
  return (
    <li
      {...props}
      className={classnames('nav-item', className, {
        active: isNavItemActive(navItem, location),
      })}
      css={{
        position: 'relative',
      }}
    >
      <Link className='nav-link lg:tw-px-2 lg:tw-py-4' {...linkProps} />

      <If condition={bubble}>
        <NavBubble {...bubble} />
      </If>
    </li>
  )
}

const SubLink = ({ Link, className, children, ...props }) => {
  return (
    <Link
      {...props}
      className={`${className} clearfix`}
      css={css`
        cursor: pointer;
      `}
    >
      <div className='tw-float-left'>{children}</div>
      <div className='tw-float-right'>
        <FontAwesomeIcon icon='chevron-right' className='tw-ml-2 text-right' />
      </div>
    </Link>
  )
}

const SubNavItemMenu = ({ Link, subItem, location }) => {
  const _subItemCss = { fontSize: 14 }
  const Component = subItem.items
    ? (props) => <SubLink {...props} Link={Link} />
    : Link
  return (
    <div
      css={
        // biome-ignore lint/style/noCommaOperator: if you see this line try to fix it
        (_subItemCss,
        css`
          @media (min-width: ${gridBreakpoints.lg}px) {
            &:hover .nav-link-sub {
              opacity: 1;
              pointer-events: all;
            }
          }
        `)
      }
    >
      <Component
        href={subItem.href}
        target={subItem.target}
        to={subItem.to}
        className={classnames('nav-link tw-px-4', {
          active: isNavItemActive(subItem, location),
        })}
      >
        {subItem.children}
      </Component>
      {subItem.items && (
        <div
          className='nav-link-sub lg:tw-py-2 box-shadow'
          css={css`
            position: absolute;
            margin-left: -5px;
            top: 0;
            left: 100%;
            width: 300px;
            min-height: 100%;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: ${colors['gray-100']};
            clip-path: inset(-30px -30px -30px 0px);

            opacity: 0;
            pointer-events: none;
            transition: all linear 100ms;
          `}
        >
          {subItem.items.map((subItem, index) => (
            <Link
              {...subItem}
              key={index}
              className={classnames('nav-link tw-px-4', {
                active: isNavItemActive(subItem, location),
              })}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const NavItemMenu = ({ Link, className, location, navItem, ...props }) => {
  const [isMobileOpen, setMobileOpen] = useState(false)
  const subItemCss = { fontSize: 14 }

  return (
    <li
      {...props}
      className={classnames(
        'tw-group tw-relative',
        className,
        navItem.className,
        { active: isNavItemActive(navItem, location) },
      )}
    >
      {/* Mobile */}
      <NavDropdown
        Link={Link}
        children={navItem.children}
        className='tw-flex tw-items-center tw-justify-between lg:tw-hidden lg:tw-py-4'
        isOpen={isMobileOpen}
        onClick={() => setMobileOpen(!isMobileOpen)}
      />
      <Collapse className='lg:tw-hidden tw-pl-4' isOpen={isMobileOpen}>
        {navItem.items.map((subItem, index) => (
          <Link
            {...subItem}
            key={index}
            className={classnames('nav-link', {
              active: isNavItemActive(subItem, location),
            })}
            css={subItemCss}
          />
        ))}
      </Collapse>
      <If condition={navItem.bubble}>
        <NavBubbleMobile {...navItem.bubble} Link={Link} />
      </If>

      {/* Desktop */}
      <div className='tw-hidden lg:tw-block'>
        <NavDropdown
          Link={Link}
          children={navItem.children}
          className='lg:tw-py-4'
        />

        <If condition={navItem.bubble}>
          <NavBubbleDesktop {...navItem.bubble} Link={Link} />
        </If>
      </div>
      <div
        className='tw-absolute tw-bg-white tw-hidden tw-left-0 tw-opacity-0 tw-pointer-events-none tw-top-full lg:group-hover:tw-opacity-100 lg:group-hover:tw-pointer-events-auto lg:tw-block lg:tw-py-2 box-shadow'
        css={css`
          border-radius: 5px;
          min-width: 230px;
          transition: all linear 100ms;
        `}
      >
        {navItem.items.map((subItem, index) => (
          <SubNavItemMenu
            Link={Link}
            key={index}
            subItem={subItem}
            location={location}
          />
        ))}
      </div>
    </li>
  )
}

// Determine if this navItem is active (if we know the location)
const isNavItemActive = (navItem, location) => {
  if (!location) return false
  const subItems = navItem.items || []
  const items = [navItem, ...subItems]
  return items.find((item) => item.to && item.to === location.pathname)
}

export const NavbarContainer = ({ Link, location, user }) => {
  // Properties for a signIn link
  const signInProps = {
    [user ? 'href' : 'to']: user ? '/home' : '/sign-in',
  }

  return (
    <div className='tw-flex tw-items-center container'>
      <Link
        className='navbar-brand lg:tw-px-2 tw-py-2 lg:-tw-ml-2 tw-mr-6'
        to='/'
        aria-label='Blissbook'
      >
        <img
          alt='Blissbook Logo'
          src={assetsUrl + '/images/marketing/Logo-OffBlack-Vector.svg'}
          className='tw-block'
          height={20}
          width={125}
        />
      </Link>

      <button
        className='navbar-toggler u-hamburger'
        type='button'
        data-toggle='collapse'
        data-target='#navbar-collapse'
        aria-controls='navbar-collapse'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='u-hamburger__box'>
          <span className='u-hamburger__inner' />
        </span>
      </button>

      <div
        className='collapse navbar-collapse tw-mt-2 lg:tw-mt-0'
        id='navbar-collapse'
      >
        <ul className='tw-hidden lg:tw-flex navbar-nav tw-mr-auto'>
          {desktopNav.map((navItem, index) => (
            <NavItem
              Link={Link}
              key={index}
              location={location}
              navItem={navItem}
            />
          ))}
        </ul>

        <ul className='lg:tw-hidden navbar-nav tw-mr-auto'>
          {mobileNav.map((navItem, index) => (
            <NavItem
              Link={Link}
              key={index}
              location={location}
              navItem={navItem}
            />
          ))}
        </ul>
      </div>

      <div className='tw-hidden lg:tw-flex tw-items-center'>
        <Link
          {...signInProps}
          style={{ fontWeight: 600, textDecoration: 'underline' }}
        >
          Sign In
        </Link>

        <If condition={!user}>
          <Link className='btn btn-sm btn-primary tw-ml-6' to='/request-a-demo'>
            Get Started
          </Link>
        </If>
      </div>
    </div>
  )
}

// Get the class for the navbar
export const getNavbarClass = (scrollTop) =>
  classnames('navbar navbar-light navbar-expand-lg fixed-top', {
    'navbar-collapsed': scrollTop > 120,
    'navbar-scrolled': scrollTop > 0,
  })

export default (props) => {
  const scrollTop = useScrollTop()
  return (
    <nav className={getNavbarClass(scrollTop)}>
      <NavbarContainer {...props} />
    </nav>
  )
}
