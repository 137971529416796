import { ContactLink, Link } from '@blissbook/ui/lib'
import React, { Fragment } from 'react'
import {
  Accordion,
  MaxWidthContainer,
  Section,
  Typography,
} from '../../components'

const faqs = [
  {
    question: 'What features are included?',
    answer: (
      <Fragment>
        Everything listed above, everything on the{' '}
        <Link to='/employee-handbook-software'>product page</Link>, and more. If
        you can't find what you need, have questions, or want a demo,{' '}
        <Link to='/request-a-demo'>contact us</Link>.
      </Fragment>
    ),
    footnote:
      'Some integrations or other custom requests may require additional fees.',
  },
  {
    question: 'Can you design my handbook?',
    answer: (
      <Fragment>
        Yes! Send us your content and branding guidelines and we'll create a
        beautiful custom Blissbook just for you!{' '}
        <a href='/request-a-demo'>Contact us</a> for details and pricing.
      </Fragment>
    ),
  },
  {
    question: 'How many handbooks can I have?',
    answer: `As many as you want. Pricing is based on the total number of people all your handbooks are shared with. Archived (termed) people don't count and there's no extra cost for Admins.`,
  },
  {
    question: 'Can you provide legal content?',
    answer: (
      <Fragment>
        Yes! New or revised handbooks are $2500. Read more about our{' '}
        <Link to='/employee-handbook-hr-policy-content'>
          handbook builder services
        </Link>{' '}
        or <ContactLink>contact us</ContactLink> for more info.
      </Fragment>
    ),
  },
  {
    question: 'Can I update policies anytime?',
    answer:
      'Of course! You can edit and publish new or updated policies any time and there’s no limit to the number of handbook or policy versions you can have.',
  },
  {
    question: 'Do you offer copywriting services?',
    answer: (
      <Fragment>
        Yes. We can edit your existing content or write new content to make your
        handbook more approachable and engaging for non-lawyer humans.{' '}
        <Link to='/request-a-demo'>Contact us</Link> for details and pricing.
      </Fragment>
    ),
  },
  {
    question: 'Is there a free trial?',
    answer: (
      <Fragment>
        Yes! <Link to='/request-a-demo'>Contact us</Link> and we'll set you up.
      </Fragment>
    ),
  },
  {
    question: 'What if I need help?',
    answer: (
      <Fragment>
        The Blissbook team is available by email,{' '}
        <ContactLink>chat</ContactLink>, or phone throughout the life of your
        Blissbook account. Support is during normal business hours (M-F 9-6 ET)
        but you'll often get quick responses outside of that timeframe.
      </Fragment>
    ),
  },
  {
    id: 'employee-seat',
    question: `What's an Employee Seat?`,
    answer: (
      <Fragment>
        An Employee Seat is a credit for access to your Blissbook handbooks for
        one person for one year. You purchase Employee Seats up front based on
        the maximum number of employees you think you'll have in the upcoming
        year. For more information on pricing,{' '}
        <Link to='/request-a-demo'>contact us</Link>.
      </Fragment>
    ),
  },
  {
    question: 'How do employees view handbooks?',
    answer: `Employees can view your handbook using a modern web browser on any device, 24/7, with 99.9% uptime. There's nothing for users to install or download.`,
  },
  {
    question: 'What if my company grows?',
    answer: (
      <>
        Employee Seats are purchased in tiers. If you grow into the next tier,
        Blissbook will automatically upgrade your subscription.
      </>
    ),
  },
  {
    question: 'Who owns the data I upload?',
    answer: `You own your handbook content and documentation data and you'll always have access to it even if you cancel your subscription.`,
  },
  {
    question: 'Can you work with my IT team?',
    answer: (
      <Fragment>
        Yes! Most HRIS and SSO integrations require coordination between the
        Blissbook team and your IT team. Check out our{' '}
        <Link to='/information-security'>IT solutions page</Link> for more
        details or <ContactLink>contact us</ContactLink>.
      </Fragment>
    ),
  },
  {
    question: 'Do all employees need an email address?',
    answer: (
      <Fragment>
        No. Employees without an email address on file may view and sign their
        private handbook using their employee ID through your SSO integration.{' '}
        <Link to='/request-a-demo'>Contact us</Link> for more information.
      </Fragment>
    ),
  },
]

export default () => {
  const faqAccordions = faqs.map((faq, index) => (
    <Accordion key={index} id={faq.id} title={faq.question}>
      {faq.answer}

      <If condition={faq.footnote}>
        <br />
        <br />
        <small>*{faq.footnote}</small>
      </If>
    </Accordion>
  ))

  return (
    <Section id='frequently-asked-questions' className='container py-lg'>
      <MaxWidthContainer className='tw-mx-auto sm:tw-pb-1 md:tw-pb-0' xs={800}>
        <Typography
          variant='h1'
          className='tw-mx-auto text-center tw-break-words'
        >
          Implementation services to <br /> optimize your launch.{' '}
        </Typography>
        <Typography variant='p2' className='text-center'>
          Blissbook offers comprehensive implementation services designed to
          optimize your handbook launch process. Our expert team will guide you
          through every step,{' '}
          <strong>from initial setup to full deployment,</strong> ensuring a
          smooth transition that aligns with your organization’s unique needs.
          With our support, you'll be able to{' '}
          <strong>customize your handbook, integrate key features,</strong> and
          empower your team to adopt the platform with ease, minimizing downtime
          and maximizing the impact of your launch. Let Blissbook streamline
          your onboarding process for a smooth and successful rollout.
        </Typography>
      </MaxWidthContainer>

      <MaxWidthContainer className='tw-mx-auto' xs={960}>
        <Typography variant='h1' className='my-sm'>
          Frequently
          <br />
          Asked
          <br />
          Questions
        </Typography>

        <div className='lg:tw-hidden'>{faqAccordions}</div>

        <div className='tw-hidden lg:tw-grid tw-grid-cols-2-auto tw-gap-x-12 tw-mx-auto'>
          {[0, 1].map((colIndex) => (
            <div key={colIndex}>
              {faqAccordions.filter((_item, index) => index % 2 === colIndex)}
            </div>
          ))}
        </div>

        <Typography variant='p2' className='tw-text-center tw-mt-6'>
          More questions? Check out our{' '}
          <Link href='https://help.blissbook.com'>help center</Link> or{' '}
          <Link to='/request-a-demo'>contact us</Link> directly.
        </Typography>
      </MaxWidthContainer>
    </Section>
  )
}
