import { Link, MailtoSupportLink } from '@blissbook/ui/lib'
import React from 'react'
import {
  HeadingDivider,
  LegalHeader,
  LegalIndent,
  LegalLayout,
} from '../components'

export default () => (
  <LegalLayout
    title='Blissbook Privacy Shield'
    description={
      'Blissbook complies with the EU-U.S. Privacy Shield Framework and the Swiss–U.S. Privacy Shield Framework as set forth by the Department of Commerce. Blissbook is also compliant with all GDPR requirements.'
    }
  >
    <LegalHeader
      heading='Privacy Shield'
      lastUpdated='May 5, 2020'
      title='EU-U.S. and Swiss-U.S. Privacy Shield Notice'
    />

    <HeadingDivider variant='h2'>Introduction</HeadingDivider>
    <p>
      Rocket Whale Products, LLC (“Blissbook”, “we”, “our”, or “us”) commits to
      conduct its business according to the EU -U.S. Privacy Shield framework
      and the Swiss – U.S. Privacy Shield Framework (the “Privacy Shield”) which
      became effective August 1, 2016 for the EU and January 12, 2017 for
      Switzerland. The Privacy Shield was developed by the U.S. Department of
      Commerce (“DOC”), the European Commission (the “EC”) and the Swiss
      Administration to provide companies based in the United States (U.S.), the
      European Union (EU) or Switzerland a mechanism to transfer data on
      European nationals to the U.S. and provide such European nationals the
      data protection available in the EU and Switzerland, in order to support
      transatlantic commerce. The EC and the Swiss Administration deem the
      Privacy Shield adequate to enable data transfers under EU and Swiss law.
    </p>

    <p>
      Blissbook complies with the EU-U.S. Privacy Shield Framework and the Swiss
      – U.S. Privacy Shield Framework as set forth by the DOC regarding the
      collection, use, and retention of personal information transferred from
      the European Union and Switzerland to the United States. Blissbook has
      certified to the DOC that it adheres to the Privacy Shield Principles (the
      “Principles”). If there is any conflict between the terms in this Privacy
      Shield Notice and the Principles, the Principles will govern. To learn
      more about the Privacy Shield program, and to view Blissbook’s
      certification, please visit{' '}
      <a
        href='https://www.privacyshield.gov/welcome'
        rel='noopener noreferrer'
        target='_blank'
      >
        https://www.privacyshield.gov/welcome
      </a>
      .
    </p>

    <p>
      The following describes Blissbook’s privacy practices as they relate to
      information about European and Swiss nationals that it obtains from
      Blissbook’s Customers, Contacts and Visitors. Capitalized terms used but
      not defined in this Privacy Shield Notice have the meanings given to them
      in the{' '}
      <Link to='/privacy-policy' title='Blissbook Privacy Policy'>
        Blissbook Privacy Policy
      </Link>
      , of which this Privacy Shield Notice forms a part and whose terms are
      incorporated herein.
    </p>

    <HeadingDivider variant='h2'>Notice</HeadingDivider>
    <p>
      Blissbook collects Personal Information about Contacts who are European
      and Swiss nationals after they provide their consent to their employers or
      other entities (i.e., Blissbook’s Customers) to permit Blissbook to
      receive the information directly from those Customers.
    </p>
    <p>
      Blissbook contractually requires Customers to obtain explicit
      authorization from Contacts in order for Blissbook to process the Personal
      Information of those Contacts, including by disclosing Personal
      Information to third parties.
    </p>
    <p>
      The types of Personal Information that may be collected are described in
      greater detail in the{' '}
      <Link to='/privacy-policy' title='Blissbook Privacy Policy'>
        Blissbook Privacy Policy
      </Link>{' '}
      and include a Customer’s or Contact’s registration information, billing
      and communications information, Employee Lists, device information, and
      location.
    </p>
    <p>
      Blissbook discloses Personal Information to Customers, hosting services
      providers, customer support providers, customer relationship management
      providers, and marketing automation service providers in order to provide
      the Services and to perform its contracts with its Customers.
    </p>
    <p>
      Any Personal Information collected is only disclosed in accordance with
      the{' '}
      <Link to='/privacy-policy' title='Blissbook Privacy Policy'>
        Blissbook Privacy Policy
      </Link>{' '}
      and pursuant to the consent of the Contact. Blissbook does not use
      Personal Information for any purpose other than those described in the{' '}
      <Link to='/privacy-policy' title='Blissbook Privacy Policy'>
        Blissbook Privacy Policy
      </Link>
      , those for which it was originally collected and authorized by the
      Contact, and for the support and maintenance of the Services. Blissbook
      may anonymize Personal Information for future use in a way that it cannot
      be linked to any identifiable individual.
    </p>
    <p>
      Notwithstanding the above, Blissbook may disclose Personal Information in
      response to lawful requests by public authorities, including to meet
      national security or law enforcement requirements.
    </p>

    <HeadingDivider variant='h2'>Choice</HeadingDivider>
    <p>
      As noted above, Blissbook does not collect any Personal Information
      without contractually requiring each Customer to obtain consent from the
      relevant Contact for Blissbook to process his or her Personal Information.
      Personal Information is never disclosed to a third party except for the
      purposes disclosed in this Privacy Shield Notice and the{' '}
      <Link to='/privacy-policy' title='Blissbook Privacy Policy'>
        Blissbook Privacy Policy
      </Link>
      . Personal Information is not reused or disclosed by Blissbook for any
      other purpose. Contacts should contact the relevant Customer directly if
      he or she wants to discontinue Blissbook’s access to his or her Personal
      Information, but may also contact Blissbook at any time by submitting a
      request to <MailtoSupportLink />.
    </p>

    <HeadingDivider variant='h2'>
      Accountability for Onward Transfer
    </HeadingDivider>
    <p>
      In cases of onward transfer to third parties of Personal Information
      received pursuant to the EU-U.S. Privacy Shield and the Swiss-U.S. Privacy
      Shield, Blissbook is potentially liable.
    </p>
    <p>
      Personal Information will be transferred from the EU or Switzerland to
      Blissbook in the United States where it will be processed by a processor
      under the direction of Blissbook. The Personal Information will be
      transferred only for limited and authorized specified purposes as agreed
      to by the applicable Customer. Blissbook will take reasonable and
      appropriate steps to ensure that its processor effectively processes the
      Personal Information transferred in a manner that is consistent with
      Blissbook’s obligations under the Privacy Shield.
    </p>
    <p>
      Any transfer by Blissbook of a Contact’s Personal Information to a
      Processor in the EU and Switzerland will be pursuant to that Contact’s
      consent to processing which Blissbook contractually requires the relevant
      Customer to obtain.
    </p>
    <p>
      The transfer of Personal Information will be only for limited and
      specified purposes. Blissbook’s processor will be obligated to provide at
      least the same level of data protection as is required by the Principles,
      and Blissbook will take steps to assure that the its processor effectively
      processes the Personal Information transferred in a manner consistent with
      Blissbook’s obligations under the Principles. The processor will be
      required to notify Blissbook if it makes a determination that it can no
      longer meet its obligation to provide the same level of protection as is
      required by the Principles, and upon notice, Blissbook will take
      reasonable and appropriate steps to stop and remediate unauthorized
      processing.
    </p>

    <HeadingDivider variant='h2'>Security</HeadingDivider>
    <p>
      Blissbook and its processor maintain a comprehensive information security
      program designed to anticipate foreseeable threats or hazards for attacks,
      intrusions, unauthorized access, system failures, alteration, destruction,
      or breach of confidentiality through (a) using administrative, technical,
      and physical safeguards (“Safeguards”); (b) reasonably designing,
      periodically reviewing, regularly testing, monitoring, and risk assessing
      the Safeguards; and (c) modifying and upgrading systems, system controls,
      procedures (including training of employees and management).
    </p>

    <HeadingDivider variant='h2'>Access</HeadingDivider>
    <p>
      Blissbook obtains Personal Information from its Customers and their
      Contacts and transfers it to its Customers and subprocessors in the EU and
      Switzerland. Blissbook retains Personal Information only for purposes of
      providing and maintaining Services for the Customer. As further described
      in the{' '}
      <Link to='/privacy-policy' title='Blissbook Privacy Policy'>
        Blissbook Privacy Policy
      </Link>
      , for much of the Personal Information Blissbook processes about Contacts
      through the Services, Blissbook acts as a processor on behalf of
      Customers. In such cases, if a Contact wants to exercise any data
      protection rights that may be available under applicable law or have
      questions or concerns about how his or her Personal Information is handled
      by Blissbook as a processor on behalf of individual Customers, the Contact
      should contact the relevant Customer that is using the Blissbook Services,
      and refer to their separate privacy policies. Similarly, if a Contact
      wants to be contacted by the relevant Customer through Blissbook’s
      Services, the Contact should contact the Customer directly to update or
      delete the Contact’s Personal Information.
    </p>
    <p>
      If a Contact contacts Blissbook directly with a request to exercise his or
      her data protection rights, Blissbook will respond in accordance with
      applicable data protection laws and after providing notice of the request
      to the relevant Customer. Blissbook may ask the Contact to verify his or
      her identity in order to facilitate an efficient response to the request.
    </p>

    <HeadingDivider variant='h2'>
      Changes in Blissbook Privacy Shield Notice
    </HeadingDivider>
    <p>
      If we decide to change this Privacy Shield Notice, we will post the
      changes at this site and at other places we deem appropriate. We reserve
      the right to modify this Privacy Shield Notice at any time, so please
      review it frequently.
    </p>

    <HeadingDivider variant='h2'>Contact Us</HeadingDivider>
    <p>
      Customers with complaints about the collection or use of their Contacts’
      Personal Information should contact:
    </p>
    <LegalIndent>
      <p>
        <strong>Privacy Shield Ombudsman</strong>
        <br />
        <MailtoSupportLink />
      </p>
    </LegalIndent>
    <p>
      If their dispute is not resolved satisfactorily, they (or their Contacts)
      may submit it for mediation to:{' '}
      <a
        href='https://www.jamsadr.com/eu-us-privacy-shield'
        rel='noopener noreferrer'
        target='_blank'
      >
        https://www.jamsadr.com/eu-us-privacy-shield
      </a>
      , an alternative dispute resolution provider located in the United States.
      The services of JAMS are provided at no cost to the Contact.
    </p>
    <p>
      Blissbook is subject to regulation by the Federal Trade Commission (the
      “FTC”), and its investigatory and enforcement powers. Under certain
      circumstances, Contacts may submit their complaint to binding arbitration,
      see:{' '}
      <a
        href='https://www.privacyshield.gov/article?id=ANNEX-I-introduction'
        rel='noopener noreferrer'
        target='_blank'
      >
        https://www.privacyshield.gov/article?id=ANNEX-I-introduction
      </a>
      .
    </p>

    <HeadingDivider variant='h2'>Human Resources (HR) Data</HeadingDivider>
    <p>
      Blissbook commits to cooperate with the panel established by the EU data
      protection authorities (DPAs) and/or the Swiss Federal Data Protection and
      Information Commissioner, as applicable and comply with the advice given
      by such panel and/or Commissioner, as applicable, with regard to human
      resources data transferred from the EU and/or Switzerland, as applicable,
      in the context of the employment relationship.
    </p>
  </LegalLayout>
)
