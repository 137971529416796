import { colors } from '@blissbook/ui/branding'
import { css, keyframes } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import times from 'lodash/times'
import React, { Fragment } from 'react'
import {
  DemoDesktopBrowser,
  DemoHandbook,
  DemoMobileBrowser,
  DemoTextBlock,
  DemoVideo,
  demoColors,
} from './components'

const customUrl = 'https://handbook.yourdomain.com'
const scrollDownDuration = 2000
const scrollUpDuration = 1000
const showCustomUrlDuration = 200
const showHandbookDuration = 1000
const showMobileDuration = 2000
const theme = 'branded'

const mobileEmailKeyframes = keyframes`
  from { opacity: 0; transform: scale(1); }
  25% { opacity: 1; transform: scale(1.1); }
  50% { opacity: 1; transform: scale(1.2); }
  to { opacity: 1; transform: scale(1); }
`

const mobileEmail = {
  to: 'Employee',
  from: 'Your Company',
  subj: 'Sign Your HB!',
}

const mobileEmailText = [
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '50%' },
  { variant: 'h1', width: '75%', color: colors['red-300'] },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '50%' },
  { variant: 'p' },
  { variant: 'p' },
  { variant: 'p', width: '20%' },
  { variant: 'p' },
  { variant: 'p', width: '95%' },
  { variant: 'p', width: '92%' },
  { variant: 'p', width: '70%' },
  { variant: 'p' },
  { variant: 'p', width: '97%' },
  { variant: 'p', width: '45%' },
]

const Component = ({ isAnimationComplete, keyframe }) => (
  <Fragment>
    <div
      className='tw-px-2 tw-py-1 box-shadow'
      css={css`
        position: absolute;
        top: 36px;
        left: 100px;

        background: white;
        color: ${colors.black};
        opacity: ${keyframe.showCustomUrl ? 1 : 0};
        transition: all ${showCustomUrlDuration}ms ease;
        z-index: 10;

        &:before {
          content: '';
          position: absolute;
          bottom: 100%;
          height: 0;
          left: 20px;
          width: 0;

          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid white;
        }
      `}
    >
      {times(customUrl.length, (index) => (
        <span
          key={index}
          children={customUrl[index]}
          css={{
            display: 'inline-block',
            opacity: index <= keyframe.customUrlIndex ? 1 : 0,
            transform: `scale(${index <= keyframe.customUrlIndex ? 1 : 3})`,
            transition: 'all 100ms linear',
          }}
        />
      ))}
    </div>

    <div className='tw-flex tw-items-end'>
      <DemoDesktopBrowser disabled={isAnimationComplete} height={400}>
        <DemoHandbook
          css={{
            marginTop: -keyframe.scrollTop,
            opacity: keyframe.showHandbook ? 1 : 0,
            transition: `margin ${keyframe.scrollDuration}ms ease, opacity ${showHandbookDuration}ms ease`,
          }}
          sections={[
            { type: 'chapter' },
            { type: 'letter', video: <DemoVideo theme={theme} /> },
            { type: 'chapter' },
            { type: 'text' },
          ]}
          theme={theme}
        />
      </DemoDesktopBrowser>

      <DemoMobileBrowser
        css={{
          animation: keyframe.showMobile
            ? `${mobileEmailKeyframes} ${showMobileDuration}ms linear 1 forwards`
            : undefined,
          marginBottom: -40,
          marginLeft: -140,
          opacity: 0,
        }}
        disabled={isAnimationComplete}
        height={260}
        width={160}
      >
        <div className='tw-px-4 tw-my-4'>
          <FontAwesomeIcon
            icon='envelope'
            css={{ color: colors['red-300'], fontSize: 24 }}
          />

          {Object.keys(mobileEmail).map((key) => (
            <div key={key} className='tw-flex tw-items-center'>
              <div
                children={key}
                css={{
                  color: demoColors.gray,
                  fontSize: 9,
                  textTransform: 'uppercase',
                  width: 36,
                }}
              />
              <div
                children={mobileEmail[key]}
                css={{
                  flex: 1,
                  fontSize: 12,
                }}
              />
            </div>
          ))}
        </div>

        <div
          children={<DemoTextBlock items={mobileEmailText} />}
          className='tw-px-8 tw-my-4'
          css={{
            transform: 'scale(0.5)',
            transformOrigin: 'top left',
            width: '200%',
          }}
        />
      </DemoMobileBrowser>
    </div>
  </Fragment>
)

export default {
  id: 'trusted',
  title: 'Trusted & Consistent',
  description:
    'Employees trust Blissbook because everything looks and feels like it’s coming from you and your brand. Use a custom domain & customize branding on both your handbook and emails.',
  Component,

  keyframes: [
    {
      duration: 2000,
      scrollDuration: 0,
      scrollTop: 0,
    },
    {
      duration: showCustomUrlDuration,
      showCustomUrl: true,
    },
    ...times(customUrl.length, (index) => ({
      duration: Math.max(160 - index * 20, 20),
      customUrlIndex: index,
    })),
    {
      duration: 1750,
    },
    {
      duration: showHandbookDuration + 200,
      showCustomUrl: false,
      showHandbook: true,
    },
    {
      duration: scrollDownDuration,
      scrollDuration: scrollDownDuration,
      scrollTop: 500,
    },
    {
      duration: scrollUpDuration + 1000,
      scrollDuration: scrollUpDuration,
      scrollTop: 0,
    },
    {
      duration: showMobileDuration,
      showMobile: true,
    },
  ],
}
