import {
  faCog,
  faComments,
  faEdit,
  faEllipsisH,
  faEnvelope,
  faFileLines,
  faPaperPlane,
  faPlug,
  faSignature,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'

interface Feature {
  icon: any
  name: string
  description: string
}

const baseFeatures: Feature[] = [
  {
    icon: faFileLines,
    name: 'Manage Policies',
    description: 'Import, edit, organize, and manage policies.',
  },
  {
    icon: faEdit,
    name: 'Create',
    description: 'Create and customize your handbook and policies.',
  },
  {
    icon: faUsers,
    name: 'Collaborate',
    description: 'Work together with your team on policies.',
  },
  {
    icon: faPaperPlane,
    name: 'Distribute',
    description: 'Easily distribute your handbook to employees.',
  },
  {
    icon: faEnvelope,
    name: 'Email',
    description: 'Send notifications and reminders via email.',
  },
  {
    icon: faSignature,
    name: 'Collect Signatures',
    description: 'Gather and track e-signatures on policies.',
  },
  {
    icon: faPlug,
    name: 'Integrate',
    description: 'Connect with your existing HR systems.',
  },
  {
    icon: faComments,
    name: 'Get Help',
    description: 'Access customer support when you need it.',
  },
]

const proFeatures: Feature[] = [
  {
    ...baseFeatures[0],
    description:
      'Import, edit, organize, and manage policies. Automated version and audit trails and approval workflows.',
  },
  ...baseFeatures.slice(1, 6),
  {
    icon: faUsers,
    name: 'Advanced Collaboration',
    description: 'Enhanced team collaboration with granular permissions.',
  },
  {
    icon: faSignature,
    name: 'Advanced Signatures',
    description: 'Advanced signature workflows and tracking.',
  },
  {
    icon: faPlug,
    name: 'Advanced Integrations',
    description: 'Connect with more systems and access advanced API features.',
  },
  {
    icon: faComments,
    name: 'Priority Support',
    description: 'Get priority access to our customer support team.',
  },
]

const customFeatures: Feature[] = [
  ...proFeatures,
  {
    icon: faCog,
    name: 'Custom Features',
    description: 'Tailor-made features to meet your specific needs.',
  },
  {
    icon: faUsers,
    name: 'Dedicated Account Manager',
    description: 'Get a dedicated account manager for personalized support.',
  },
]

interface WhatsIncludedProps {
  selectedPlan: 'base' | 'pro' | 'custom'
}

const WhatsIncluded: React.FC<WhatsIncludedProps> = ({ selectedPlan }) => {
  const [features, setFeatures] = useState<Feature[]>(baseFeatures)
  const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null)
  const [showAllFeatures, setShowAllFeatures] = useState(false)
  const [animate, setAnimate] = useState(false)
  const [previousFeature, setPreviousFeature] = useState<Feature | null>(null)

  useEffect(() => {
    switch (selectedPlan) {
      case 'pro':
        setFeatures(proFeatures)
        break
      case 'custom':
        setFeatures(customFeatures)
        break
      default:
        setFeatures(baseFeatures)
    }
    setSelectedFeature(features[0])
    setShowAllFeatures(false)
  }, [selectedPlan, features])

  if (!selectedFeature) return null

  const displayedFeatures = showAllFeatures ? features : features.slice(0, 8)

  const handleFeatureSelection = (feature: Feature) => {
    setAnimate(true)
    setPreviousFeature(selectedFeature)
    setTimeout(() => {
      setAnimate(false)
      setSelectedFeature(feature)
      setPreviousFeature(null)
    }, 300)
  }

  return (
    <div className='tw-bg-blurple-700 tw-text-white tw-my-24'>
      <h2 className='tw-text-6xl tw-font-semibold tw-text-center tw-mb-18'>
        What's Included?
      </h2>
      <div className='tw-overflow-x-auto tw-mb-8 tw-grid tw-justify-items-center'>
        <div className='tw-flex tw-gap-4 tw-justify-start tw-items-start tw-mb-8 tw-min-w-max tw-px-4'>
          {displayedFeatures.map((feature) => (
            // biome-ignore lint/a11y/useButtonType: <explanation>
            <button
              key={feature.name}
              onClick={() => handleFeatureSelection(feature)}
              className='tw-gap-y-[10px] tw-flex tw-flex-col tw-items-center tw-w-24'
            >
              <FontAwesomeIcon
                className={`tw-h-6 tw-w-6 tw-p-4 tw-rounded-full tw-transition-all ${
                  selectedFeature.name === feature.name
                    ? 'tw-border-[3px] tw-border-white tw-text-white tw-bg-[#4E4ED8]'
                    : 'tw-text-[#DBDAF7] tw-border-[3px] tw-border-[#6B6BA7] tw-bg-[#6B6BA7]'
                }`}
                icon={feature.icon}
                size='lg'
              />
              <div
                className={`tw-text-xs tw-mt-2 tw-max-w-20 tw-break-words tw-text-center ${
                  selectedFeature.name === feature.name
                    ? 'tw-text-white'
                    : 'tw-text-[#DBDAF7]'
                }`}
              >
                {feature.name}
              </div>
            </button>
          ))}
          {features.length > 8 && !showAllFeatures && (
            // biome-ignore lint/a11y/useButtonType: <explanation>
            <button
              onClick={() => setShowAllFeatures(true)}
              className='tw-gap-y-[10px] tw-flex tw-flex-col tw-items-center tw-w-24'
            >
              <FontAwesomeIcon
                className='tw-h-6 tw-w-6 tw-p-4 tw-rounded-full tw-transition-all tw-text-[#DBDAF7] tw-border-[3px] tw-border-[#6B6BA7] tw-bg-[#6B6BA7]'
                icon={faEllipsisH}
                size='lg'
              />
              <div className='tw-text-xs tw-mt-2 tw-max-w-20 tw-break-words tw-text-center tw-text-[#DBDAF7]'>
                More
              </div>
            </button>
          )}
        </div>
      </div>
      <div className='tw-bg-white tw-text-blurple-700 tw-rounded-lg tw-p-8 tw-max-w-4xl tw-mx-auto tw-shadow-lg tw-grid sm:tw-grid-cols-[1fr] md:tw-grid-cols-[auto_1fr] tw-gap-x-7 tw-border-[3px] tw-border-blurple-500 tw-items-center'>
        <h3
          className={`tw-text-lg tw-font-semibold sm:tw-mb-2 md:tw-mb-0 tw-grid tw-content-center tw-text-gray-800 tw-transition-all tw-duration-300 ${
            animate
              ? 'tw-opacity-0 tw-translate-y-2'
              : 'tw-opacity-100 tw-translate-y-0'
          }`}
        >
          {animate ? previousFeature?.name : selectedFeature?.name}
        </h3>
        <p
          className={`tw-text-md tw-text-gray-700 tw-mb-0 tw-leading-relaxed tw-transition-all tw-duration-300 ${
            animate
              ? 'tw-opacity-0 tw-translate-y-2'
              : 'tw-opacity-100 tw-translate-y-0'
          }`}
        >
          {animate
            ? previousFeature?.description
            : selectedFeature?.description}
        </p>
      </div>
      <div className='tw-text-center tw-mt-12'>
        <a
          href='#'
          className='tw-text-aqua-300 tw-underline tw-underline-offset-4 tw-text-lg tw-leading-[30px] tw-tracking-[1px]'
        >
          View all features in detail, including a comparison chart
        </a>
      </div>
    </div>
  )
}

export default WhatsIncluded
