import { blogUrl } from '@blissbook/ui-config'
import { gridBreakpoints } from '@blissbook/ui/branding'
import { Col, Link, Row } from '@blissbook/ui/lib'
import { css } from '@emotion/react'
import React from 'react'
import { DotsBorder, Section, Typography } from '../../components'

export default () => (
  <Section id='our-mission' className='container'>
    <DotsBorder className='tw-px-6 lg:tw-px-18 tw-py-6 lg:tw-py-14'>
      <Row>
        <Col
          className='tw-flex tw-flex-col tw-justify-between'
          css={css`
            @media (min-width: ${gridBreakpoints.lg}px) {
              > * {
                max-width: 360px;
              }
            }
          `}
          xs={12}
          lg={6}
        >
          <div>
            <img
              alt='Bicycle'
              src='/images/marketing/about/Bicycle.svg'
              className='tw-mb-6'
            />

            <Typography variant='h3' className='tw-text-blurple-500 tw-mb-6'>
              Our Mission
            </Typography>

            <Typography variant='headline' className='tw-mb-6'>
              <em>Inspired</em> employees.
            </Typography>
          </div>

          <Typography
            variant='p2'
            className='lg:tw-mb-0'
            style={{ fontSize: 18, fontFamily: 'Georgia,serif' }}
          >
            <em>
              <strong>Inspired:</strong> to be filled with the urge or ability
              to do or feel something.
            </em>
          </Typography>
        </Col>
        <Col xs={12} lg={6}>
          <Typography variant='p2'>
            For an employee, inspiration could come from a clearly communicated
            “why” from leadership. Or from a memorable onboarding experience. It
            could even be as simple as having a not-crappy (dare we say fun?)
            experience dealing with HR policies - an experience that, if done
            right, makes an employee feel valued as a person.
          </Typography>

          <Typography variant='p2'>
            People spend over a third of their lives on the job during their
            working years. If Blissbook’s dent in the universe is helping people
            feel more inspired and valued in that job (even if it’s through
            something as modest as an employee handbook), then we’ll consider
            ourselves successful.
          </Typography>

          <Typography variant='p2' className='tw-mb-0'>
            Read more about our mission{' '}
            <Link
              href={blogUrl + '/a-new-blissbook-brand/'}
              className='-underline'
              target='_blank'
            >
              here
            </Link>
            .
          </Typography>
        </Col>
      </Row>
    </DotsBorder>
  </Section>
)
