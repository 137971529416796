import { colors } from '@blissbook/ui/branding'
import { Link } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment } from 'react'

import { Layout, Section, Typography } from '../components'

import { TOCList } from './TOC'
import {
  CaseStudy,
  ChapterHeader,
  ExternalLink,
  HowToSection,
  PersonCard,
  QuoteBordered,
} from './components'

import { images, urls } from './howToConfig'

import './styles/how-to-ebook.scss'

export default () => (
  <Layout
    title='How to Write Policies and Procedures: The Depth Work'
    description={
      'Guide and sample template for writing HR policies and procedures. Includes an outline of how to write a new policy or procedure.'
    }
    className='how-to-ebook'
  >
    <ChapterHeader
      title='3: The Depth Work'
      subtitle='Drafting Policies and Procedures'
      prev={{ url: urls.stepWork, text: '2. The Step Work' }}
      next={{ url: urls.yepWork, text: '4. The Yep Work' }}
      img={images.headerDepthWork.src}
    />

    <HowToSection
      className='container tw-mt-14'
      main={
        <Fragment>
          <Typography variant='p2'>
            Remember, for the sake of efficiency, seek attorney feedback on your
            policies early in the process. This will help you prioritize your
            work, starting with the most critical policy changes so you can
            initiate the feedback process while you work on more minor tweaks.
          </Typography>
          <Typography variant='h3' className='dark'>
            When you write a new policy or procedure, follow this general
            template:
          </Typography>
          <ol className='p2'>
            <li>
              Identify the key information the policy or procedure must convey.
            </li>
            <li>Consider why the policy or procedure was established.</li>
            <li>
              Customize the content from steps 1 and 2 so that it is
              well-organized and reflects your brand.
            </li>
            <li>
              Review the text, looking for ways to make it friendlier to your
              audience (i.e. why does the policy or procedure improve the
              workplace?).
            </li>
          </ol>
          <Typography variant='p2'>
            By communicating the value behind a policy, you empower employees to
            understand the need for the policy and its ultimate benefit. Cynthia
            Gore did this when she communicated her company’s work-from-home
            policy.
          </Typography>
        </Fragment>
      }
      side={<TOCList activeIndex={3} />}
    />

    <HowToSection
      className='container'
      main={
        <QuoteBordered
          quote='Having an up-to-date handbook can serve as a valuable defense in a lawsuit. It’s really important to have written a policy and that it’s clear what that policy is.'
          attribution={{
            name: 'Cynthia Gore',
            title: (
              <span>
                Head of HR Operations and Talent Acquisition at{' '}
                <ExternalLink href='https://www.mbusa.com'>
                  Mercedes-Benz USA
                </ExternalLink>
              </span>
            ),
          }}
        />
      }
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h2'>How to Write a Policy</Typography>
          <Typography variant='p2'>
            Now, that you’re ready to jump in with both feet (or both pens),
            here are some tips to keep in mind when writing policies:
          </Typography>
          <ul className='p2'>
            <li>
              Be specific and provide all necessary details for the policy.
            </li>
            <li>
              If you’re writing a policy that's required by law, do your
              employees a favor and translate the legalese into conversational
              language.
            </li>
            <li>
              Determine if there are any exceptions, limitations, or
              restrictions that need coverage. If so, list those after the
              policy.
            </li>
            <li>
              Avoid ambiguous language. If a policy is prohibitive, then avoid
              verbs like “may” or “should.” These words imply that the employee
              has a choice in following the policy. At best, your employees will
              probably skim the policies, so be as clear and concise as
              possible.
            </li>
            <li>
              <ExternalLink href='https://writingcooperative.com/edit-your-work-by-reading-it-backwards-5092e74fead6'>
                Read your content backwards
              </ExternalLink>{' '}
              or out loud and look for words that can be deleted. Brevity
              improves clarity.
            </li>
            <li>
              Use simple sentences in standard structure (subject + verb).
            </li>
          </ul>
          <Typography variant='p2'>
            While writing your policy, you may inadvertently drift back into
            legalese. Flag these sections for revision for a more conversational
            tone that reflects your brand. For ease of understanding, it’s best
            to write at a sixth-grade level; Microsoft Word has{' '}
            <ExternalLink href='https://support.office.com/en-us/article/get-your-document-s-readability-and-level-statistics-85b4969e-e80a-4777-8dd3-f7fc3c8b3fd2'>
              tools for determining readability
            </ExternalLink>
            .
          </Typography>
        </Fragment>
      }
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            Your Policy’s Audience
          </Typography>
          <Typography variant='p2'>
            It’s important to understand your audience for each policy because
            employees will wonder whether it applies to them and under what
            circumstances.
          </Typography>
          <Typography variant='p2'>
            Some policies differ per audience, such as:
          </Typography>
          <ul className='p2'>
            <li>Information for employees in different states</li>
            <li>Information for employees in different departments or teams</li>
            <li>Information for different title types</li>
          </ul>
          <Typography variant='p2'>
            Make the differentiation clear across these types. You can also look
            for policy management software that makes it easy to{' '}
            <a href='/employee-handbook-software#personalized-content'>
              personalize policy content
            </a>{' '}
            to individuals and groups (like Blissbook!)
          </Typography>
        </Fragment>
      }
    />

    <CaseStudy title='Case Study: Distributing Handbooks'>
      <Typography variant='p2'>
        Chanin needed to build and distribute handbooks to several hundred
        employees spread out across four different locations.
      </Typography>
      <Typography variant='p2'>
        By using Blissbook, she can publish the handbook, and only employees in
        New York can see what’s applicable to New York. This reduces the amount
        of information any one employee has to review, and it also ensures that
        everything they read pertains to them.
      </Typography>
      <PersonCard
        img={images.profileChanin.src}
        name='Chanin Christensen'
        title={
          <span>
            SVP / HR Manager at{' '}
            <ExternalLink href='https://merrickbank.com/'>
              Merrick Bank
            </ExternalLink>
          </span>
        }
      />
    </CaseStudy>

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            The Policy’s Introduction
          </Typography>
          <Typography variant='p2'>
            Once you’ve ironed out the policy details, you may want to add an
            introduction. Give employees a brief summary of what the policy is
            about, why it exists, and why they should care. Be as conversational
            as you can, writing in the same language you would use in a
            one-on-one conversation.
          </Typography>
          <Typography variant='p2'>
            Why didn’t we talk about the introduction first? For many, writing a
            summary can be challenging because of the amount of information
            there is to convey. If you are struggling to summarize your points
            and stay true to your company brand, write the policy before you
            write the intro. If you write the intro after digging into the
            policy, you’ll have a better idea of how to briefly articulate what
            it is and why it matters.
          </Typography>
        </Fragment>
      }
    />

    <HowToSection
      className='container'
      main={
        <QuoteBordered
          quote='You’re serving dual purposes of making sure you’re in compliance with the law and giving employees a roadmap of the relationship.'
          attribution={{
            name: 'Kate Tylee Herz',
            title: (
              <span>
                Counsel, Employment Services Group at{' '}
                <ExternalLink href='https://www.dwt.com/'>
                  Davis Wright Tremaine, LLP
                </ExternalLink>
              </span>
            ),
          }}
        />
      }
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            Related Policies
          </Typography>
          <Typography variant='p2'>
            Employees want an easy reference to related policies, which is
            another benefit of an electronic format. By linking to related
            policies within the context of a particular point, you make it easy
            for employees to find information themselves, reducing the number of
            questions you’re likely to receive.
          </Typography>
        </Fragment>
      }
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h2'>How to Write a Procedure</Typography>
          <Typography variant='p2'>
            When writing procedures, keep the same conversational tone. If you
            can help employees understand a procedure visually, use an
            infographic, flowchart, or other image. Not only can this add flair
            to your policies, but visual elements can be helpful for
            comprehension and memory.
          </Typography>
          <Typography variant='p2'>
            <strong>
              For the parts that need to be straight-up text, keep in mind:
            </strong>
          </Typography>
          <ul className='p2'>
            <li>
              If an employee will use a procedure to follow a policy, write it
              in the order it should be executed, when applicable.
            </li>
            <li>
              If a procedure can be followed in any order, use design elements
              or alphabetical order to make it easier to find specific actions
              at a later date.
            </li>
            <li>
              Include necessary forms, guides, or other resources employees will
              need to follow the procedure. If you’re distributing your policies
              digitally, it should be easy to link to any of these documents
              stored in the cloud.
            </li>
          </ul>
          <Typography variant='p2'>
            Once your procedures are written, take another look to remove excess
            text. Can something be replaced with an image or an infographic? You
            can always flag content and ask your designer for suggestions later.
            The more visually engaging your handbook is, the more likely it is
            that employees will read and remember it.
          </Typography>
        </Fragment>
      }
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h2'>Things to Remember</Typography>
          <Typography variant='h3' className='dark'>
            Disclaimers
          </Typography>
          <Typography variant='p2'>
            An employee’s job is not guaranteed by simply following your
            company’s basic rules and regulations. Your standards should be
            higher and, because of that, your collective policies and/or
            employee handbook are not a contract. This is especially important
            in at-will employment states.
          </Typography>
          <Typography variant='p2'>
            At some point in your handbook, you should say this plainly. Don’t
            lead with this, though, because it can throw off the feel-good vibes
            created when you start with engaging culture content like your
            mission, vision, and values. Feel free to title the disclaimer in a
            non-threatening way (such as “about this handbook”) at the end.
          </Typography>
          <Typography variant='p2'>
            <strong>Something like this will suffice*:</strong>
          </Typography>
          <div
            className='tw-p-10'
            style={{ backgroundColor: colors['blurple-50'] }}
          >
            <Typography variant='p2'>
              This handbook is designed to familiarize you with our company and
              provide you with information about our brand, policies,
              guidelines, and programs, all of which affect your life here at
              Acme Incorporated. Please keep in mind that it is not intended to
              be 100% comprehensive, and it is not meant to address every
              application of, or exception to, the general policies and
              procedures described. If you have any questions about any topic
              covered within, please contact your manager or the people
              department.
            </Typography>
            <Typography variant='p2'>
              This handbook is not a contract for employment, express or
              implied, and it does not guarantee any fixed terms and conditions
              of your employment. Your employment is at-will, and you or we are
              free to end the employment relationship at any time, with or
              without cause and without prior notice.
            </Typography>
            <Typography variant='p2'>
              Last, the policies, procedures, and benefits described within may
              be altered or discontinued from time to time. Although we will try
              to communicate changes with you when they occur, a policy may be
              changed or eliminated without notice.
            </Typography>
          </div>
          <Typography variant='p2'>
            <em>
              *Consult with your legal counsel, we are not lawyers and this is
              not legal advice.
            </em>
          </Typography>
        </Fragment>
      }
    />

    <HowToSection
      className='container'
      main={
        <Fragment>
          <Typography variant='h3' className='dark'>
            Content Not to Include
          </Typography>
          <Typography variant='p2'>
            Since your employee handbook should not be confused with a contract,
            you’ll want to keep some content separate from your handbook. Don’t
            include any policy or content that could unintentionally turn your
            handbook into something a judge or jury could confuse for a
            contract.
          </Typography>
          <Typography variant='p2'>
            You also want to exclude any content that’s usually customized on a
            per-employee basis.
          </Typography>
          <Typography variant='p2'>Examples include:</Typography>
          <ul className='p2'>
            <li>Non-compete or confidentiality agreements</li>
            <li>NDAs (Non Disclosure Agreements)</li>
            <li>Trade secret, inventions, or IP agreements</li>
          </ul>
        </Fragment>
      }
    />

    <HowToSection
      className='container'
      main={
        <QuoteBordered
          quote='You want to make sure that you’re not creating a contract for employment with your handbook, especially if you’re in an at-will employment state.'
          attribution={{
            name: 'Kate Tylee Herz',
            title: (
              <span>
                Counsel, Employment Services Group at{' '}
                <ExternalLink href='https://www.dwt.com/'>
                  Davis Wright Tremaine, LLP
                </ExternalLink>
              </span>
            ),
          }}
        />
      }
    />

    <Section className='container tw-p-18 text-center'>
      <Fragment>
        <Typography variant='h2'>Next Chapter: The Yep Work</Typography>
        <Typography variant='p2'>
          <Link className='btn btn-link tw-m-2' href={urls.stepWork}>
            <FontAwesomeIcon className='tw-mr-2' icon='arrow-left' />
            Previous
          </Link>
          <Link className='btn btn-primary tw-m-2' href={urls.yepWork}>
            Next: Revisions, Decisions, and Approval
          </Link>
        </Typography>
      </Fragment>
    </Section>
  </Layout>
)
